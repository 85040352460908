import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';

import Header2 from '../../parts/header2';
import Side from '../../parts/side';
import SharedPricing from '../shared/pricing';

// Assets
import 'css/preprava_balikov.css';


const InfoPricingPage = (props) => {
  const { i18n, t } = useTranslation();

  return(
    <>
      <section id="home">
          <Header2 props={props} />
      </section>

      <section id="content" className="border-top">
          <div className="container">
              <h2 className="headline-left">{t('page.pricing')}</h2>
              <div className="row">

                  <div className="col-xl-8 main">
                    <SharedPricing />
                  </div>

                  <div className="col-xl-4 side">
                      <Side />
                  </div>

              </div>
          </div>
      </section>
    </>
  );
}

export default InfoPricingPage;

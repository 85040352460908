import React from 'react'
import { Link } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';

import Header2 from '../../parts/header2';
import Side from '../../parts/side';

// Images
import img_zberna_miesta from 'images/pages/zberna_miesta.jpg';
import img_czechland from 'images/pages/czechland.jpg';
import img_vcielka_maja from 'images/pages/vcielka_maja.jpg';
import img_babicka from 'images/pages/babicka.jpg';
import img_unit_3 from 'images/pages/unit_3_navigation_point.jpeg';
import img_londyn from 'images/pages/londyn_zberne_miesto_matkoakubko.jpg';
import img_makcbox from 'images/pages/makcbox.jpg';


const InfoCollectionPointsPage = (props) => {
  const { i18n, t } = useTranslation();

  return(
    <>
      <section id="home">
          <Header2 props={props} />
      </section>

      <section id="content" className="border-top">
          <div className="container">
              <h2 className="headline-left">Zberné miesta</h2>
              <div className="row">

                  <div className="col-xl-8 main">
                    <section id="preprava_balikov">
                        <img src={img_zberna_miesta} alt="zberna mista" />
                    </section>

                    <section id="we-transport">
                        <br/>
                        <p><Trans i18nKey="pageInfo.collectionPoints.introduction"><a href="/preprava-balikov-slovensko-anglicko"></a></Trans></p>
                        { (i18n.language === "sk") &&
                            <p>
                                Zatiaľ máme dohodnuté <strong>dve zberné miesta,</strong> ponuku zberných miest <strong>plánujeme rozširovať.</strong>
                            </p>
                        }
                        <div className="alert-box">
                        <p><Trans i18nKey="pageInfo.collectionPoints.become"><a href="/kontakt"></a></Trans></p>
                        </div>
                    </section>

                    <section id="preprava_balikov">
                      <div className="row border-bottom" style={{ paddingBottom: "10px" }}>
                          <div className="col-lg-6">
                              <img src={img_zberna_miesta} alt="Tatry delicatessen" />
                          </div>
                          <div className="col-lg-6">
                              <h3>
                                  Tatry delicatessen
                              </h3>
                              <p>
                                  50 Station Road
                                  <br/> CH5 1SX Queensferry
                                  <br/> tel.: +44 01244 831355
                              </p>
                          </div>
                      </div>

                      <div className="row border-bottom" style={{ paddingBottom: "10px", paddingTop: "10px" }}>
                          <div className="col-lg-6">
                              <img src={img_czechland} alt="CzechLand" />
                          </div>
                          <div className="col-lg-6">
                              <h3>
                                  CzechLand
                              </h3>
                              <p>
                                  108 Northgate street
                                  <br/> GL11SL Gloucester
                                  <br/> tel.: +44 7824 326711
                              </p>
                          </div>
                      </div>

                      <div className="row border-bottom" style={{ paddingBottom: "10px", paddingTop: "10px" }}>
                          <div className="col-lg-6">
                              <img src={img_babicka} alt="Babička – Grandma’s pantry" />
                          </div>
                          <div className="col-lg-6">
                              <h3>
                                  Babička – Grandma’s pantry
                              </h3>
                              <p>
                                10 Harrier Park,
                                <br/> Didcot OX11 7PL,
                                <br/> United Kingdom
                                <br/> tel.: +44 7850 368473
                              </p>
                          </div>
                      </div>

                      <div className="row border-bottom" style={{ paddingBottom: "10px", paddingTop: "10px" }}>
                          <div className="col-lg-6">
                              <img src={img_unit_3} alt="Unit 3 Navigation Point" />
                          </div>
                          <div className="col-lg-6">
                              <h3>
                                  Unit 3 Navigation Point
                              </h3>
                              <p>
                                  Golds Hill Way
                                  <br/> Tipton DY4 0PY
                                  <br/> tel.: +44 7894007679
                                  <br/> Otvorené: {t('mon')}-{t('fri')} 09:00 – 17:00
                              </p>
                          </div>
                      </div>

                      <div className="row" style={{ paddingTop: "10px" }}>
                          <div className="col-lg-6">
                              <img src={img_londyn} alt="London" />
                          </div>
                          <div className="col-lg-6">
                              <h3>
                                204 Caledonian Road
                              </h3>
                              <p>
                                London, N1 0SQ,
                                <br/> v prevádzke 2BUSY 2CLEAN LONDON LIMITED
                                <br/> Otváracie hodiny:
                                <br/> {t('monday')} - {t('friday')}: 9am - 6pm
                                <br/> {t('saturday')}: 9am - 5pm
                                <br/> {t('sunday')}: zavreté
                              </p>
                          </div>
                      </div>

                      <div className="row" style={{ paddingTop: "10px" }}>
                          <div className="col-lg-6">
                              <img src={img_makcbox} alt="Makcbox" />
                          </div>
                          <div className="col-lg-6">
                              <h3>
                                Makcbox - sklady na prenájom
                              </h3>
                              <p>
                                Textilná 4,
                                <br/> 040 12, Košice
                                <br/> Otváracie hodiny:
                                <br/> {t('monday')} - {t('friday')}: 9:00 - 17:00
                                <br/> {t('saturday')}: zatvorené
                                <br/> {t('sunday')}: zatvorené
                                <br/><a href="https://www.makcbox.sk" target="_blank">www.makcbox.sk</a>
                              </p>
                          </div>
                      </div>

                    </section>

                    <section style={{ marginTop : '40px' }}>
                        <p><Trans i18nKey="page.delivery.order"><span></span></Trans></p>
                        <Link to={i18n.language === "sk" ? "/objednavka-prepravy-balika" : "/order"} data-action="form"><button>{t('orderit')}</button></Link>
                    </section>
                  </div>

                  <div className="col-xl-4 side">
                      <Side />
                  </div>

              </div>
          </div>
      </section>
    </>
  );
}

export default InfoCollectionPointsPage;

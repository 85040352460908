import React from 'react'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Images
import img_arrow from 'images/arrow_dotted_right.png';
import img_icon_sk from 'images/flag_sk.png';
import img_icon_cz from 'images/flag_cz.png';
import img_icon_gb from 'images/flag_bg.png';

// Backend
import * as Dt from 'utils/dt';


const OrderBoxTransport = ({ vertical, direction, dtStart, dtClose, urgent }) => {
  const { t } = useTranslation();

  let icons = null;
  if (direction === 1) {
    icons = <>
      <span id="flag_sk"><img src={img_icon_sk} alt="" /></span>
      <span id="flag_cz"><img src={img_icon_cz} alt="" /></span>
      <span id="dots"><img src={img_arrow} alt="" /></span>
      <span id="flag_bg"><img src={img_icon_gb} alt="" /></span>
    </>;
  } else if (direction === 2) {
    icons = <>
      <span id="flag_bg"><img src={img_icon_gb} alt="" /></span>
      <span id="dots"><img src={img_arrow} alt="" /></span>
      <span id="flag_sk"><img src={img_icon_sk} alt="" /></span>
      <span id="flag_cz"><img src={img_icon_cz} alt="" /></span>
    </>;
  }

  return (
    <div id={ (direction === 1) ? "from_sk" : ((direction === 2) ? "from_bg" : null) } className={ (vertical) ? "column border" : "column border-right" }>
        <div className="icons">
          {icons}
        </div>
        <div className="date">
            <div className="preprava">
                <p>{t('dateofdelivery')}</p>
                <span className="datum_prepravy">{ Dt.formatD(dtStart) }</span>
            </div>

            <div className={ urgent ? "uzavirka urgent" : "uzavirka" }>
              { Dt.formatD(dtClose) &&
                <>
                  <p>{t('deadlineoforders')}</p>
                  <span className="uzavirka_objednavek">{ Dt.formatD(dtClose) } <small>{t('at')} 15:00</small></span>
                </>
              }
            </div>
        </div>
    </div>
  )
}

export default OrderBoxTransport;

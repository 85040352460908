import React from 'react'
import { Link } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';

import TransportCalculator from '../../parts/transportCalculator';

import {calcPrice, priceToFixed, displayPrice} from 'utils/price';

// Config
import configFile from 'config';

// Assets
import 'css/preprava_balikov.css';


const SharedPricing = (props) => {
  const { i18n, t } = useTranslation();

  return(
    <>
      <section id="prices">
          <h3 className="headline-left">{t('willcostToUk')}?</h3>
          <p><Trans i18nKey="page.pricing.willcostToUk" values={{
                toUkInEUR: displayPrice( '€', configFile.price.toUkInEUR, configFile.price.toUkInGBP )
                , toUkInGBP: displayPrice( '£', configFile.price.toUkInEUR, configFile.price.toUkInGBP )
                , minToUkInEUR: displayPrice( '€', configFile.price.minToUkInEUR, configFile.price.minToUkInGBP )
                , minToUkInGBP: displayPrice( '£', configFile.price.minToUkInEUR, configFile.price.minToUkInGBP )
                , minKgFixed: priceToFixed(configFile.price.minKgFixedPrice,0)
            }}><b></b><span>test</span></Trans><br />
            <Trans i18nKey="page.pricing.weight"></Trans>
          </p>
          <p id="table-title">{t('example')}:</p>
          <table className="table table-bordered">
              <thead>
                  <tr>
                      <th>{t('weight')}</th>
                      <th>{t('price')}</th>
                  </tr>
              </thead>
              <tbody>
                  <tr>
                      <td>9 kg</td>
                      <td>{priceToFixed(calcPrice('€',9,2))} €</td>
                  </tr>
                  <tr>
                      <td>12 kg</td>
                      <td>{priceToFixed(calcPrice('€',12,2))} €</td>
                  </tr>
                  <tr>
                      <td>20 kg</td>
                      <td>{priceToFixed(calcPrice('€',20,2))} €</td>
                  </tr>
              </tbody>
          </table>
          <TransportCalculator
            currency='€'
            fromTo={2}
          />

          <h3 className="headline-left">{t('willcostToScot')}?</h3>
          <p><Trans i18nKey="page.pricing.willcostToScot" values={{
                toScotInEUR: displayPrice( '€', configFile.price.toScotInEUR, configFile.price.toScotInGBP )
                , toScotInGBP: displayPrice( '£', configFile.price.toScotInEUR, configFile.price.toScotInGBP )
                , minToScotInEUR: displayPrice( '€', configFile.price.minToScotInEUR, configFile.price.minToScotInGBP )
                , minToScotInGBP: displayPrice( '£', configFile.price.minToScotInEUR, configFile.price.minToScotInGBP )
                , minKgFixed: priceToFixed(configFile.price.minKgFixedPrice,0)
            }}><b></b><span>test</span></Trans><br />
            <Trans i18nKey="page.pricing.weight"></Trans>
          </p>
          <p id="table-title">{t('example')}:</p>
          <table className="table table-bordered">
              <thead>
                  <tr>
                      <th>{t('weight')}</th>
                      <th>{t('price')}</th>
                  </tr>
              </thead>
              <tbody>
                  <tr>
                      <td>9 kg</td>
                      <td>{priceToFixed(calcPrice('€',9,3))} €</td>
                  </tr>
                  <tr>
                      <td>12 kg</td>
                      <td>{priceToFixed(calcPrice('€',12,3))} €</td>
                  </tr>
                  <tr>
                      <td>20 kg</td>
                      <td>{priceToFixed(calcPrice('€',20,3))} €</td>
                  </tr>
              </tbody>
          </table>
          <TransportCalculator
            currency='€'
            fromTo={3}
          />

          <h3 className="headline-left">{t('willcostFromUk')}?</h3>
          <p><Trans i18nKey="page.pricing.willcostFromUk" values={{
                fromUkInGBP: displayPrice( '£', configFile.price.fromUkInEUR, configFile.price.fromUkInGBP )
                , minFromUkInGBP: displayPrice( '£', configFile.price.minFromUkInEUR, configFile.price.minFromUkInGBP )
                , toUkInEUR: displayPrice( '€', configFile.price.toUkInEUR, configFile.price.toUkInGBP )
                , toUkInGBP: displayPrice( '£', configFile.price.toUkInEUR, configFile.price.toUkInGBP )
                , minToUkInEUR: displayPrice( '€', configFile.price.minToUkInEUR, configFile.price.minToUkInGBP )
                , minToUkInGBP: displayPrice( '£', configFile.price.minToUkInEUR, configFile.price.minToUkInGBP )
                , minKgFixed: priceToFixed(configFile.price.minKgFixedPrice,0)
            }}><b></b></Trans><br />
            <Trans i18nKey="page.pricing.weight"></Trans>
          </p>
          
          <p id="table-title">{t('example')}:</p>
          <table className="table table-bordered">
              <thead>
                  <tr>
                    <th>{t('weight')}</th>
                    <th>{t('price')}</th>
                  </tr>
              </thead>
              <tbody>
                  <tr>
                      <td>9 kg</td>
                      <td>£ {priceToFixed(calcPrice('£',9,1))}</td>
                  </tr>
                  <tr>
                      <td>12 kg</td>
                      <td>£ {priceToFixed(calcPrice('£',12,1))}</td>
                  </tr>
                  <tr>
                      <td>20 kg</td>
                      <td>£ {priceToFixed(calcPrice('£',20,1))}</td>
                  </tr>
              </tbody>
          </table>
          <TransportCalculator
            currency='£'
            fromTo={1}
          />

          <h3>{t('fees')}</h3>
          <p>{t('page.pricing.fees1')}</p>
          <p>{t('page.pricing.fees2')}</p>
          <p>{t('page.pricing.fees3')}</p>
          <h4>{t('notice')}!</h4>
          <p><Trans i18nKey="page.pricing.fees4"><span className="alert-box"></span></Trans></p>
          <p><Trans i18nKey="page.pricing.fees5"><Link to="/clo"></Link></Trans></p>
      </section>

      <section>
          <h3 className="headline-left">{t('howpay')}?</h3>
          <p>{t('page.pricing.howpay').split('\n').reduce((r, c, x) => r ? [...r, <br key={x}/>, c] : [c], null)}</p>
      </section>
    </>
  );
}

export default SharedPricing;

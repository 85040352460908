import React from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import Header2 from '../parts/header2';
import Side from '../parts/side';

import BlogItem from './blog/blogItem';

// Backend
import * as DataBlogService from 'services/dataBlogService.js';
import * as Helper from 'utils/helpers';

// Images
import img_arrow from 'images/arrow_left_white.png';

class BlogArticlePage extends React.Component {
  state = {
    blog : null,
    articleId : null,
  };


  constructor(props) {
    super(props);
  }


  parseSlug() {
    const slug = this.props.location.pathname.substring(1);
    if ( slug.substring(slug.length - 1) === "/" ) {
      return slug.substring(0, slug.length - 1);
    } else {
      return slug;
    }
  }


  async componentDidMount() {
    //this.setState({ articleId : (this.props.match.params.article ? this.props.match.params.article : 0) });
    await this.blogLoadBySlug( this.parseSlug() );
  }


  async componentWillReceiveProps(nextProps) {
    //if (nextProps.location.pathname !== window.location.pathname) {
    if (this.props.location.pathname !== nextProps.location.pathname) {
      /*this.setState({ blog: null, articleId : null });
      this.componentDidMount();*/
      this.setState({ articleId : null });
      await this.blogLoadBySlug( nextProps.location.pathname.substring(1) );
    }
  }



  // GET
  async blogLoadBySlug(slug) {
    if (slug === null) {
      this.setState({ articleId : 0 });
      return 0;
    }

    try {
      const data = await DataBlogService.getBlogsBySlug(slug);

      if (data === null) {
        this.setState({ articleId : 0 });
      } else {
        this.setState({ articleId: data._id });
        this.setState({ blog : data });
      }
    } catch(e) {
      this.setState({ articleId : 0 });
    }
  }


  render () {
    const { t } = this.props;
    const { blog, articleId } = this.state;


    if ( (articleId === null) || (articleId === 0) ) {
      return(
        <>
          <section id="home">
              <Header2 props={this.props} />
          </section>

          <section id="content" className="border-top">
              <div className="container">
                  <div style={{ paddingTop: '0' }} className="headline-left"></div>

                  <div className="row">

                      <div className="col-xl-8 main">

                        { (articleId === null) &&
                          <p style={{ textAlign: 'center', marginTop: '40px' }}>{t('loading')}...</p>
                        }
                        { (articleId === 0) &&
                          <>
                            <h2>404</h2>
                            <p style={{ textAlign: 'center', marginTop: '40px' }}>{t('page.notfound')}.</p>
                          </>
                        }

                      </div>

                      <div className="col-xl-4 side">
                          <Side />
                      </div>

                    </div>
                </div>
            </section>
        </>
      );
    }


    return(
      <>
        <section id="home">
            <Header2 props={this.props} />
        </section>

        <section id="content" className="border-top">
            <div className="container">
                <div style={{ paddingTop: '0' }} className="headline-left"></div>

                <div className="row">

                    <div className="col-xl-8 main">
                      <Link to="/blog" className="blog-goback"><button className="border"><img src={img_arrow} alt="" /> &nbsp; {t('back')}</button></Link>

                      { (blog !== null) &&
                        <section id="we-transport" className="blog-article">
                            <h2 style={{ marginTop: '40px' }} className="blog-title">{blog.title}</h2>
                            {/* <span className="blog-time">21.02 2020</span> */}
                            <div className="blog-img"><img src={blog.image} /></div>
                            <div className="blog-desc" dangerouslySetInnerHTML={{__html: blog.excerpt}}></div>
                            <div className="blog-desc" dangerouslySetInnerHTML={{__html: blog.text}}></div>
                            {/* <a href="#">
                                Více informací
                                <img src={img_arrow} alt="" />
                            </a> */}
                        </section>
                      }

                      <Link to="/blog" style={{ paddingTop: '30px' }} className="blog-goback"><button className="border"><img src={img_arrow} alt="" /> &nbsp; {t('back')}</button></Link>
                    </div>

                    <div className="col-xl-4 side">
                        <Side />
                    </div>

                  </div>
              </div>
          </section>
      </>
    );
  }
}

export default withTranslation()(BlogArticlePage);

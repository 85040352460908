import React from 'react'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import OrderStateForm from 'components/forms/orderStateForm';


const OrderBoxState = ({ orders, vertical }) => {
  // REMOVE LATER >>>
  return null;
  // <<< REMOVE LATER

  const { t } = useTranslation();

  let ordersFilter = null;

  return (
    vertical ?

    <section id="order-check-side">
        <div className="order-check">
            <h2>{t('form.state.title')}</h2>
            <p>{t('form.state.description')}</p>

            <OrderStateForm />

        </div>

    </section>

    :

    <div className="order-check border">
        <div className="info">
            <p id="title">{t('form.state.title')}</p>
            <p>{t('form.state.description')}</p>
        </div>

        <OrderStateForm />

    </div>
  );
}

export default OrderBoxState;

import React from 'react'
import { Link } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';

import Header2 from '../../parts/header2';
import Side from '../../parts/side';

// Images


const InfoCookiesPage = (props) => {
  const { i18n, t } = useTranslation();

  return(
    <>
      <section id="home">
          <Header2 props={props} />
      </section>

      <section id="content" className="border-top">
          <div className="container">
              <h2 className="headline-left">Informácie o cookies</h2>
              <div className="row">

                  <div className="col-xl-8 main">
                    <section id="we-transport">
                      <p>Web Maťko a Kubko, rovnako ako všetky weby, ktoré navštívite, ukladá súbory cookies. Určite ste sa s týmto pojmom už stretli. Viete však, o čo vlastne ide?</p>

          						<h3>Charakteristika cookies</h3>
          							<p>Cookies sú malé textové súbory, ktoré webová stránka, ktorú navštívite, ukladá do vášho počítača, mobilu, či iného zariadenia. Tieto súbory obsahujú osobne identifikovateľné informácie, ako napríklad vaše meno, priezvisko, e-mailová adresa, ale aj vaše preferencie, napríklad jazyk, či veľkosť písma. Samozrejme, všetko sú to údaje, ktoré ste danej webovej lokalite už predtým vy sami poskytli.</p>
          							<p>Výhodou pre vás je, že pri opakovanej návšteve konkrétneho webu už tieto údaje nemusíte odznova zadávať, ak sa napríklad potrebujete prihlásiť. Súbory cookies sa zaznamenávajú a uchovávajú v zmysle § 55 ods. 5 Zákona č. 351/2011 Z. z. o elektronických komunikáciách v znení neskorších predpisov. Ak chcete ich ukladaniu zabrániť, môžete tak urobiť nastavením vo webovom prehliadači. Viac informácií o cookies si môžete <a href="https://www.allaboutcookies.org/" target="_blank">prečítať tu</a>.</p>

          						<h3>Aké súbory cookies spracúva náš web?</h3>
          							<p>Na našom webe sa spracúvajú tieto typy cookies:</p>
          							<ol style={{ paddingLeft: "20px" }}>
          								<li>Základné cookies. Alebo tzv. nevyhnutné cookies. Bez nich by webová stránka nemohla správne fungovať. Ide napríklad o nastavenia bezpečnosti, správu siete a prístupnosť. Môžete ich zakázať, ak zmeníte nastavenia v prehliadači. Ovplyvní to však fungovanie webu.</li>
          								<li>
                            <p>Funkčné cookies. Vďaka nim je uchovaný rovnaký spôsob prehliadania nášho webu zakaždým, keď ho navštívite. Ide tu o voľbu jazyka, veľkosť písma, či rôzne iné preferencie zobrazenia. V rámci funkčných cookies tiež zaznamenávame údaje o návštevnosti nášho webu. Sú to však len anonymné štatistiky a nie je možné vás (ako osobu) na základe nich identifikovať.</p>
                            <p>Ide napríklad o nástroj Google Analytics spoločnosti Google Ireland Limited, ktorý analyzuje návštevnosť webu a správanie sa návštevníkov na našich stránkach. Vďaka takýmto získaným údajom sa potom snažíme čo najlepšie prispôsobiť obsah webu. Ako spoločnosť Google využíva tieto údaje? Rozsah a spôsob ich využitia <a href="https://policies.google.com/technologies/partner-sites?hl=sk" target="_blank">nájdete tu</a>.</p>
                            <p>Ďalej využívame aj cookies spoločnosti Facebook Ireland Ltd, ktoré uchovávajú anonymizované údaje na štatistické účely. O aké údaje ide a ako sú využívané spoločnosťou Facebook? Pozrieť si <a href="https://www.facebook.com/policies/cookies/" target="_blank">môžete tu</a>.</p>
                          </li>
          								<li>Marketingové (reklamné) cookies. Vďaka nim sa vám na našom webe zobrazuje reklama, ktorá vás zaujíma. Nám zas tieto nástroje umožňujú merať účinnosť reklám a pod. Na tento účel využívame nástroj spoločnosti Google Irelan Limited, tzv. Google Ads.</li>
          							</ol>
          							<p>Ak ide o nevyhnutné cookies, súhlas na ich získanie nám udeľujete už len tým, že prehliadate náš web. Pokiaľ ide o rozšírené cookies, vyžaduje sa priame vyjadrenie súhlasu s ich zaznamenávaním. Ak nám tento súhlas udelíte, môžeme vám napríklad prispôsobovať reklamu na mieru.</p>

                      <h3>Ako dlho sa cookies uchovávajú?</h3>
          							<p>Základné cookies sa uchovávajú 1 mesiac. Pokiaľ ide o rozšírené a reklamné cookies, tie sú uchovávané po dobu 24 mesiacov. Avšak ak chcete, môžete ich, podľa svojho uváženia, kontrolovať alebo mazať. Takisto môžete väčšinu prehliadačov nastaviť tak, že sa cookies nebudú ukladať. V tom prípade je však možné, že pri návšteve webovej stránky nebudú niektoré jej funkcie a služby fungovať správne alebo budete musieť manuálne upravovať niektoré nastavenia.</p>
          							<p>Máte záujem upraviť nastavenia súborov cookies v prehliadačoch? Môžete tak urobiť tu:</p>
          							<p>
                          <a href="https://support.mozilla.org/sk/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=povolenie-zakazanie-cookies&redirectlocale=sk" target="_blank">Firefox</a><br/>
                          <a href="https://support.google.com/chrome/answer/95647" target="_blank">Google Chrome</a><br/>
                          <a href="https://support.microsoft.com/sk-sk/windows/odstr%C3%A1nenie-a-spr%C3%A1va-s%C3%BAborov-cookie-168dab11-0753-043d-7c16-ede5947fc64d" target="_blank">Internet Explorer</a><br/>
                          <a href="https://support.apple.com/sk-sk/guide/safari/sfri11471/mac" target="_blank">Safari</a>
                        </p>
          							<p>Ak používate iný prehliadač, nastavenie súborov cookies nájdete v jeho dokumentácii.</p>
          							<p>V prípade, že sa v rámci súborov cookies spracúvajú aj osobné informácie, ako napríklad IP adresa, návštevník webu má všetky práva v súlade s našimi Podmienkami ochrany osobných údajov.</p>
          					</section>
                  </div>

                  <div className="col-xl-4 side">
                      <Side />
                  </div>

              </div>
          </div>
      </section>
    </>
  );
}

export default InfoCookiesPage;

import React, {useState} from 'react';
import { NavLink, Link } from 'react-router-dom';
import { useTranslation, language } from 'react-i18next';

// Images
import img_logo from 'images/logo_MAK.png';
import img_icon_bars from 'images/icon_bars_3.png';


const Menu = ({ props }) => {
  const [showSubmenu, setShowSubmenu] = useState(false);

  const { i18n, t } = useTranslation();
  const lang = i18n.language;

  const displaySubmenu = (show, menuName) => {
    setShowSubmenu(show);
  }


  return(
    <div className="container">
        <nav id="navigation" className="nav-top navbar navbar-expand-xl static-top">

            <Link to="/" className="navbar-brand">
              <div className="hidden-title">Maťko a Kubko</div>
              <img src={img_logo} alt="Logo Maťko a Kubko" />
            </Link>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapseNavbar">
                <img src={img_icon_bars} alt="" />
            </button>

            <div id="collapseNavbar" className="collapse navbar-collapse justify-content-end">
                <ul className="navbar-nav">
                    <li id="uvod" className="nav-item">
                      <NavLink to="/" exact className="nav-link">{t('home')}</NavLink>
                    </li>
                    <li onMouseEnter={()=>displaySubmenu(true, "preprava")} onMouseLeave={()=>displaySubmenu(false, "preprava")} id="preprava_balikov" className="nav-item">
                      <NavLink to={lang === "sk" ? "/preprava-balikov-slovensko-anglicko" : "/delivery"} className="nav-link dropdown-toggle">{t('delivery')} <span className="dropdown-toggle"></span></NavLink>
                      <ul style={{ display: (showSubmenu ? "inherit" : "none" ) }} className="dropdown-menu">
                        <li className="nav-item"><NavLink to="/clo" className="nav-link dropdown-item">{t('brexit')}</NavLink></li>
                        <li className="nav-item"><NavLink to="/najblizsi-termin-prepravy" className="nav-link dropdown-item">{t('nearestTransport')}</NavLink></li>
                        <li className="nav-item"><NavLink to="/mapy" className="nav-link dropdown-item">{t('mapsAndRoutes')}</NavLink></li>
                        <li className="nav-item"><NavLink to="/cennik" className="nav-link dropdown-item">{t('priceList')}</NavLink></li>
                        <li className="nav-item"><NavLink to="/zberne-miesta" className="nav-link dropdown-item">{t('collectionPoints')}</NavLink></li>
                        <li className="nav-item"><NavLink to="/matko-a-kubko-pomaha-detom" className="nav-link dropdown-item">{t('helpTheChildren')}</NavLink></li>
                        <li className="nav-item"><NavLink to="/vernostna-zlava" className="nav-link dropdown-item">{t('loyaltyDiscount')}</NavLink></li>
                      </ul>
                    </li>
                    <li id="stahovanie_nabytku" className="nav-item">
                      <NavLink to={lang === "sk" ? "/stahovanie-nabytku-v-anglicku" : "/moving"} className="nav-link">{t('moving')}</NavLink>
                    </li>
                    <li id="nakupy_v_anglicku" className="nav-item">
                      <NavLink to={lang === "sk" ? "/nakupy-v-anglicku" : "/food"} className="nav-link">{t('shopping')}</NavLink>
                    </li>
                    <li id="blog" className="nav-item">
                      <NavLink to={lang === "sk" ? "/blog" : "/blog"} className="nav-link">{t('blog')}</NavLink>
                    </li>
                    <li id="kontakt" className="nav-item">
                      <NavLink to={lang === "sk" ? "/kontakt" : "/contact"} className="nav-link">{t('contact')}</NavLink>
                    </li>
                    <li id="objednat_prepravu" className="nav-item">
                      <Link to={lang === "sk" ? "/objednavka-prepravy-balika" : "/order"} data-action="form" className="nav-link"><button>{t('orderit')}</button></Link>
                    </li>
                </ul>
            </div>

        </nav>
    </div>
  );
}

export default Menu;

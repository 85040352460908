import React from 'react';
import { Link } from 'react-router-dom';
import { withTranslation, Trans } from 'react-i18next';

import Header2 from '../parts/header2';
import Side from '../parts/side';

import {calcPrice, priceToFixed, displayPrice} from 'utils/price';

// Config
import configFile from 'config';

// Assets


class ShoppingPage extends React.Component {
  render () {
    const { i18n, t } = this.props;

    return(
      <React.Fragment>
        <section id="home">
            <Header2 props={this.props} />
        </section>

        <section id="content" className="border-top">
            <div className="container">
                <h2 className="headline-left">{t('shopping')}</h2>
                <div className="row">

                    <div className="col-xl-8 main">
                        <section id="preprava_balikov">
                          { t('page.shopping.introduction').split('\n').map(item => <p key={item} className="mr-bottom">{item}</p>) }
                        </section>
                        <section id="we-transport">
                            <h3 style={{paddingTop: 25 + 'px'}} className="section-title">{t('page.shopping.howto')}</h3>

                            <p><Trans i18nKey="page.shopping.howto1"><b></b><a href="https://www.ebay.co.uk/" target="_blank" rel="noopener noreferrer"></a></Trans></p>

                            <section id="packing">
                              <div className="packing-list border-top border-bottom">
                                  <ul className="inner-list">
                                      <li><Link to="/ako-lacno-nakupovat-cez-anglicky-ebay">{t('page.shopping.howtocheap')}</Link></li>
                                  </ul>
                              </div>
                            </section>

                            <p><Trans i18nKey="page.shopping.howto2"><b></b><a href="https://www.paypal.com/sk/home/" target="_blank" rel="noopener noreferrer"></a></Trans></p>
                            <p><Trans i18nKey="page.shopping.howto3"><b></b><b></b></Trans></p>
                            <p><Trans i18nKey="page.shopping.howto4" values={{
                                minToUkInGBP: displayPrice( '£', configFile.price.minToUkInEUR, configFile.price.minToUkInGBP )
                                , minKgFixed: priceToFixed(configFile.price.minKgFixedPrice,0)
                              }}><b></b></Trans></p>
                            <p><Trans i18nKey="page.shopping.howto5"><b></b><b></b></Trans></p>
                            <p><Trans i18nKey="page.shopping.howto6" values={{
                                toUkInGBP: displayPrice( '£', configFile.price.toUkInEUR, configFile.price.toUkInGBP )
                                , minToUkInGBP: displayPrice( '£', configFile.price.minToUkInEUR, configFile.price.minToUkInGBP )
                                , minKgFixed: priceToFixed(configFile.price.minKgFixedPrice,0)
                              }}></Trans></p>
                            <div className="alert-box">
                                <p>{t('page.shopping.howto7')}</p>
                            </div>
                            <p>{t('page.shopping.howto8')}</p>

                            <h4 className="section-title">{t('notice')}</h4>
                            <p>{t('page.shopping.notice')}</p>

                        </section>
                    </div>

                    <div className="col-xl-4 side">
                        <Side />
                    </div>

                  </div>
              </div>
          </section>
      </React.Fragment>
    );
  }
}

export default withTranslation()(ShoppingPage);

import React from 'react'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Images
import img_arrow_right from 'images/arrow_right_red.png';
import img_icon_truck from 'images/icon_truck_2x.png';
import img_icon_shop from 'images/icon_shop.png';
import img_icon_trolley from 'images/icon_trolley.png';


const Services = (props) => {
  const { i18n, t } = useTranslation();

// TODO: remove later: style={{ marginTop: '70px' }}
  return (
    <section style={{ marginTop: '70px' }} id="services">
        <div className="container">
            <h3 className="headline-center">{t('ourservices')}</h3>

            <div className="row">
                <div data-aos="fade-up" id="transport-packages" className="col-lg-4 service-item">
                    <img id="truck" className="service-icon" src={img_icon_truck} alt="" />
                    <h4>{t('delivery')}</h4>
                    <p>{t('services.delivery').split('\n').reduce((r, c, x) => r ? [...r, <br key={x}/>, c] : [c], null)}</p>
                    <Link to={i18n.language === "sk" ? "/preprava-balikov-slovensko-anglicko" : "/delivery"} data-action="form" className="nav-link">{t('moreinfo')} <img src={img_arrow_right} alt="" /></Link>
                </div>

                <div data-aos="fade-up" data-aos-delay="500" id="shopping" className="col-lg-4 service-item">
                    <img id="shop" className="service-icon" src={img_icon_shop} alt="" />
                    <h4>{t('shopping')}</h4>
                    <p>{t('services.food').split('\n').reduce((r, c, x) => r ? [...r, <br key={x}/>, c] : [c], null)}</p>
                    <Link to={i18n.language === "sk" ? "/nakupy-v-anglicku" : "/food"} data-action="form" className="nav-link">{t('moreinfo')} <img src={img_arrow_right} alt="" /></Link>
                </div>

                <div data-aos="fade-up" data-aos-delay="1000" id="moving" className="col-lg-4 service-item">
                    <img id="trolley" className="service-icon" src={img_icon_trolley} alt="" />
                    <h4>{t('moving')}</h4>
                    <p>{t('services.moving').split('\n').reduce((r, c, x) => r ? [...r, <br key={x}/>, c] : [c], null)}</p>
                    <Link to={i18n.language === "sk" ? "/stahovanie-nabytku-v-anglicku" : "/moving"} data-action="form" className="nav-link">{t('moreinfo')} <img src={img_arrow_right} alt="" /></Link>
                </div>

            </div>
        </div>
    </section>
  )
}

export default Services;

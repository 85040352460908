import React from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

// Images
import img_arrow from 'images/arrow_right_red.png';

const BlogItem = ({ t, id, slug, title, image, text }) => {
  return(
    <section id="we-transport" className="blog-article">
        <h2 className="blog-title">{title}</h2>
        {/* <span className="blog-time">21.02 2020</span> */}
        <Link to={"/" + slug}><div className="blog-img"><img src={image} /></div></Link>
        <div className="blog-desc" dangerouslySetInnerHTML={{__html: text}}></div>
        <Link to={"/" + slug} id="continue_link">
            {t('continuereading')}
            <img src={img_arrow} alt="" />
        </Link>
    </section>
  );
}

export default withTranslation()(BlogItem);

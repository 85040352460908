import React, { useState } from 'react';

import {calcPrice, priceToFixed} from 'utils/price';
import { useTranslation, Trans } from 'react-i18next';


const TransportCalculator = ({ currency, fromTo }) => {
  const { i18n, t } = useTranslation();
  const [price, setPrice] = useState( null );


  const handleClickedPriceCalc = ({ currentTarget : input }) => {
    const kg = calcPrice( currency, input.value, fromTo );
    setPrice(kg);
    return kg;
  };


  return(
    <div className="transportCalc">
      <p>{t('form.calculator.enterWeight')}: &nbsp;
        <input type="text" onChange={handleClickedPriceCalc} />
        &nbsp; kg &nbsp;
        <strong>
          {
            ( (price === null) || ( isNaN(price) ) ) ?
              <>{ (price === null) ? "" : " ... Nezadal(a) ste celé číslo" }</>
            :
              <> ... {t('yourPrice')}: { fromTo === 1 ? "£ " : ""}{priceToFixed(price)}{fromTo === 2 || fromTo === 3 ? " €" : ""}</>
          }
        </strong>
      </p>
    </div>
  );

}

export default TransportCalculator;

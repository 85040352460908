import React from 'react';
import { Link } from 'react-router-dom';
import { Trans, withTranslation } from 'react-i18next';
import Joi from 'joi-browser';
import ReactGA from "react-ga";

// Backend
import * as DataNewslettersService from 'services/dataNewslettersService';



class NewsletterForm extends React.Component {

  schema = {
    email: Joi.string().email().required().label('E-mail'),
  };

  state = {
    email : "",
    message : null,
  };


  handleChangeInput = ({ currentTarget : input }) => {
    this.setState({ email : input.value });
  };


  handleSubscribe = async (e) => {
    const { email } = this.state;
    const schema = { "email" : this.schema.email };
    const { error } = Joi.validate(email, schema);

    this.setState({ message : "processing" });

    // Analytics
    ReactGA.initialize("UA-49991991-1");
    ReactGA.event({
      category: 'button',
      action: 'preklik',
      label: 'newsletter',
      transport: 'xhr',
    });

    try {
      const { message } = await DataNewslettersService.insertEmail( email );
      this.setState({ message });
      return message;
    } catch (ex) {
      if ( ex.response.data === undefined || ex.response.data.message === undefined) {
        this.setState({ message : null });
        return null;
      } else {
        const message = ex.response.data.message;
        this.setState({ message });
        return message;
      }
    }

  };


  render () {
    const { t } = this.props;

    let txt = "";
    switch (this.state.message) {
      case null:
        txt = "";
        break;
      case "empty":
        txt = "E-mail je prázdný";
        break;
      case "format":
        txt = "E-mail má chybný formát";
        break;
      case "list":
        txt = "Požadovaný seznam kontaktů byl odstraněn. Prosím kontaktujte nás";
        break;
      case "wrong":
        txt = "Nastala chyba. Prosím kontaktujte nás";
        break;
      case "subscribed":
        txt = "K odběru novinek jste již přihlášen/a";
        break;
      case "ok":
        txt = "Nyní jste přihlášen/a k odběru novinek, děkujeme";
        break;
      case "processing":
        txt = "zpracovávám..";
        break;
      case "custom":
      default:
        txt = "Nastala chyba. Prosím kontaktujte nás.";
        break;
    }

    return (
      <section id="newsletter">
           <div className="container">
               <div className="newsletter-box border">
                   <div className="info">
                       <h2>{t('form.newsletter.title')}</h2>
                       <p>{t('form.newsletter.description')}</p>
                   </div>
                   <input onChange={this.handleChangeInput} type="text" placeholder={t('form.newsletter.email') + "..."} />
                   <a onClick={ e => { e.preventDefault() } } className="flex-grow-1"><button onClick={this.handleSubscribe}>{t('form.newsletter.submit')}</button></a>
                   {
                     (txt !== "") &&
                     <div style={{ width: '100%', textAlign: 'center', marginTop: '20px' }}>
                       <p style={{ marginBottom: '0' }}>{ txt }.</p>
                     </div>
                   }
               </div>
           </div>
       </section>
    );
  }
}

export default withTranslation()(NewsletterForm);

import React from 'react';

import {isNumber} from 'utils/helpers';

// Config
import configFile from 'config';


export function getPrice(currency, priceInEur, priceInGbp) {
  if ( (! isNumber(priceInEur)) && (! isNumber(priceInGbp)) ) {
    return NaN;
  }

  let out = NaN;

  // Set price directly
  switch (currency) {
    case '€':
      out = priceInEur;
      break;
    case '£':
      out = priceInGbp;
      break;
    default:
      return NaN;
  }

  // If direct price is invalid -> count from another currency
  if ( ! isNumber(out) ) {
    switch (currency) {
      case '€':
        out = priceGbpToEur(priceInGbp);
        break;
      case '£':
        out = priceEurToGbp(priceInEur);
        break;
      default:
        return NaN;
    }
  }

  return out;
}

export function displayPrice(currency, priceInEur, priceInGbp, positions = 2) {
  const price = getPrice(currency, priceInEur, priceInGbp);
  if ( isNaN(price) ) {
    return NaN;
  }

  // Format output
  switch (currency) {
    case '€':
      return (priceToFixed(price, positions) + "€");
    case '£':
      return ("£" + priceToFixed(price, positions));
    default:
      return NaN;
  }
}


/*
 * "fromTo":
 *  1 = from UK/SCOT => to SK/CZ
 *  2 = from SK/CZ => to UK
 *  3 = from SK/CZ => to SCOT
 */
export function calcPrice(currency, kg, fromTo) {
  const kgInt = parseInt(kg);
  if ( isNaN(kgInt) ) {
    return kgInt;
  }
  
  if (kgInt <= configFile.price.minKgFixedPrice) {
    // Kg is below/same as minimal limit for fixed price
    switch (fromTo) {
      case 1:
        return getPrice(currency, configFile.price.minFromUkInEUR, configFile.price.minFromUkInGBP);
      case 2:
        return getPrice(currency, configFile.price.minToUkInEUR, configFile.price.minToUkInGBP);
      case 3:
        return getPrice(currency, configFile.price.minToScotInEUR, configFile.price.minToScotInGBP);
      default:
        return NaN;
    }
  }
  else {
    // Kg is above minimal limit for fixed price
    switch (fromTo) {
      case 1:
        return kgInt * getPrice(currency, configFile.price.fromUkInEUR, configFile.price.fromUkInGBP);
      case 2:
        return kgInt * getPrice(currency, configFile.price.toUkInEUR, configFile.price.toUkInGBP);
      case 3:
        return kgInt * getPrice(currency, configFile.price.toScotInEUR, configFile.price.toScotInGBP);
      default:
        return NaN;
    }
  }
}


export function priceToFixed(price, positions = 2) {
  if ( ! Number.isInteger(positions) ) {
    // Return unformated
    return price;
  }
  return price.toFixed(positions);
}


export function priceEurToGbp(price) {
  return price * configFile.price.EURtoGBP;
}

export function priceGbpToEur(price) {
  return price / configFile.price.EURtoGBP;
}

import React, {useContext} from 'react';
import { Context } from '../../global.jsx';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import OrderBoxTransport from 'components/orderBoxTransport';

// Images
import img_arrow_right from 'images/arrow_right_white.png';

// Backend
import * as Dt from 'utils/dt';


const OrderBoxTransports = ({ vertical }) => {
  /*async componentDidMount() {
    if (this.props.loading_PlannedTransports === false) {
      if (this.props.plannedTransports === null) {
        this.props.dispatch({ type: 'LOADING_PLANNEDTRANSPORTS', payload: true });
        await this.mapToPlannedTransports();
        this.props.dispatch({ type: 'LOADING_PLANNEDTRANSPORTS', payload: false });
      }
    }
  }*/
  const { i18n, t } = useTranslation();
  const [state, dispatch] = useContext(Context);
  const { plannedTransports } = state;


  if (vertical) {

    if ( (plannedTransports !== null) && (plannedTransports.length === 0) ) return null;

    return (
      <section id="termin-prepravy-side">
          <div className="termin-prepravy">
              <h2>{t('nearesttermoftranpsport')}</h2>
              {
                (plannedTransports === null) ?
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className="border column">
                      {t('loading')} ...
                  </div>
                :
                  plannedTransports.map( item =>
                    <OrderBoxTransport
                      key={item.key}
                      vertical={true}
                      direction={item.direction}
                      dtStart={item.transport.dtStart}
                      dtClose={item.transport.dtClose}
                    />
                  )
              }

              {
                (plannedTransports !== null) &&
                <div id="order-button" className="column">
                    <Link to={i18n.language === "sk" ? "/objednavka-prepravy-balika" : "/order"} data-action="form">
                      <button>
                          {t('orderit')}
                          <img src={img_arrow_right} alt="" />
                      </button>
                    </Link>
                </div>
              }
          </div>
      </section>
    );

  } else {

    if ( (plannedTransports !== null) && (plannedTransports.length === 0) ) return (
      <div className="order-dates" style={{ height: '80px' }}>
      </div>
    );

    return (
      <div className="order-dates">
          <p id="headline">{t('nearesttermoftranpsport')}:</p>
          <div className="columns shadow bg-white">
              {
                (plannedTransports === null) ?
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className="flex-grow-1 column">
                      {t('loading')} ...
                  </div>
                :
                  plannedTransports.map( item =>
                    <OrderBoxTransport
                      key={item.key}
                      vertical={false}
                      direction={item.direction}
                      dtStart={item.transport.dtStart}
                      dtClose={item.transport.dtClose}
                    />
                )
              }

              {
                (plannedTransports !== null) &&
                <div id="order-button" className="flex-grow-1">
                    <Link to={i18n.language === "sk" ? "/objednavka-prepravy-balika" : "/order"} data-action="form">
                      <button>
                          {t('orderit')}
                          <img src={img_arrow_right} alt="" />
                      </button>
                    </Link>
                </div>
              }
          </div>
      </div>
    );

  }
};

export default OrderBoxTransports;

import config from 'config.json';
import * as Http from './httpService';
import * as Helpers from 'utils/helpers';
import * as Dt from 'utils/dt';
import * as Mapper from 'utils/mapper';

const apiRolesUrl = config.apiUrl + '/settings';

// Roles

export function mapToSettings(items) {
  let mappedItems = {};
  for ( let i = 0; i < items.length; i++ ) {
    const item = mapToSetting( items[i] );
    mappedItems[ item._id ] = item;
  }
  return mappedItems;
}

export function mapToSettingsByKey(items) {
  let mappedItems = {};
  for ( let i = 0; i < items.length; i++ ) {
    const item = mapToSetting( items[i] );
    mappedItems[ item.key ] = item;
  }
  return mappedItems;
}

export function mapToSetting(data) {     // mapToViewModel
  Helpers.renameObjKey(data, "pcId", "_id");
  data._id = Helpers.parsePc(data._id);

  return data;
}

export function mapFromSetting(data) {
  Helpers.renameObjKey(data, "_id", "pcId");

  return data;
}


export async function getSettings() {
  const response = await Http.get( apiRolesUrl + config.apiFormat );
  return mapToSettings( response );
}

export async function getSettingsByKeys() {
  const response = await Http.get( apiRolesUrl + config.apiFormat );
  return mapToSettingsByKey( response );
}

export async function updateSettings(data) {
  const response = await Http.post( apiRolesUrl + '/bar', data );
  return response;
}

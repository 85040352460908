import React, { Component, useContext } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
//import { withTranslation } from 'react-i18next';
//import './App.css';

// BACKEND
import {Context} from './global';
import * as DataAddressesService from 'services/dataAddressesService.js';
import * as DataBlogService from 'services/dataBlogService.js';
import * as DataTransportsService from 'services/dataTransportsService.js';

// JS
import AOS from 'aos';

// Assets
import './i18n';
import 'css/custom.css';
import 'css/media.css';
import 'css/aos.css';
import 'css/override.css';

// Pages
import IndexPage from './components/pages/indexPage';
import DeliveryPage from './components/pages/deliveryPage';
import MovingPage from './components/pages/movingPage';
import ShoppingPage from './components/pages/shoppingPage';
import BlogPage from './components/pages/blogPage';
import BlogArticlePage from './components/pages/blogArticlePage';
import OrderPage from './components/pages/orderPage';
import OrderCompletePage from './components/pages/orderCompletePage';
import ContactPage from './components/pages/contactPage';
import LoginPage from './components/pages/loginPage';
import Page404 from './components/pages/page404';

import InfoDiscountPage from './components/pages/info/discountPage';
import InfoCollectionPointsPage from './components/pages/info/collectionPointsPage';
import InfoChildrenPage from './components/pages/info/childrenPage';
import InfoDeliveryDatePage from './components/pages/info/deliveryDatePage';
import InfoTermsPage from './components/pages/info/termsPage';
import InfoUserPrivacyPage from './components/pages/info/userPrivacyPage';
import InfoUserPrivacyPage2 from './components/pages/info/userPrivacyPage2';
import InfoCookiesPage from './components/pages/info/cookiesPage';
import InfoHowToMakeOrderPage from './components/pages/info/howToMakeOrderPage';
import InfoPricingPage from './components/pages/info/pricingPage';
import InfoMapsPage from './components/pages/info/mapsPage';
import InfoDutyPage from './components/pages/info/dutyPage';

// Meta tags
import Meta from './components/meta';


// Admin pages

// Parts
import OrderForm from './components/forms/orderForm';
import NewsletterForm from './components/forms/newsletterForm';
import References from './components/parts/references';
import Blog from './components/parts/blog';
import Partnership from './components/parts/partnership';
import Footer from './components/parts/footer';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// ANALYTICS
import useAnalytics from './analytics';


const App = ({ }) => {
  useAnalytics();

  const [state, dispatch] = useContext(Context);
  return <AppComponent
      dispatch={dispatch}
      plannedTransports={state.plannedTransports}
    />
};


class AppComponent extends React.Component {

  // GLOBAL STATE >>>

  async mapToPlannedTransports() {
    let plannedTransports = [];

    const transports = await this.transportsLoad();
    if (transports === null) {
      this.props.dispatch({ type: 'SET_PLANNEDTRANSPORTS', payload: plannedTransports });
      return;
    }

    for (let i = 0; i < transports.length; i++) {
      if (i >= 2) {
        break;
      }
      plannedTransports[i] = {
        key: i,
        transport: transports[i],
        direction: await this.direction( transports[i] ),
      }
    }

    this.props.dispatch({ type: 'SET_PLANNEDTRANSPORTS', payload: plannedTransports });
  }


  // GET

  async addressesLoad(id) {
    try {
      const address = await DataAddressesService.getAddressById(id);
      if (address === null) {
        return null;
      }

      /* CAUSING LOOP!
      const addresses = { ...this.state.addresses };
      addresses[ address._id ] = address;
      this.setState({ addresses });*/

      return address;
    } catch(e) {
      return null;
    }
  }

  async blogsLoad() {
    try {
      const blogs = await DataBlogService.getBlogsOrdId("desc");
      this.props.dispatch({ type: 'SET_BLOGS', payload: blogs });
      return blogs;
    } catch(e) {
      return null;
    }
  }

  async transportsLoad() {
    try {
      const transports = await DataTransportsService.getTransportsByState( 1, true );
      //this.setState({ transports });
      return transports;
    } catch(e) {
      return null;
    }
  }


  // @return 0 = no direction; 1 = from Slovakia; 2 = from England
  async direction( transport ) {
    if ( (transport === null) || (transport.fcAddrSrc === null) || (transport.fcAddrSrc === null) ) {
      return 0;
    }
    // Load src address
    /*let srcAddr = this.state.addresses[ transport.fcAddrSrc ];
    if (srcAddr === undefined) {
      srcAddr = await this.addressesLoad(transport.fcAddrSrc);
      if (srcAddr === undefined) {
        return 0;
      }
    }*/
    const srcAddr = await this.addressesLoad(transport.fcAddrSrc);
    if (srcAddr === undefined) {
      return 0;
    }
    // Load dst address
    /*let dstAddr = this.state.addresses[ transport.fcAddrDst ];
    if (dstAddr === undefined) {
      dstAddr = await this.addressesLoad(transport.fcAddrDst);
      if (dstAddr === undefined) {
        return 0;
      }
    }*/
    const dstAddr = await this.addressesLoad(transport.fcAddrDst);
    if ( (srcAddr === undefined) || (dstAddr === undefined) || (srcAddr === null) || (dstAddr === null) ) {
      return 0;
    }

    if ( srcAddr.addrCountry === "sk" && dstAddr.addrCountry === "gb" ) {
      return 1;
    } else if ( srcAddr.addrCountry === "gb" && dstAddr.addrCountry === "sk" ) {
      return 2;
    } else {
      return 0;
    }
  }

  // <<< GLOBAL STATE



  componentDidMount() {
      /*//An array of assets
      let scripts = [
          //{ src: "js/jquery.waypoints.js" },
          //{ src: "js/waypoint.js" },
          //{ src: "js/noframework.waypoints.min.js" },
          { src: "https://unpkg.com/aos@2.3.1/dist/aos.js" },
      ]
      //Append the script element on each iteration
      scripts.map(item => {
          const script = document.createElement("script")
          script.src = item.src
          script.async = true
          document.body.appendChild(script)
      });*/

      AOS.init({
        duration : 2000
      })

      this.mapToPlannedTransports();
      this.blogsLoad();
   }


  render () {
    //const { t } = this.props;

    return (
      <>
        <ToastContainer />

        <Meta />

        <main>

          <Switch>
            <Route path={[ "/delivery", "/preprava-balikov-slovensko-anglicko" ]} component={DeliveryPage} />
            <Route path={[ "/moving", "/stahovanie-nabytku-v-anglicku" ]} component={MovingPage} />
            <Route path={[ "/food", "/nakupy-v-anglicku" ]} component={ShoppingPage} />
            <Route path="/blog" component={BlogPage} />
            <Route path={[ "/contact", "/kontakt" ]} component={ContactPage} />
            <Route path={[ "/order", "/objednavka-prepravy-balika" ]} component={OrderPage} />
            <Route path={[ "/order-complete", "/objednano" ]} component={OrderCompletePage} />

            <Route path={"/vernostna-zlava"} component={InfoDiscountPage} />
            <Route path={"/zberne-miesta"} component={InfoCollectionPointsPage} />
            <Route path={"/matko-a-kubko-pomaha-detom"} component={InfoChildrenPage} />
            <Route path={"/najblizsi-termin-prepravy"} component={InfoDeliveryDatePage} />
            <Route path={"/vseobecne-zmluvne-podmienky-ustanovenia-pri-preprave-zasielok-vzp"} component={InfoTermsPage} />
            <Route path={"/ochrana-osobnych-udajov1"} component={InfoUserPrivacyPage} />
            <Route path={"/ochrana-osobnych-udajov"} component={InfoUserPrivacyPage2} />
            <Route path={[ "/how-tofill-theorder", "/ako-vyplnit-objednavku" ]} component={InfoHowToMakeOrderPage} />
            <Route path={"/informacie-cookies"} component={InfoCookiesPage} />
            <Route path={[ "/pricelist", "/cennik" ]} component={InfoPricingPage} />
            <Route path={[ "/maps", "/mapy" ]} component={InfoMapsPage} />
            <Route path={[ "/vat", "/clo" ]} component={InfoDutyPage} />

            <Route path="/login" component={LoginPage} />

            <Route path="/" exact component={IndexPage} />

            <Route component={BlogArticlePage} />
          </Switch>

          <NewsletterForm />
          <References />
          <Blog />
          <Partnership />

          <Footer />
        </main>
      </>
    );
  }
}

//export default withTranslation()(App);
export default App;

import moment from 'moment';
import { isset } from 'utils/helpers';


export function isValidDate(date) {
  return date && Object.prototype.toString.call(date) === "[object Date]" && !isNaN(date);
}


export function fromHM(hours, minutes) {
  return moment( new Date(null, null, null, hours, minutes) );
}


export function today() {
  const dt = new Date();
  return {
    "dd": ('0' + dt.getDate()).slice(-2),
    "mm": ('0' + (dt.getMonth()+1)).slice(-2),
    "yy": dt.getFullYear().toString().substr(-2),
    "yyyy": dt.getFullYear(),
  }
}


// ---------------
// From MySQL
// ---------------

// https://stackoverflow.com/questions/3075577/convert-mysql-datetime-stamp-into-javascripts-date-format
// https://stackoverflow.com/questions/15141762/how-to-initialize-a-javascript-date-to-a-particular-time-zone
export function fromMysql(string) {
  if (isset(string)) {

    // Version 1
    /*let dt = string.split(/[- :T+]/);   // Split timestamp into [ Y, M, D, h, m, s, timezone ]
    //return new Date(Date.UTC(dt[0], dt[1], dt[2], dt[3], dt[4], dt[5]));   // Apply each element to the Date function
    return new Date(dt[0], dt[1], dt[2], dt[3], dt[4], dt[5]);*/

    // Negative timezone offset: https://stackoverflow.com/questions/17545708/parse-date-without-timezone-javascript
    // Version 2
    /*var date = new Date(string)
    var userTimezoneOffset = date.getTimezoneOffset() * 60000;
    return new Date(date.getTime() - userTimezoneOffset);*/

    // Version 3
    /*let dt = string.split(/[- :T+]/);   // Split timestamp into [ Y, M, D, h, m, s, timezone ]
    let datetime = new Date(dt[0], dt[1], dt[2], dt[3], dt[4], dt[5]);
    datetime.setTime( datetime.getTime() + datetime.getTimezoneOffset()*60*1000 );  // TO IGNORE TIMEZONE! https://stackoverflow.com/questions/439630/create-a-date-with-a-set-timezone-without-using-a-string-representation/439871#439871
    return datetime;*/

    return new Date(string);

  } else {
    return null;
  }
}

// https://stackoverflow.com/questions/3552461/how-to-format-a-javascript-date
export function fromDT(datetime) {
  /*if (isset(datetime)) {
    datetime.setTime( datetime.getTime() + datetime.getTimezoneOffset()*60*1000 );  // TO IGNORE TIMEZONE! https://stackoverflow.com/questions/439630/create-a-date-with-a-set-timezone-without-using-a-string-representation/439871#439871
    return {
      y : datetime.getFullYear(),
      m : datetime.getMonth(),
      d : datetime.getDate(),
      h : datetime.getHours(),
      i : datetime.getMinutes(),
      s : datetime.getSeconds(),
    };
  } else {
    return {
      y : "",
      m : "",
      d : "",
      h : "",
      i : "",
      s : "",
    };
  }*/
  if (isset(datetime)) {
    //datetime.setTime( datetime.getTime() + datetime.getTimezoneOffset()*60*1000 );  // TO IGNORE TIMEZONE! https://stackoverflow.com/questions/439630/create-a-date-with-a-set-timezone-without-using-a-string-representation/439871#439871
    return {
      y : moment(datetime).format("YYYY"),
      m : moment(datetime).format("MM"),
      d : moment(datetime).format("DD"),
      h : moment(datetime).format("HH"),
      i : moment(datetime).format("mm"),
      s : moment(datetime).format("ss"),
    };
  } else {
    return {
      y : "",
      m : "",
      d : "",
      h : "",
      i : "",
      s : "",
    };
  }
}


export function formatDT(datetime) {
  if (isset(datetime)) {

    if (typeof datetime === 'string' || datetime instanceof String) {
      datetime = new Date(Date.parse(datetime));
    }

    let dt = fromDT(datetime);

    return `${dt.d}.${dt.m}.${dt.y} ${dt.h}:${twoDigits(dt.i)}:${twoDigits(dt.s)}`;
  } else {
    return "";
  }
}

export function formatD(datetime) {
  if (isset(datetime)) {

    if (typeof datetime === 'string' || datetime instanceof String) {
      datetime = new Date(Date.parse(datetime));
    }

    let dt = fromDT(datetime);

    return `${dt.d}.${dt.m}.${dt.y}`;
  } else {
    return "";
  }
}


// ---------------
// To MySQL
// ---------------

// https://stackoverflow.com/questions/3552461/how-to-format-a-javascript-date
export function toDT(string) {
  if (! isset(string)) {
    return null;
  }

  const dt = string.split(/[-. :T+]/);

  if (dt.length >= 3) {
    let out = new Date(1970, 0, 0);
    out.setDate(dt[0]);
    out.setMonth(dt[1]);
    out.setYear(dt[2]);
    if (dt.length >= 4) out.setHours(dt[3]);
    if (dt.length >= 5) out.setMinutes(dt[4]);
    if (dt.length >= 6) out.setSeconds(dt[5]);

    return out;
  } else {
    return new Date(Date.parse(string));
  }
}


// https://stackoverflow.com/questions/5129624/convert-js-date-time-to-mysql-datetime
export function toMysql(datetime) {
  if ( ! (datetime instanceof Date)) {
    return null;
  }

  //return datetime.getUTCFullYear() + "-" + twoDigits(datetime.getUTCMonth()) + "-" + twoDigits(datetime.getUTCDate()) + " " + twoDigits(datetime.getUTCHours()) + ":" + twoDigits(datetime.getUTCMinutes()) + ":" + twoDigits(datetime.getUTCSeconds());

  return moment(datetime).format("YYYY-MM-DD HH:mm:ss");
}


function twoDigits(d) {
    if (0 <= d && d < 10) return "0" + d.toString();
    if (-10 < d && d < 0) return "-0" + (-1*d).toString();
    return d.toString();
}

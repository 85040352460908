import React from 'react';
import Joi from 'joi-browser';
import { toast } from 'react-toastify';


export const Input = ({ name, label, error, ...rest }) => {
  rest.value = rest.value ? rest.value : "";

  return (
    <>
      { label !== undefined &&
        <label htmlFor={name}>{label}</label>
      }
      <input {...rest} name={name} className="form-control" />
      { error && <div className="alert alert-danger">{error}</div> }
    </>
  )
}


export const Date = ({ name, label, error, ...rest }) => {
  rest.value = rest.value ? rest.value : "";

  return (
    <>
      <label htmlFor={name}>{label}</label>
      <input {...rest} name={name} className="form-control" />
      { error && <div className="alert alert-danger">{error}</div> }
    </>
  )
}


export const Textarea = ({ name, label, error, ...rest }) => {
  rest.value = rest.value ? rest.value : "";
  rest.rows = rest.rows ? rest.rows : 10;

  return (
    <>
      <label htmlFor={name}>{label}</label>
      <textarea {...rest} name={name} rows={rest.rows} className="form-control"></textarea>
      { error && <div className="alert alert-danger">{error}</div> }
    </>
  )
}


export const Select = ({ name, label, options, error, ...rest }) => {
  options = options ? options : [];
  rest.value = rest.value ? rest.value : "";

  return (
    <>
      <label htmlFor={name}>{label}</label>
      <select {...rest} name={name} className="form-control">
        <option value="" />
        { options.map( option => (
          <option key={option._id} value={option._id}>{option.name}</option>
        ))}
      </select>
      { error && <div className="alert alert-danger">{error}</div> }
    </>
  )
}



export default class Form extends React.Component {
  state = {
    data : {},
    errors : {},
  };


  // Validations

  validate = () => {
    const { error } = Joi.validate(this.state.data, this.schema, { abortEarly : true});
    if (! error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };


  validateProperty = ( name, value ) => {
    const obj = { [name] : value };
    const schema = { [name] : this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };


  // For submit

  handleSubmit = e => {
    e.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      // Scroll to top
      window.scrollTo(0, 400);
      toast.warn("Najskôr opravte chyby vo formulári.");
      return errors;
    }

    this.doSubmit();
  };


  // For inputs

  handleValidation = (name, value) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(name, value);

    if (errorMessage) errors[name] = errorMessage;
    else delete errors[name];

    return errors;
  }


  handleChangeInput = ({ currentTarget : input }) => {
    const errors = this.handleValidation(input.name, input.value);

    const data = { ...this.state.data };
    data[input.name] = input.value;

    this.setState({ data, errors });
  };



  handleChangeCheckbox = ({ currentTarget : input }) => {
    const errors = this.handleValidation(input.name, input.checked);

    const data = { ...this.state.data };
    data[input.name] = input.checked;
    /*this.setState({
      data : {
        [input.name] : !this.state.data[input.name]
      }
    });*/
    this.setState({ data, errors });
  }


  // Components

  renderInput(name, label, type="text") {
    const { data, errors } = this.state;

    return(
      <Input
        type={type}
        value={data[name]}
        onChange={this.handleChangeInput}

        name={name}
        label={label}
        error={errors[name]}
      />
    )
  };


  renderDate(name, label) {
    const { data, errors } = this.state;

    return(
      <Date
        value={data[name]}
        onChange={this.handleChangeInput}

        name={name}
        label={label}
        error={errors[name]}
      />
    )
  };


  renderTextarea(name, label, rows=10) {
    const { data, errors } = this.state;

    return(
      <Textarea
        rows={rows}
        value={data[name]}
        onChange={this.handleChangeInput}

        name={name}
        label={label}
        error={errors[name]}
      />
    )
  };


  renderCheckbox(name, label) {
    const { data, errors } = this.state;

    return(
      <Input
        type="checkbox"
        checked={data[name]}
        value={name}
        onChange={this.handleChangeCheckbox}

        name={name}
        label={label}
        error={errors[name]}
      />
    )
  };


  renderSelect(name, label, options) {
    const { data, errors } = this.state;

    return(
      <Select
        value={data[name]}
        options={options}
        onChange={this.handleChangeInput}

        name={name}
        label={label}
        error={errors[name]}
      />
    )
  }


  renderButton(label) {
    return(
      <button disabled={this.validate()}>{label}</button>
    )
  };

}

import React from 'react';
import { useTranslation } from 'react-i18next';

// Images
import img_map from 'images/map_europe.png';


const Numbers = (props) => {
  const { t } = useTranslation();

  return (
    <section id="numbers">
        <div className="container">
            <h3 className="headline-left">{t('matkoandkubko')} {t('numbers')}</h3>
            <div className="content">
                <ul className="number-list">
                    <li data-count="1040000" className="number-list-item" data-aos="flip-right" data-aos-duration="5000"> 1 040 000</li>
                    <p>{t('numbers.km')} 2000</p>
                    <li data-count="38000" className="number-list-item" data-aos="flip-right" data-aos-duration="6000">38 000</li>
                    <p>{t('numbers.cusomers')}</p>
                    <li data-count="125000" className="number-list-item" data-aos="flip-right" data-aos-duration="7000">125 000</li>
                    <p>{t('numbers.transports')}</p>
                    <li data-count="5600" className="number-list-item" data-aos="flip-right" data-aos-duration="8000">5 600</li>
                    <p>{t('numbers.fans')}</p>
                </ul>
                <div className="image">
                    <img src={img_map} alt="" />
                </div>
            </div>
        </div>
    </section>
  )
}

export default Numbers;

import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Images
import img_logo_footer from 'images/logo_MAK_footer_2x.png';
import img_icon_phone_white from 'images/icon_phone_white.png';
import img_icon_facebook from 'images/icon_facebook.svg';


const Footer = (props) => {
  const { i18n, t } = useTranslation();
  const lang = i18n.language;

  return (
    <footer>
        <div className="container">
            <div className="row kontakt">
                <div id="phones" className="kontakt-item col-lg-6">
                    <span id="title">{t('contact')}</span>
                    <span id="phone">
                        <img id="icon_phone" src={img_icon_phone_white} alt="" />
                        +421 949 105 003
                    </span>
                    <span id="phone">
                        <img id="icon_phone" src={img_icon_phone_white} alt="" />
                        +44 789 400 76 79
                    </span>
                </div>
                <div id="hours" className="col-lg-6 kontakt-item">
                    <span id="title">{t('openinghours')}</span>
                    <span id="opening-hours">{t('monday')} - {t('friday')} (9:00 - 17:00)</span>
                </div>
            </div>
        </div>
        <div className="footer-menu ">
            <div className="container">
                <div className=" row ">
                    <div className="menu-item col-lg-3">
                        <img src={img_logo_footer} alt="" />
                    </div>
                    <div className="menu-item col-lg-2">
                        <ul>
                          <li><NavLink to={lang === "sk" ? "/preprava-balikov-slovensko-anglicko" : "/delivery"}>{t('delivery')}</NavLink></li>
                          <li><NavLink to={lang === "sk" ? "/blog" : "/blog"}>{t('blog')}</NavLink></li>
                          <li><NavLink to="/vernostna-zlava">Vernostná zľava</NavLink></li>
                        </ul>
                    </div>

                    <div className="menu-item col-lg-2">
                        <ul>
                            <li><NavLink to={lang === "sk" ? "/stahovanie-nabytku-v-anglicku" : "/moving"}>{t('moving')}</NavLink></li>
                            <li><NavLink to={lang === "sk" ? "/kontakt" : "/contact"}>{t('contact')}</NavLink></li>
                            <li><NavLink to="/matko-a-kubko-pomaha-detom">Preprava zadarmo</NavLink></li>
                            <li><NavLink to="/vseobecne-zmluvne-podmienky-ustanovenia-pri-preprave-zasielok-vzp">Prepravné podmienky</NavLink></li>
                        </ul>
                    </div>

                    <div className="menu-item col-lg-2">
                        <ul>
                            <li><NavLink to={lang === "sk" ? "/nakupy-v-anglicku" : "/food"}>{t('shopping')}</NavLink></li>
                            <li><NavLink to={lang === "sk" ? "/objednavka-prepravy-balika" : "/order"}>{t('orderit')}</NavLink></li>
                            <li><NavLink to="/zberne-miesta">Zberné miesta</NavLink></li>
                            <li><NavLink to="/ochrana-osobnych-udajov">Ochrana osobných údajov</NavLink></li>
                        </ul>
                    </div>

                    <div className="menu-item col-lg-3">
                        <span><img height="20" width="auto" src={img_icon_facebook} alt="" /></span>
                        <a target="_blank" href="https://www.facebook.com/MatkoAKubko/">Sledujte nás na facebooku</a>
                    </div>
                </div>
            </div>
        </div>
        <div className="copyright text-center">
            <strong>{t('createdby')} <a target="_blank" href="http://www.diastudio.cz/">diastudio.cz</a>, <a target="_blank" href="https://www.fzatloukal.cz/">Ing. Filip Zatloukal</a></strong>
        </div>
    </footer>
  )
}

export default Footer;

import React from 'react'
import { Link } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';

import Header2 from '../../parts/header2';
import Side from '../../parts/side';

// Images


const InfoUserPrivacyPage2 = (props) => {
  const { i18n, t } = useTranslation();

  return(
    <>
      <section id="home">
          <Header2 props={props} />
      </section>

      <section id="content" className="border-top">
          <div className="container">
              <h2 className="headline-left">Ochrana osobných údajov</h2>
              <div className="row">

                  <div className="col-xl-8 main">
                    <section id="we-transport">
                      <p>Zaujíma vás, ako Maťko a Kubko chráni vaše osobné údaje? Prečítajte si naše podmienky ochrany osobných údajov.</p>
                      <p>Pri spracovaní osobných údajov postupuje spoločnosť Matko a Kubko Logistics LTD podľa Nariadenia Európskeho parlamentu a Rady EÚ č. 2016/679 o ochrane fyzických osôb pri spracovaní údajov a voľnom pohybe týchto údajov a o zrušení Smernice č. 95/46 ES (všeobecné nariadenie o ochrane osobných údajov), v súlade so Zákonom č. 18/2018 Z. z. o ochrane osobných údajov v znení neskorších predpisov (ďalej len „zákon“).</p>
                      <p>Keďže spoločnosť Matko a Kubko Logistics LTD poskytuje prepravné služby, pri práci s poskytnutými dátami vystupujeme ako prevádzkovateľ osobných údajov, ktorý sám alebo spoločne s inými, určí účely a prostriedky spracovania osobných údajov.</p>
                      <p>Aby bolo možné poskytovať naše služby, ktoré si zákazník objedná, sme oprávnení spracovať osobné údaje zákazníka alebo údaje, ktoré nám zákazník poskytne alebo zadá pri registrácii. Takéto spracovanie osobných údajov je zákonné, pretože pre plnenie zmluvy so subjektom (poskytovanie našich služieb zákazníkom) je nevyhnutné.</p>
                      <p>V týchto zásadách o ochrane osobných údajov sa dočítate informácie o tom, aké osobné údaje spracúvame a ako ich spracúvame. Ide o údaje, ktoré sú nám poskytnuté pre splnenie objednávky.</p>

                      <h3>1. Základné pojmy podľa GDPR</h3>
                      <p>GDPR – je skratka z anglického pojmu General Data Protection Regulation, čiže všeobecné nariadenie o ochrane osobných údajov, teda Nariadenie Európskeho parlamentu a Rady EÚ č. 2016/679 z 27. apríla 2016 o ochrane fyzických osôb pri spracovaní osobných údajov a voľnom pohybe týchto údajov a o zrušení Smernice č. 95/46/ES (ďalej len „GDPR“). Ide o nový právny predpis EÚ, ktorým sa zaviedla európska reforma ochrany osobných údajov. V celej EÚ nadobudlo toto nariadenie účinnosť 25. mája 2018. Je priamo záväzné a má prednosť pre vnútroštátnymi právnymi predpismi.</p>
                      <p>Dotknutá osoba je nositeľ osobných údajov, teda akákoľvek identifikovaná alebo identifikovateľná fyzická osoba. Dotknutou osobou je fyzická osoba, ktorá si u prevádzkovateľa objedná prepravné služby, alebo ktorej prevádzkovateľ doručuje zásielkou, objednanú inou dotknutou osobou. Môže to byť tiež osoba, ktorej prevádzkovateľ zasiela notifikácie, týkajúce sa objednávky prepravných služieb.</p>
                      <p>Prevádzkovateľ je fyzická alebo právnická osoba, orgán verejnej moci, agentúra alebo iný subjekt, ktorý sám alebo spoločne s inými, určí účely a prostriedky spracovania osobných údajov.</p>
                      <p>Príjemca údajov je fyzická alebo právnická osoba, orgán verejnej moci, agentúra alebo iný subjekt, ktorým sú osobné údaje poskytnuté. Ak príjemca údaje ďalej spracováva, stáva sa ďalším prevádzkovateľom, ale z pohľadu pôvodného prevádzkovateľa je to irelevantné. Príjemcom však nie je orgán verejnej moci, ktorý údaje môže získavať v rámci svojej činnosti.</p>
                      <p>Osobné údaje sú informácie o dotknutej osobe, na základe ktorých ju možno priamo alebo nepriamo identifikovať. Ide najmä o určitý identifikátor ako meno, identifikačné číslo, lokalizačné údaje, sieťový identifikátor alebo jeden, či viaceré faktory, špecifické fyzickou, fyziologickou, genetickou, mentálnou, ekonomickou, kultúrnou alebo spoločenskou identitou tejto fyzickej osoby.</p>
                      <p>Spracovanie je akákoľvek operácia alebo súbor operácií s osobnými údajmi alebo súbormi osobných údajov, ktorá je vykonávaná pomocou automatizovaných postupov, ako je zhromaždenie, zaznamenanie, usporiadanie, štruktúrovanie, uloženie, prispôsobenie alebo pozmenenie, vyhľadanie, nahliadnutie, používanie, sprístupnenie prenosom, šírenie alebo akékoľvek iné sprístupnenie, zoradenie či skombinovanie, obmedzenie, vymazanie alebo zničenie.</p>

                      <h3>2. Aké osobné údaje spracúvame?</h3>
                      <p>Spoločnosť Matko a Kubko Logistics LTD bežne spracúva tieto osobné údaje:</p>
                      <ul>
                        <li>Údaje, ktoré nám umožňujú kontaktovať zákazníkov, teda kontaktné údaje ako sú emailová adresa, telefónne číslo, adresa na vyzdvihnutie alebo doručenie zásielky a fakturačná adresa.</li>
                        <li>Identifikačné údaje – meno, priezvisko alebo užívateľské meno.</li>
                        <li>Údaje o objednanej službe. Tieto údaje obsahujú informácie o prepravovanej zásielke, o spôsobe platby, vrátane bankového účtu alebo detailov platobnej karty a tiež údaje o prípadných reklamáciách.</li>
                        <li>Údaje, spojené s použitím call centra – môže ísť o nahrávky hovorov.</li>
                        <li>Údaje o vašom správaní na našom webe. Sem patrí IP adresa, identifikácia zariadení a tiež údaje, získané zo súborov cookies a podobných technológií. Môže ísť napríklad o voľbu jazyka, či ďalšie preferencie zobrazenia, o nastavenia bezpečnosti, správy siete alebo štatistické údaje, ktoré sú vyhodnotené z návštevnosti našej webovej stránky.</li>
                      </ul>

                      <h3>3. Účely spracovania osobných údajov</h3>
                      <p>Spracovanie vašich osobných údajov ako sú meno, priezvisko, adresa, telefónny kontakt a emailová adresa, je nutné pre úspešné vybavenie objednávky.</p>
                      <p>Vaše osobné údaje spracúvame za účelom vybavenia objednávky zákazníka (dotknutej osoby) a za účelom informovania a kontaktovania dotknutej osoby o plnení objednávky, prípadne aj za účelom reklamačného konania a vykonania práv a povinností, vyplývajúcich zo zmluvného vzťahu medzi príkazcom a zasielateľom.</p>
                      <p>Zákonným dôvodom pre spracovanie osobných údajov pri vybavovaní objednávky je plnenie zmluvného vzťahu podľa čl. 6 ods. 1 písm. b) GDPR, plnením právnej povinnosti prevádzkovateľa podľa čl. 6 ods. 1 písm. c) GDPR a oprávnený záujem prevádzkovateľa podľa čl. 6 ods. 1 písm. f) GDPR, ktorý spočíva v ochrane vlastných nárokov spojených so zmluvným vzťahom alebo v súvislosti so zasielaním notifikácií týkajúcich sa objednávky.</p>
                      <p>Údaje, spojené s využitím call centra, spracúvame za účelom vybavenia požiadaviek alebo reklamácií zákazníkov. Zákonným dôvodom pre spracúvanie osobných údajov v rámci call centra je súhlas, udelený prevádzkovateľovi.</p>
                      <p>Osobné údaje, získané zaznamenávaním súborov cookies, spracúvame na rôzne účely, napríklad na účel ukladania vašich predvolieb bezpečného vyhľadávania, na výber relevantných reklám, sledovanie počtu návštevníkov na stránke, zjednodušenie registrácie do nových služieb a na ochranu vašich údajov. Zákonným dôvodom, pre spracúvanie údajov v rámci súborov cookies je súhlas, udelený prevádzkovateľovi.</p>

                      <h3>4. Doba uchovávania osobných údajov</h3>
                      <p>Osobné údaje zákazníka sú uchovávané na dobu nevyhnutnú na výkon práv a povinností, ktoré vyplývajú zo zmluvného vzťahu medzi príkazcom a zasielateľom a dobu uplatňovania nárokov z týchto zmluvných vzťahov. Na účely plnenia právnych povinností, vyplývajúcich zo zmluvných vzťahov a najmä z platných právnych predpisov, uchovávame osobné údaje po dobu 10 rokov.</p>
                      <p>Na účely vybavenia otázok prípadne reklamácií sa uchovávajú nahrávky hovoru len po dobu, ktorá je nevyhnutná na vybavenie danej reklamácie alebo dopytu, najviac však po dobu 1/2 roka, ak nie je nevyhnutná iná doba spracúvania.</p>
                      <p>Osobné údaje, získané zo súborov cookies, sa spracúvajú po dobu 1 mesiaca (nevyhnutné cookies) a po dobu 2 rokov (funkčné a reklamné cookies).</p>
                      <p>Zabezpečenie spracovania osobných údajov je vykonávané v súlade s čl. 32 GDPR. Osobné údaje sú spracovávané v súlade s GDPR spôsobom, ktorý zaručuje bezpečnosť a dôvernosť týchto údajov, vrátane zabránenia neoprávnenému prístupu a osobných údajom a k zariadeniam, používaným na ich spracovanie alebo ich neoprávnenému použitiu.</p>

                      <h3>5. Príjemcovia osobných údajov, ktorým môžu byť osobné údaje sprístupnené</h3>
                      <p>Objednávkou služieb spoločnosti Matko a Kubko Logistics LTD beriete na vedomie, že osobné údaje budú poskytnuté ďalším príjemcom. Ide o týchto príjemcov:</p>
                      <ul>
                        <li>Príjemcovia, ktorí sú súčasťou plnenia služby pre zákazníka (zmluvný dopravcovia, podieľajúci sa na realizácii prepravy)</li>
                        <li>Prevádzkovatelia marketingových nástrojov a marketingové agentúry</li>
                        <li>Príjemcovia osobných údajov, ktorí zabezpečujú realizáciu platieb na základe zmluvy</li>
                        <li>Príjemcovia, ktorí poskytujú služby prevádzkovania webovej aplikácie a dátového úložiska a IT spoločnosti</li>
                        <li>Advokáti a daňoví poradcovia.</li>
                      </ul>
                      <p>Zoznam konkrétnych príjemcov osobných údajov:<br/>
                        Facebook<br/>
                        Google LLC (Google Ads, Google Analytics, Google Disk, Google Tag manager)</p>

                      <h3>6. Odovzdanie osobných údajov do zahraničia</h3>
                      <p>Prenos osobných údajov do zahraničia je nevyhnutný pre splnenie objednanej služby pri objednávke prepravy s miestom doručenia v zahraničí. V takom prípade sú osobné údaje odovzdávané tiež oprávneným prepravným spoločnostiam a colným orgánom príslušnej krajiny na účely colného konania.</p>
                      <p>Spoločnosť Matko a Kubko Logistic LTD môže byť v niektorých prípadoch nútená odovzdať osobné informácie miestnym alebo zahraničným orgánom štátnej moci, aby sa vyhovelo platným právnym predpisom v danej krajine alebo v záujme doručenia zásielky na miesto určenia. Zahraničné krajiny nemusia uplatňovať rovnaké zákony na ochranu údajov ako krajina, v ktorej ste osobné informácie poskytli.</p>
                      <p>Ak je miestom doručenia zásielky krajina mimo EÚ, klient berie na vedomie, že prevádzkovateľ bude prenášať osobné údaje do krajín mimo EÚ z dôvodu plnenia predmetu uzavretej zasielateľskej zmluvy.</p>
                      <p>Prevádzkovateľ vykonáva prenos pri poskytovaní osobných údajov externému poskytovateľovi dátového úložiska (Google Disk) a externých softvérových služieb (Google Ads, Google Analytics, Facebook), pričom vždy kladie dôraz na dostatočne prijaté opatrenia na zabezpečenie ochrany osobných údajov.</p>

                      <h3>7. Práva dotknutej osoby</h3>
                      <p>Spoločnosť Matko a Kubko Logistics LTD rešpektuje základné práva a slobody dotknutých osôb. GDPR zvyšuje právo na súkromie dotknutej osoby. Podľa GDPR majú dotknuté osoby tieto práva:</p>
                      <ul>
                        <li>Právo byť informované o spracúvaní svojich osobných údajov a mať prístup k nim (čl. 15 GDPR). Dotknutá osoba má právo získať od prevádzkovateľa potvrdenie o tom, či sa spracúvajú jej osobné údaje a ak áno, má právo získať prístup k týmto osobným údajom a získať informácie o:</li>
                        <ul>
                          <li>Účele spracúvania osobných údajov</li>
                          <li>Kategórii spracúvaných osobných údajov</li>
                          <li>Identifikácii príjemcu alebo kategórii príjemcu, ktorému boli alebo majú byť osobné údaje poskytnuté, najmä o príjemcovi v tretej krajine alebo o medzinárodnej organizácii, ak je to možné</li>
                          <li>Dobe uchovávania osobných údajov; ak to nie je možné, informáciu o kritériách jej určenia</li>
                          <li>O práve požadovať od prevádzkovateľa opravu osobných údajov týkajúcich sa dotknutej osoby, ich vymazanie alebo obmedzenie ich spracúvania, alebo o práve namietať spracúvanie osobných údajov</li>
                          <li>O práve podať návrh na začatie konania podľa § 100 Zákona alebo podať sťažnosť dozornému orgánu podľa ustanovenia čl. 77 GDPR</li>
                          <li>O zdroji osobných údajov, ak sa osobné údaje nezískali od dotknutej osoby</li>
                          <li>existencii automatizovaného individuálneho rozhodovania vrátane profilovania podľa ustanovenie čl. 22 ods. 1 a 4 GDPR a ustanovenia § 28 ods. 1 a 4 Zákona (v týchto prípadoch poskytne prevádzkovateľ dotknutej osobe informácie najmä o použitom postupe, ako aj o význame a predpokladaných dôsledkoch takého spracúvania osobných údajov pre dotknutú osobu</li>
                        </ul>
                        <li>Na opravu svojich nesprávnych osobných údajov (čl. 16 GDPR). Dotknutá osoba má právo na to, aby prevádzkovateľ bez zbytočného odkladu opravil nesprávne osobné údaje, ktoré sa jej týkajú. So zreteľom na účel spracúvania osobných údajov má dotknutá osoba právo na doplnenie neúplných osobných údajov.</li>
                        <li>Na vymazanie svojich osobných údajov (čl. 17 GDPR). Dotknutá osoba má právo na to, aby prevádzkovateľ bez zbytočného odkladu vymazal osobné údaje, ktoré sa jej týkajú. Prevádzkovateľ je povinný bez zbytočného odkladu vymazať osobné údaje, ak dotknutá osoba uplatnila právo na výmaz podľa predchádzajúcej vety, ak</li>
                        <ul>
                          <li>Osobné údaje už nie sú potrebné na účel, na ktorý sa získali alebo inak spracúvali</li>
                          <li>Dotknutá osoba odvolá svoj súhlas so spracúvaním osobných údajov a neexistuje iný právny základ pre spracúvanie osobných údajov</li>
                          <li>Dotknutá osoba namieta spracúvanie osobných údajov a neprevažujú žiadne oprávnené dôvody na spracúvanie osobných údajov</li>
                          <li>Osobné údaje sa spracúvajú nezákonne</li>
                          <li>Je dôvodom pre výmaz splnenie povinnosti podľa GDPR, Zákona, osobitného predpisu alebo medzinárodnej zmluvy, ktorou je Slovenská republika viazaná</li>
                          <li>Osobné údaje sa získavali v súvislosti s ponukou služieb informačnej spoločnosti.</li>
                        </ul>
                        <li>Na obmedzenie spracúvania (čl. 18 GDPR). Dotknutá osoba má právo na to, aby prevádzkovateľ obmedzil spracúvanie osobných údajov, ak:</li>
                        <ul>
                          <li>dotknutá osoba namieta správnosť osobných údajov, a to počas obdobia umožňujúceho prevádzkovateľovi overiť správnosť osobných údajov</li>
                          <li>spracúvanie osobných údajov je nezákonné a dotknutá osoba namieta vymazanie osobných údajov a žiada namiesto toho obmedzenie ich použitia</li>
                          <li>prevádzkovateľ už nepotrebuje osobné údaje na účel spracúvania osobných údajov, ale potrebuje ich dotknutá osoba na uplatnenie právneho nároku</li>
                          <li>dotknutá osoba namieta spracúvanie, a to až do overenia, či oprávnené dôvody na strane prevádzkovateľa prevažujú nad oprávnenými dôvodmi dotknutej osoby.</li>
                        </ul>
                        <li>Na získanie svojich osobných údajov a ich prenesenie inému prevádzkovateľovi (čl. 20 GDPR). Dotknutá osoba má právo získať osobné údaje, ktoré sa jej týkajú a ktoré poskytla prevádzkovateľovi, v štruktúrovanom, bežne používanom a strojovo čitateľnom formáte a má právo preniesť tieto údaje ďalšiemu prevádzkovateľovi. Právo na prenosnosť nesmie mať nepriaznivé dôsledky na práva a slobody iných. Toto právo platí ak:</li>
                        <ul>
                          <li>Sa spracúvanie zakladá na súhlase alebo zmluve</li>
                          <li>Sa spracúvanie vykonáva automatizovanými prostriedkami</li>
                        </ul>
                        <li>Namietať voči spracúvaniu údajov z dôvodov, týkajúcich sa jej konkrétnej situácie, ktorá je vykonávané na základe oprávnených záujmov alebo realizovania dôležitej úlohy vo verejnom záujme (čl. 21 GDPR). Ak sa osobné údaje spracúvajú na účely priameho marketingu, dotknutá osoba má právo kedykoľvek namietať proti spracúvaniu svojich osobných údajov vrátane profilovania. Ak dotknutá osoba namieta voči spracúvaniu na účely priameho marketingu, prevádzkovateľ už jej osobné údaje na tieto účely nesmie spracúvať.</li>
                      </ul>
                      <p>a to pod podmienkou, že sú splnené potrebné zákonné požiadavky.</p>

                      <p>Dotknutá osoba má <strong>právo kedykoľvek odvolať súhlas so spracúvaním osobných údajov</strong>, ktoré sa jej týkajú. Odvolanie súhlasu nemá vplyv na zákonnosť spracúvania osobných údajov založeného na súhlase pred jeho odvolaním. Dotknutá osoba môže súhlas odvolať rovnakým spôsobom, akým súhlas udelila.</p>
                      <p>Dotknutá osoba má právo podať sťažnosť na Úrade na ochranu osobných údajov SR v prípade, že sa domnieva, že bolo porušené jej právo na ochranu osobných údajov. Vzor návrhu na začatie konania bude uverejnený na webovom sídle Úradu na ochranu osobných údajov.</p>
                      <p>Prehlasujeme, že údaje sú spracúvané korektne a zhromažďované sú na určité a legitímne účely a zároveň spracúvané spôsobom, ktorý zabezpečí ich náležité zabezpečenie pred neoprávneným či protiprávnym spracovaním.</p>
                      <p>Odoslaním objednávky z internetového objednávkového formulára potvrdzujete, že ste oboznámený/á s podmienkami ochrany osobných údajov, a že ich v celom rozsahu a bez výhrad prijímate a súhlasíte s použitím svojich osobných údajov na účely plnenia nevyhnutné na výkon práv a povinností, vyplývajúcich zo zmluvného vzťahu medzi príkazcom a zasielateľom.</p>
                      <p>Tieto Zásady ochrany osobných údajov vstupujú do platnosti dňom ich vydania, t. j. 14. 11. 2021</p>
          					</section>
                  </div>

                  <div className="col-xl-4 side">
                      <Side />
                  </div>

              </div>
          </div>
      </section>
    </>
  );
}

export default InfoUserPrivacyPage2;

import config from 'config.json';
import * as Http from './httpService';
import * as Helpers from 'utils/helpers';
import * as Dt from 'utils/dt';
import * as Mapper from 'utils/mapper';

const apiNewslettersUrl = config.apiUrl + '/newsletters';

// Newsletters

export function mapToNewsletters(items) {
  let mappedItems = {};
  for ( let i = 0; i < items.length; i++ ) {
    const item = mapToNewsletter( items[i] );
    mappedItems[ item._id ] = item;
  }
  return mappedItems;
}

export function mapToNewsletter(data) {     // mapToViewModel
  Helpers.renameObjKey(data, "pcId", "_id");
  data._id = Helpers.parsePc(data._id);

  return data;
}

export function mapFromNewsletter(data) {
  Helpers.renameObjKey(data, "_id", "pcId");

  return data;
}


export async function insertEmail(email) {
  //const response = await Http.post( apiNewslettersUrl + '/subscribe', mapFromNewsletter(email) );
  const response = await Http.post( apiNewslettersUrl + '/subscribe', mapFromNewsletter({'email': email}) );
  return mapToNewsletter( response );
}

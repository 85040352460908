import React, {useContext} from 'react';
import { Context } from '../../../global.jsx';
import { Link } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';

import Header2 from '../../parts/header2';
import Side from '../../parts/side';
import OrderBoxTransports from '../../parts/orderBoxTransports';

// Images
import img_map from 'images/pages/mapa_uk_nova_20202.jpg';
import img_map_codes from 'images/pages/mapa_uk_post_codes2_01.jpg';


const InfoMapsPage = (props) => {
  const { i18n, t } = useTranslation();

  return(
    <>
      <section id="home">
          <Header2 props={props} />
      </section>

      <section id="content" className="border-top">
          <div className="container">
              <h2 className="headline-left">Mapa a trasy</h2>
              <div className="row">

                  <div className="col-xl-8 main">
                    <section id="we-transport">
          						<p><Trans i18nKey="pageInfo.deliveryDate.introduction"><a href="/preprava-balikov-slovensko-anglicko"></a></Trans></p>
                      <p>{t('pageInfo.maps.introduction2')}</p>
          						<Link to={i18n.language === "sk" ? "/objednavka-prepravy-balika" : "/order"} data-action="form"><button>{t('orderit')}</button></Link>

                      <div className="row" style={{ paddingTop: "10px" }}>

                      </div>

          						<div style={{ paddingTop: "10px" }}>
            						<h3>{t('routes')}</h3>
            						<p><Trans i18nKey="pageInfo.locations"><a href="/preprava-balikov-slovensko-anglicko"></a></Trans></p>
            						<p>{t('legend')}:</p>

            						<span style={{ backgroundColor: "#6f92b5"}}>{t('blue')}</span> {t('area')} – {t('thursday')}/{t('friday')} {t('pageInfo.beforeTransportDate')}
              						<br/><span style={{ backgroundColor: "#d77d53" }}>{t('red')}</span> {t('area')} – {t('friday')} {t('pageInfo.beforeTransportDate')}
              						<br/><span style={{ backgroundColor: "#f5f46c" }}>{t('yellow')}</span>/<span style={{ backgroundColor: "#89e99d" }}>{t('green')}</span> – {t('sunday')} {t('pageInfo.beforeTransportDate')}
                          <br/><span style={{ backgroundColor: "#db73b5" }}>{t('purple')}</span> – {t('saturday')}/{t('sunday')} {t('pageInfo.beforeTransportDate')}
              						<br/><span style={{ backgroundColor: "#747573" }}>{t('grey')}</span> {t('area')} – <Trans i18nKey="pageInfo.grayCollection"><a href="/kontakt"></a></Trans>

                        <section id="preprava_balikov">
              						<img src={img_map} alt="mapa rozvozu" />
              						<a href={img_map}> {t('clickToEnlarge')}</a>
              						<img style={{ paddingTop: "20px" }} src={img_map_codes} alt="mapa rozvozu" />
              						<a href={img_map_codes}> {t('clickToEnlarge')}</a>
                        </section>
                      </div>

          					</section>
                  </div>

                  <div className="col-xl-4 side">
                      <Side />
                  </div>

              </div>
          </div>
      </section>
    </>
  );
}

export default InfoMapsPage;

import React from 'react'
import { Link } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';

import Header2 from '../../parts/header2';
import Side from '../../parts/side';

// Images
import img_vernostna_zlava from 'images/pages/vernostna_zlava.jpg';


const InfoDiscountPage = (props) => {
  const { i18n, t } = useTranslation();

  return(
    <>
      <section id="home">
          <Header2 props={props} />
      </section>

      <section id="content" className="border-top">
          <div className="container">
              <h2 className="headline-left">{t('loyaltyDiscount')}</h2>
              <div className="row">

                  <div className="col-xl-8 main">
                    <section id="preprava_balikov">
                      <img src={img_vernostna_zlava} alt="Obrazek s textem vernostna zlava" />
                      <p><Trans i18nKey="pageInfo.discount.introduction"><b></b></Trans></p>
                    </section>
                    <section id="we-transport">
                      <h3 class="section-title">{t('pageInfo.discount.how')}</h3>
                        <p><Trans i18nKey="pageInfo.discount.howText1"><b></b></Trans></p>
            						<p><Trans i18nKey="pageInfo.discount.howText2"><a href="https://matkoakubko.com/"></a><a href="https://matkoakubko.com/preprava-balikov-slovensko-anglicko"></a></Trans></p>
            						<p><Trans i18nKey="pageInfo.discount.howText3"><a href="https://matkoakubko.com/preprava-balikov-slovensko-anglicko"></a><b></b></Trans></p>
                    </section>

                    <section>
                        <p><Trans i18nKey="page.delivery.order"><span></span></Trans></p>
                        <Link to={i18n.language === "sk" ? "/objednavka-prepravy-balika" : "/order"} data-action="form"><button>{t('orderit')}</button></Link>
                    </section>
                  </div>

                  <div className="col-xl-4 side">
                      <Side />
                  </div>

              </div>
          </div>
      </section>
    </>
  );
}

export default InfoDiscountPage;

import config from 'config.json';
import * as Http from './httpService';
import * as Helpers from 'utils/helpers';
import { isset } from 'utils/helpers';
import * as Dt from 'utils/dt';

const apiAddressesUrl = config.apiUrl + '/addresses';

/* Address types:
0-9   = global (= customer + internal)
10-19 = customer (invoicing, delivery, ...)
20-29 = internal
30-39 = internal transport only
40-49 = internal route only
50-59 = internal contract only

x0  = all purposes
x1  = start only for internals
x2  = finish only for internals

Customers:
01  = CURRENT invoicing
02  = INACTIVE invoicing
03  = CURRENT pickup(start)
04  = INACTIVE pickup(start)
05  = CURRENT delivery(finish)
06  = INACTIVE delivery(finish)
*/


// Functions

export function areAddressesEqualWithoutPc(addr1, addr2) {
  if ( (! isset(addr1) && isset(addr2)) || (isset(addr1) && ! isset(addr2)) ) {
    return false;
  }

  if (addr1.type !== addr2.type) { return false };
  if (addr1.lastname !== addr2.lastname) { return false };
  if (addr1.firstname !== addr2.firstname) { return false };
  if (addr1.company !== addr2.company) { return false };
  if (addr1.phone !== addr2.phone) { return false };
  if (addr1.phoneCode !== addr2.phoneCode) { return false };
  if (addr1.addrStreet !== addr2.addrStreet) { return false };
  if (addr1.addrCity !== addr2.addrCity) { return false };
  if (addr1.addrZip !== addr2.addrZip) { return false };
  if (addr1.addrCountry !== addr2.addrCountry) { return false };
  return true;
}


// Addresses

export function mapToAddresses(items) {
  let mappedItems = {};
  for ( let i = 0; i < items.length; i++ ) {
    const item = mapToAddress( items[i] );
    mappedItems[ item._id ] = item;
  }
  return mappedItems;
}

export function mapToAddress(data) {     // mapToViewModel
  Helpers.renameObjKey(data, "pcId", "_id");
  data._id = Helpers.parsePc(data._id);

  return data;
}

export function mapFromAddress(data) {
  Helpers.renameObjKey(data, "_id", "pcId");
  //data.phone = parseInt(data.phone);
  //data.addrZip = parseInt(data.addrZip);

  return data;
}


export async function getAddresses() {
  const response = await Http.get( apiAddressesUrl + config.apiFormat );
  return mapToAddresses( response );
}

export async function getAddressById( pcId ) {
  const response = await Http.get( apiAddressesUrl + config.apiFormat, {pcId : pcId} );
  if ( response.length === 1 ) {
    return mapToAddress( response[0] );
  } else {
    return null;
  }
}

export async function getAddress(id) {
  const response = await Http.get( apiAddressesUrl + '/' + id + config.apiFormat );
  return mapToAddress( response );
}

export async function insertAddress(data) {
  const response = await Http.post( apiAddressesUrl + config.apiFormat, mapFromAddress(data) );
  return mapToAddress( response );
}

export async function updateAddress(id, data) {
  const response = await Http.put( apiAddressesUrl + '/' + id + config.apiFormat, mapFromAddress(data) );
  return mapToAddress( response );
}

export async function deleteAddress(id) {
  return await Http.del( apiAddressesUrl + '/' + id + config.apiFormat );
}

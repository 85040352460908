import config from 'config.json';
import * as Http from './httpService';
import * as Helpers from 'utils/helpers';
import * as Dt from 'utils/dt';

const apiContractsUrl = config.apiUrl + '/contracts';
const apiContractToStatesUrl = config.apiUrl + '/contract_to_states';
const apiContractStateStatesUrl = config.apiUrl + '/contract_states';

// Contracts

export function mapToContracts(items) {
  let mappedItems = {};
  for ( let i = 0; i < items.length; i++ ) {
    const item = mapToContract( items[i] );
    mappedItems[ item._id ] = item;
  }
  return mappedItems;
}

export function mapToContract(data) {
  Helpers.renameObjKey(data, "pcId", "_id");
  data._id = Helpers.parsePc(data._id);
  data.fcUser = Helpers.parseFc(data.fcUser);
  data.fcAddrSrc = Helpers.parseFc(data.fcAddrSrc);
  data.fcAddrDst = Helpers.parseFc(data.fcAddrDst);
  data.fcTransport = Helpers.parseFc(data.fcTransport);

  return data;
}

export function mapFromContract(data) {
  Helpers.renameObjKey(data, "_id", "pcId");
  data.fcAddrSrc = "/addresses/" + data.fcAddrSrc;
  data.fcAddrDst = "/addresses/" + data.fcAddrDst;
  if ( (data.fcTransport === undefined) || (data.fcTransport == 0) ) {
    data.fcTransport = null;
  } else if (data.fcTransport !== null) {
    data.fcTransport = "/transports/" + data.fcTransport;
  }
  if ( (data.fcUser === undefined) || (data.fcUser == 0) ) {
    data.fcUser = null;
  } else if (data.fcUser !== null) {
    data.fcUser = "/users/" + data.fcUser;
  }

  return data;
}



export async function placeOrder(data) {
  const response = await Http.post( config.apiUrl + '/api/orders' /*+ config.apiFormat*/, JSON.stringify(data) );
  return response;
}



export async function getContracts() {
  const response = await Http.get( apiContractsUrl + config.apiFormat );
  return mapToContracts( response );
}

export async function getContract(id) {
  const response = await Http.get( apiContractsUrl + '/' + id + config.apiFormat );
  return mapToContract( response );
}

export async function getContractByNumber(number) {
  const response = await Http.get( apiContractsUrl + config.apiFormat + '/?number=' + number );
  if (response.length === 0) {
    return [];
  } else {
    return mapToContracts( response );
  }
}

export async function insertContract(data) {
  const response = await Http.post( apiContractsUrl + config.apiFormat, mapFromContract(data) );
  return mapToContract( response );
}

export async function updateContract(id, data) {
  const response = await Http.put( apiContractsUrl + '/' + id + config.apiFormat, mapFromContract(data) );
  return mapToContract( response );
}

export async function deleteContract(id) {
  return await Http.del( apiContractsUrl + '/' + id + config.apiFormat );
}


// ContractToStates

export function mapToContractToStates(items) {
  let mappedItems = {};
  for ( let i = 0; i < items.length; i++ ) {
    const item = mapToContractToState( items[i] );
    mappedItems[ item._id ] = item;
  }
  return mappedItems;
}

export function mapToContractToState(data) {
  Helpers.renameObjKey(data, "pcId", "_id");
  data._id = Helpers.parsePc(data._id);
  data.fcContract = Helpers.parseFc(data.fcContract);
  data.fcState = Helpers.parseFc(data.fcState);
  data.fcUser = Helpers.parseFc(data.fcUser);
  data.fcRoute = Helpers.parseFc(data.fcRoute);
  data.dtCreated = Dt.fromMysql(data.dtCreated);

  return data;
}

export function mapFromContractToState(data) {
  Helpers.renameObjKey(data, "_id", "pcId");
  Helpers.removeObjKey(data, "dtCreated");

  return data;
}


export async function getContractToStates() {
  const response = await Http.get( apiContractToStatesUrl + config.apiFormat );
  return mapToContractToStates( response );
}

export async function getContractToState(id) {
  const response = await Http.get( apiContractToStatesUrl + '/' + id + config.apiFormat );
  return mapToContractToState( response );
}

export async function getContractToStateByContractnumber( contractNumber ) {
  const response = await Http.get( apiContractToStatesUrl + config.apiFormat, {'fcContract.number' : contractNumber } );
  if (response.length === 0) {
    return [];
  } else {
    return mapToContractToStates( response );
  }
}

export async function insertContractToState(data) {
  const response = await Http.post( apiContractToStatesUrl + config.apiFormat, mapFromContractToState(data) );
  return mapToContractToState( response );
}

export async function updateContractToState(id, data) {
  const response = await Http.put( apiContractToStatesUrl + '/' + id + config.apiFormat, mapFromContractToState(data) );
  return mapToContractToState( response );
}

export async function deleteContractToState(id) {
  return await Http.del( apiContractToStatesUrl + '/' + id + config.apiFormat );
}


// ContractStates

export function mapToContractStates(items) {
  let mappedItems = {};
  for ( let i = 0; i < items.length; i++ ) {
    const item = mapToContractState( items[i] );
    mappedItems[ item._id ] = item;
  }
  return mappedItems;
}

export function mapToContractState(data) {
  Helpers.renameObjKey(data, "pcId", "_id");
  data._id = Helpers.parsePc(data._id);

  return data;
}

export function mapFromContractState(data) {
  Helpers.renameObjKey(data, "_id", "pcId");

  return data;
}


export async function getContractStates() {
  const response = await Http.get( apiContractStateStatesUrl + config.apiFormat );
  return mapToContractStates( response );
}

export async function getContractState(id) {
  const response = await Http.get( apiContractStateStatesUrl + '/' + id + config.apiFormat );
  return mapToContractState( response );
}

export async function insertContractState(data) {
  const response = await Http.post( apiContractStateStatesUrl + config.apiFormat, mapFromContractState(data) );
  return mapToContractState( response );
}

export async function updateContractState(id, data) {
  const response = await Http.put( apiContractStateStatesUrl + '/' + id + config.apiFormat, mapFromContractState(data) );
  return mapToContractState( response );
}

export async function deleteContractState(id) {
  return await Http.del( apiContractStateStatesUrl + '/' + id + config.apiFormat );
}


export function testContracts() {
  return [
      { _id: 1,
        date: 'Převzetí: 1.1.2020\nDoručení: 5.1.2020',
        state: 'Kurýr: Jméno Příjmení\nPoslední stav: vyzvednuté\nAktualizováno: 1.1.2020 11:30',
        address_src: 'Jméno Příjmení\nUlice 123\nMěsto 12345\nSlovenská republika',
        address_dst: 'Jméno Příjmení\nUlice 123\nMěsto 12345\nAnglie',
      },
      { _id: 2,
        date: 'Převzetí: 1.1.2020\nDoručení: 5.1.2020',
        state: 'Kurýr: Jméno Příjmení\nPoslední stav: vyzvednuté\nAktualizováno: 1.1.2020 11:30',
        address_src: 'Jméno Příjmení\nUlice 123\nMěsto 12345\nSlovenská republika',
        address_dst: 'Jméno Příjmení\nUlice 123\nMěsto 12345\nAnglie',
      },
      { _id: 3,
        date: 'Převzetí: 1.1.2020\nDoručení: 5.1.2020',
        state: 'Kurýr: Jméno Příjmení\nPoslední stav: vyzvednuté\nAktualizováno: 1.1.2020 11:30',
        address_src: 'Jméno Příjmení\nUlice 123\nMěsto 12345\nSlovenská republika',
        address_dst: 'Jméno Příjmení\nUlice 123\nMěsto 12345\nAnglie',
      },
    ];
}

import React from 'react';

import OrderBoxTransports from './orderBoxTransports';
import OrderBoxState from './orderBoxState';
import Blog from './blog';
import References from 'components/parts/references';

// Images
import img_refHead from 'images/image_refHead.png';


const Side = (props) => {
  return (
    <>
        <OrderBoxTransports
          vertical={true}
        />

        <OrderBoxState
          vertical={true}
        />

        <Blog
          textlinksonly={true}
          size={5}
        />

        <References
          vertical={true}
        />
    </>
  )
}

export default Side;

import React from 'react';

import LangSelector from 'components/parts/langSelector';

// Images
import img_icon_phone from 'images/icon_phone.png';
// Analytics
import { gtmEvent } from 'analytics';


const HeaderContact = ({ props }) => {

  const sendAnalytics = (phoneNumber) => {
    console.log( phoneNumber );
    gtmEvent({
      event: 'clicked',
      eventProps: {
          category: 'contact',
          action: 'clicked',
          label: 'mobile',
          value: phoneNumber
      }
    });
  }


  return(
    <div className="contact-top border-bottom">
        <div className="container">
            <div className="phone-contact">
                <span><img src={img_icon_phone} alt="" /></span>
                <span id="num_sk" onClick={ () => sendAnalytics("sk") } >+421 949 105 003</span>
                <span id="num_bg" onClick={ () => sendAnalytics("gb") } >+44 789 400 76 79</span>
            </div>
            <LangSelector props={props} />
        </div>
    </div>
  );
}

export default HeaderContact;

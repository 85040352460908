import React, {createContext, useReducer} from "react";



const initialGlobalState = {
  //loading_PlannedTransports: false, // MUST BE FALSE TO ALLOW INIT
  blogs: null,
  plannedTransports: null,
  error: null
};



const reducer = (state, action) => {
  switch (action.type) {
    /*case 'LOADING_PLANNEDTRANSPORTS':
      return {
        ...state,
        loading_PlannedTransports: action.payload
      };*/
    case 'SET_BLOGS':
      return {
        ...state,
        blogs: action.payload
      };

    case 'SET_PLANNEDTRANSPORTS':
      return {
        ...state,
        plannedTransports: action.payload
      };
    case 'ADD_PLANNEDTRANSPORTS':
      return {
        ...state,
        plannedTransports: state.plannedTransports.concat(action.payload)
      };
    case 'REMOVE_PLANNEDTRANSPORTS':
        return {
          ...state,
          plannedTransports: state.plannedTransports.filter(plannedTransport => plannedTransport._id !== action.payload)
        };
    case 'SET_ERROR':
        return {
          ...state,
          error: action.payload
        };
    default:
      return state;
  }
};

//export default Reducer;



const Global = ({ children }) => {

  const [state, dispatch] = useReducer(reducer, initialGlobalState);

  return (
    <Context.Provider value={[state, dispatch]}>
      {children}
    </Context.Provider>
  )
};

export const Context = createContext(initialGlobalState);
export default Global;

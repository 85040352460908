import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';

import Header2 from '../parts/header2';
import Side from '../parts/side';

// Assets


const MovingPage = (props) => {
  const { i18n, t } = useTranslation();

  return(
    <>
      <section id="home">
          <Header2 props={props} />
      </section>

      <section id="content" className="border-top">
          <div className="container">
              <h2 className="headline-left">{t('moving')}</h2>
              <div className="row">

                  <div className="col-xl-8 main">
                      <section id="preprava_balikov">
                          <p>{t('page.moving.introduction')}</p>
                      </section>

                      <section id="we-transport">
                        <h3 style={{paddingTop: 25 + 'px'}} className="section-title">{t('weoffer')}?</h3>
                        <ol className="content-list">
                            <li>{t('page.moving.weoffer1')}</li>
                            <li>{t('page.moving.weoffer2')}</li>
                            <li>{t('page.moving.weoffer3')}</li>
                            <li>{t('page.moving.weoffer4')}</li>
                        </ol>

                        <div style={{ fontSize: '18px' }}>
                            <p style={{ fontSize: '18px' }}>{t('page.moving.what')}</p>
                            <ul>
                                <Trans i18nKey="page.moving.whatText"><li></li><li></li><li></li><li></li><li></li><li></li><li></li></Trans>
                            </ul>
                            <p style={{ fontSize: '18px' }}>{t('page.moving.documents')}</p>
                        </div>

                        <div style={{ fontSize: '18px' }}>
                            <p style={{ fontSize: '18px' }}>{t('page.moving.vehicle')}</p>
                            <ul>
                                <Trans i18nKey="page.moving.vehicleText"><li></li><li></li><li></li><li></li></Trans>
                            </ul>
                            <p style={{ fontSize: '18px' }}>{t('page.moving.customs')}</p>
                        </div>

                        <p>{t('page.moving.doorToDoor')}</p>

                        <Link to={i18n.language === "sk" ? "/kontakt" : "/contact"} data-action="form"><button>{t('contactus')}</button></Link>
                      </section>
                  </div>

                  <div className="col-xl-4 side">
                      <Side />
                  </div>

              </div>
          </div>
      </section>
    </>
  );
}

export default MovingPage;

import React from 'react';
import { withTranslation } from 'react-i18next';

// Images
import img_icon_quote from 'images/icon_quote.png';
import img_ref1 from 'images/ref1.png';
import img_ref2 from 'images/ref2.png';
import img_ref3 from 'images/ref3.png';


const ReferenceHorizontal = ({ icon, name, text }) => {
  return (
    <div className="col-lg-4 ref-item">
       <img id="icon_quote" src={img_icon_quote} alt="" />
       <p>{text}</p>
       <span className="ref-name">
            <img id="ref-head" src={icon} alt="" />
            <strong>{name}</strong>
        </span>
    </div>
  );
}

const ReferenceVertical = ({ icon, name, text }) => {
  return (
    <div className="reference-item">
        <p>{text}</p>
        <img src={icon} alt="" />
        <strong>{name}</strong>
    </div>
  );
}


class References extends React.Component {
  state = {
    references : {
      1 : { _key : 1, icon : img_ref1, name : "Stanka Harčaríková", text : 'ref.1' },
      2 : { _key : 2, icon : img_ref2, name : "Veronika Simonova Lisovská", text : 'ref.2' },
      3 : { _key : 3, icon : img_ref3, name : "Iveta Duffek", text : 'ref.3' },
    },
  };

  render () {
    const { vertical } = this.props;
    const { t } = this.props;
    const { references } = this.state;

    return(
      vertical ?

      <section id="reference-side">
        <h2>{t('references')}</h2>
        { Object.values(references).map(item =>
          <ReferenceVertical
            key={item._key}
            icon={item.icon}
            name={item.name}
            text={t(item.text)}
          />
        )}
      </section>

      :

      <section id="reference">
        <div className="container">
          <h3 className="headline-center">{t('references')}</h3>
          <div className="row">
            { Object.values(references).map(item =>
              <ReferenceHorizontal
                key={item._key}
                icon={item.icon}
                name={item.name}
                text={t(item.text)}
              />
            )}
          </div>
        </div>
      </section>
    );
  }
}

export default withTranslation()(References);

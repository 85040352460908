import React from 'react';
import { Link } from 'react-router-dom';

import HeaderContact from './headerContact';
import Menu from './menu';
import HeaderTransportBox from './headerTransportBox';
import OrderStateFormMobile from '../forms/orderStateFormMobile';
import Bar from 'components/bar';

// Images
import img_banner from 'images/custom/banner.png';

const Header1 = ({ props }) => {
  return (
    <header>
      <Bar />
      <HeaderContact props={props} />
      <Menu props={props} />
      <HeaderTransportBox />
      {/*<OrderStateFormMobile />*/}
    </header>
  )
}

export default Header1;

import React from 'react';


export function isset(element) {
  if ( (! element) || typeof element === 'undefined' || element === null) {
    return false;
  } else {
    return true;
  }
}

export function empty(element) {
  if ( (! isset(element)) || element === '' ) {
    return true;
  } else {
    return false;
  }
}

export function echo(text) {
  if (isset(text)) {
    return text;
  } else {
    return "";
  }
}

export function isNumber(value) {
  return typeof value === 'number';
}


export function formatEur(price) {
  if (! isset(price)) {
    return undefined;
  }
  return price.toFixed(2).replace('.', ',').replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1 ");
}


export class ExtendableError extends Error {
  constructor(code, message) {
    super();
    this.stack = (new Error()).stack;
    this.name = this.constructor.name;
    this.message = message;
    this.code = code;
  }
}

export class HttpError extends ExtendableError {
  constructor(code, message, response) {
    super(code, message);
    this.response = response;
  }
}



export function renameObjKey(obj, oldName, newName) {
  //if (! (oldName in obj)) {
  if (! obj.hasOwnProperty(oldName)) {
    return;
  }

  if (oldName !== newName) {
    Object.defineProperty(obj, newName, Object.getOwnPropertyDescriptor(obj, oldName));
    removeObjKey(obj, oldName);   // delete obj[oldName];
  }
}

export function removeObjKey(obj, name) {
  if (! obj.hasOwnProperty(name)) {
    return;
  }

  obj[name] = undefined;
  try {
    delete obj[name];
  } catch (ex) {
  }
}


// https://medium.com/@kevinsimper/react-newline-to-break-nl2br-a1c240ba746
// https://www.freecodecamp.org/forum/t/newline-in-react-string-solved/68484/9
export function newlineTextToBr(text) {
  if ( ! isset(text) ) {
    return null;
  }

  return text.split('\n').map( (txt, i) => (
      <React.Fragment key={i}>{txt}<br />
      </React.Fragment>
    ) );
}


export function parsePc(pc) {
  if (! isset(pc)) {
    return pc;
  }
  return parseInt(pc);
}

export function parseFc(fcPath) {
  if( typeof fcPath==='number' && (fcPath%1)===0 ) {  // TODO: Make better INT check
      return fcPath;
  }

  if (! isset(fcPath)) {
    return fcPath;
  }
  return parseInt( fcPath.substring(fcPath.lastIndexOf("/") + 1) );
}

export function parseBool(value) {
  return (value == 1) ? true : false;
}

export function parseBasic(value) {
  return (isset(value) ? value : "");
}

export function slugify(str) {
  str = str.replace(/^\s+|\s+$/g, ''); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  var from = "čďěňřšťůýžãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;"
  var to   = "cdenrstuyzaaaaaeeeeeiiiiooooouuuunc------";
  for (var i=0, l=from.length ; i<l ; i++) {
      str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
      .replace(/\s+/g, '-') // collapse whitespace and replace by -
      .replace(/-+/g, '-'); // collapse dashes

  return str;
}

export function getRandom() {
  const min = 1;
  const max = 999999;
  return parseInt(min + Math.random() * (max - min));
}

import React from 'react'

import Header2 from '../parts/header2';

import OrderForm from '../forms/orderForm';

// Assets
import 'css/preprava_balikov.css';


class OrderPage extends React.Component {
  render () {
    return(
      <React.Fragment>
        <section id="home">
          <Header2 props={this.props} />
        </section>

        <OrderForm />

      </React.Fragment>
    );
  }
}

export default OrderPage;

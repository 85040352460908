import React from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import Header2 from '../parts/header2';


class OrderCompletePage extends React.Component {
  render () {
    const { t } = this.props;

    return(
      <>
        <section id="home">
            <Header2 props={this.props} />
        </section>

        <div className="complete-message">
            <div className="container">
                <h2>{t('order.complete.title')}</h2>
                <Link to="/">{t('gohome')}</Link>
            </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(OrderCompletePage);

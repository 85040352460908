import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

const languageDetector = new LanguageDetector();
languageDetector.addDetector({
  name: 'fromUrlDetector',

  lookup(options) {
    const pathname = window.location.pathname;

    if (pathname === "/") {
      // LEAVE AS IT IS
      return;
    }

    else if (pathname.startsWith("/404")) {
      // LEAVE AS IT IS
      return;
    }

    else if (pathname.startsWith("/login")) {
      // LEAVE AS IT IS
      return;
    }

    else if (pathname.startsWith("/blog")) {
      // LEAVE AS IT IS
      return;
    }

    else if (pathname.startsWith("/delivery")) {
      return 'en';
    } else if (pathname.startsWith("/preprava-balikov-slovensko-anglicko")) {
      return 'sk';
    }

    else if (pathname.startsWith("/moving")) {
      return 'en';
    } else if (pathname.startsWith("/stahovanie-nabytku-v-anglicku")) {
      return 'sk';
    }

    else if (pathname.startsWith("/food")) {
      return 'en';
    } else if (pathname.startsWith("/nakupy-v-anglicku")) {
      return 'sk';
    }

    else if (pathname.startsWith("/contact")) {
      return 'en';
    } else if (pathname.startsWith("/kontakt")) {
      return 'sk';
    }

    else if (pathname.startsWith("/order")) {
      return 'en';
    } else if (pathname.startsWith("/objednavka-prepravy-balika")) {
      return 'sk';
    }
    
    else if (pathname.startsWith("/order-complete")) {
      return 'en';
    } else if (pathname.startsWith("/objednano")) {
      return 'sk';
    }

    else if (pathname.startsWith("/how-tofill-theorder")) {
      return 'en';
    } else if (pathname.startsWith("/ako-vyplnit-objednavku")) {
      return 'sk';
    }

    else if (pathname.startsWith("/pricelist")) {
      return 'en';
    } else if (pathname.startsWith("/cennik")) {
      return 'sk';
    }

    else if (pathname.startsWith("/maps")) {
      return 'en';
    } else if (pathname.startsWith("/mapy")) {
      return 'sk';
    }

    else if (pathname.startsWith("/vat")) {
      return 'en';
    } else if (pathname.startsWith("/clo")) {
      return 'sk';
    }
    
    else {
      return 'sk';
    }

    return;
  },
});


i18n
  .use(Backend)
  .use(initReactI18next)
  .use(languageDetector)
  .init({
    //lng: 'sk',
    backend: {
      /* translation file path */
      loadPath: '/internationals/{{ns}}/{{lng}}.json'
    },
    fallbackLng: 'sk',
    debug: true,
    /* can have multiple namespace, in case you want to divide a huge translation into smaller pieces and load them on demand */
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ','
    },
    react: {
      wait: true
    },

    //LanguageDetector >>>
    detection: {
      order: ['fromUrlDetector'],
    },
  })

export default i18n;

import config from 'config.json';
import * as Http from './httpService';
import * as Helpers from 'utils/helpers';
import * as Dt from 'utils/dt';

const apiBlogsUrl = config.apiUrl + '/blogs';

// Blogs

export function mapToBlogs(items) {
  let mappedItems = {};
  for ( let i = 0; i < items.length; i++ ) {
    const item = mapToBlog( items[i] );
    mappedItems[ item._id ] = item;
  }
  return mappedItems;
}

export function mapToBlog(data) {
  Helpers.renameObjKey(data, "pcId", "_id");
  data._id = Helpers.parsePc(data._id);
  data.fcUserAuthor = Helpers.parseFc(data.fcUserAuthor);
  data.dtCreated = Dt.fromMysql(data.dtCreated);
  data.dtPublished = Dt.fromMysql(data.dtPublished);

  return data;
}

export function mapFromBlog(data) {
  Helpers.renameObjKey(data, "_id", "pcId");
  Helpers.removeObjKey(data, "fcUserAuthor");
  Helpers.removeObjKey(data, "dtCreated");
  Helpers.removeObjKey(data, "dtPublished");

  return data;
}


export async function getBlogs(page) {
  if (page === undefined) {
    page = "";
  } else {
    page = "?page=" + page
  }
  const response = await Http.get( apiBlogsUrl + config.apiFormat + page );
  return mapToBlogs( response );
}
export async function getBlogsOrdId(ordId, page) {
  if (page === undefined) {
    page = "";
  } else {
    page = "&page=" + page
  }
  const response = await Http.get( apiBlogsUrl + config.apiFormat + "?order[pcId]=" + ordId + page );
  return mapToBlogs( response );
}

export async function getBlog(id) {
  const response = await Http.get( apiBlogsUrl + '/' + id + config.apiFormat );
  return mapToBlog( response );
}
export async function getBlogsBySlug(slug) {
  const response = await Http.get( apiBlogsUrl + config.apiFormat + '?slug=' + slug );
  if (response.length === 1) {
    return mapToBlog( response[0] );
  }
  return null;
}

export async function insertBlog(data) {
  const response = await Http.post( apiBlogsUrl + config.apiFormat, mapFromBlog(data) );
  return mapToBlog( response );
}

export async function updateBlog(id, data) {
  const response = await Http.put( apiBlogsUrl + '/' + id + config.apiFormat, mapFromBlog(data) );
  return mapToBlog( response );
}

export async function deleteBlog(id) {
  return await Http.del( apiBlogsUrl + '/' + id + config.apiFormat );
}

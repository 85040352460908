import config from 'config.json';
import * as Http from './httpService';
import * as Helpers from 'utils/helpers';
import * as Dt from 'utils/dt';

const apiMessagesUrl = config.apiUrl + '/messages';

// Messages

export function mapToMessages(items) {
  let mappedItems = {};
  for ( let i = 0; i < items.length; i++ ) {
    const item = mapToMessage( items[i] );
    mappedItems[ item._id ] = item;
  }
  return mappedItems;
}

export function mapToMessage(data) {
  Helpers.renameObjKey(data, "pcId", "_id");
  data._id = Helpers.parsePc(data._id);
  data.dtSent = Dt.fromMysql(data.dtSent);
  data.fcUser = Helpers.parseFc(data.fcUser);

  return data;
}

export function mapFromMessage(data) {
  Helpers.renameObjKey(data, "_id", "pcId");
  Helpers.removeObjKey(data, "fcUser");
  Helpers.removeObjKey(data, "dtSent");

  return data;
}


export async function patchMessageViewed(id, viewed) {
  const toPatch = {
      "_id" : id,
      "viewed" : viewed
    };

  const response = await Http.patch( apiMessagesUrl + '/' + id + config.apiFormat, mapFromMessage(toPatch) );
  return mapToMessage( response );
}

export async function getMessages() {
  const response = await Http.get( apiMessagesUrl + config.apiFormat );
  return mapToMessages( response );
}

export async function getMessage(id) {
  const response = await Http.get( apiMessagesUrl + '/' + id + config.apiFormat );
  return mapToMessage( response );
}

export async function insertMessage(data) {
  const response = await Http.post( apiMessagesUrl + config.apiFormat, mapFromMessage(data) );
  return mapToMessage( response );
}

export async function updateMessage(id, data) {
  const response = await Http.put( apiMessagesUrl + '/' + id + config.apiFormat, mapFromMessage(data) );
  return mapToMessage( response );
}

export async function deleteMessage(id) {
  return await Http.del( apiMessagesUrl + '/' + id + config.apiFormat );
}


export function testMessages() {
  return [
      { _id: 1,
        contact: 'Jméno Příjmení\nTel.: 123 456 789\nEmail: email@email.sk',
        message: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Integer imperdiet lectus quis justo. Integer vulputate sem a nibh rutrum consequat. Fusce dui leo, imperdiet in, aliquam sit amet, feugiat eu, orci. Nulla turpis magna, cursus sit amet, suscipit a, interdum id, felis. Duis viverra diam non justo. Nullam sapien sem, ornare ac, nonummy non, lobortis a enim. Nullam lectus justo, vulputate eget mollis sed, tempor sed magna. Donec ipsum massa, ullamcorper in, auctor et, scelerisque sed, est. Curabitur bibendum justo non orci. Nulla non arcu lacinia neque faucibus fringilla. Et harum quidem rerum facilis est et expedita distinctio. Suspendisse nisl.',
      },
      { _id: 2,
        contact: 'Jméno Příjmení\nTel.: 123 456 789\nEmail: email@email.sk',
        message: 'Phasellus faucibus molestie nisl. Etiam dictum tincidunt diam. Aenean fermentum risus id tortor. Curabitur sagittis hendrerit ante. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos hymenaeos. Vestibulum fermentum tortor id mi. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. In enim a arcu imperdiet malesuada. Phasellus rhoncus. Etiam posuere lacus quis dolor. Praesent id justo in neque elementum ultrices. Nunc auctor.',
      },
      { _id: 3,
        contact: 'Jméno Příjmení\nTel.: 123 456 789\nEmail: email@email.sk',
        message: 'Vestibulum fermentum tortor id mi. In enim a arcu imperdiet malesuada. Morbi scelerisque luctus velit. Etiam quis quam. Nunc dapibus tortor vel mi dapibus sollicitudin. Fusce suscipit libero eget elit. Nunc dapibus tortor vel mi dapibus sollicitudin. Mauris dictum facilisis augue. Sed vel lectus. Donec odio tempus molestie, porttitor ut, iaculis quis, sem. Integer in sapien. Vestibulum fermentum tortor id mi.',
      },
      { _id: 4,
        contact: 'Jméno Příjmení\nTel.: 123 456 789\nEmail: email@email.sk',
        message: 'Praesent id justo in neque elementum ultrices. Itaque earum rerum hic tenetur a sapiente delectus, ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat. Nulla turpis magna, cursus sit amet, suscipit a, interdum id, felis. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Quisque tincidunt scelerisque libero. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Vivamus porttitor turpis ac leo. Nullam sapien sem, ornare ac, nonummy non, lobortis a enim. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Maecenas aliquet accumsan leo. Integer lacinia.',
      },
    ];
}

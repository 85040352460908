import axios from 'axios';
import { toast } from 'react-toastify';
import * as Helpers from '../utils/helpers';


/*
function getToken() {
  const tokenString = localStorage.getItem('token');
  const userToken = JSON.parse(tokenString);
  return userToken?.token;
}

// Add a request interceptor + TOKEN HEADER
axios.interceptors.request.use(function (config) {
    const token = getToken();
    if (token === undefined) {
      console.log(window.location.pathname);
      if (window.location.pathname !== "/") {
        //toast.info("Vaše přihlášení vypršelo -> klikněte na ODHLÁSIT a znova se přihlašte.");
        localStorage.clear();
        window.location.href = "/";
      }
      return config;
    }

    config.headers.Authorization = "Bearer " + getToken();
    return config;
});
//OR: axios.defaults.headers.common['Authorization'] = `Bearer ${getToken()}`;
*/
axios.interceptors.response.use(null, error => {
  const expectedError = error.response && error.response.status >= 400 && error.response.status < 500;

  if (! expectedError) {
    console.log("FZATLOUKAL.CZ Log: ", error);
    //alert("Nastala neočekávaná chyba při komunikaci se serverem.");
    toast.error("Nastala neočekávaná chyba při komunikaci se serverem.");
  }

  return Promise.reject(error);
});


// https://pastebin.com/zEURbC6K

export async function get(url, parameters) {
  if (parameters === undefined) {
    parameters = {};
  }

  const result = await axios.get(url, { params : parameters })
    .then( (response) => {
      if (response.status === 200) {
        return response.data;
      }
      console.log( `Error "${url}": ${response.status}` );
      throw new Helpers.HttpError( response.status, "" );
      //return [ response.status, "" ];
    })
    .catch( (err) => {
      console.log( `Error "${url}": ${err.toString()}` );
      throw new Helpers.HttpError( 0, err.toString() );
      //return [ 0, err ];
    });

  return result;
}

export async function post(url, data) {
  return await axios.post(url, data)
    .then( (response) => {
      if (response.status === 201) {
        return response.data;
      }
      console.log( `Error "${url}": ${response.status}` );
      throw new Helpers.HttpError( response.status, "" );
      //return [ response.status, "" ];
    })
    .catch( (err) => {
      console.log( `Error "${url}": ${err.toString()}` );
      throw new Helpers.HttpError( err.code, err.toString(), err.response );
      //return [ 0, err ];
    });
}

export async function put(url, data) {
  return await axios.put(url, data)
    .then( (response) => {
      if (response.status === 200) {
        return response.data;
      }
      console.log( `Error "${url}": ${response.status}` );
      throw new Helpers.HttpError( response.status, "" );
      //return [ response.status, "" ];
    })
    .catch( (err) => {
      console.log( `Error "${url}": ${err.toString()}` );
      throw new Helpers.HttpError( 0, err.toString() );
      //return [ 0, err ];
    });
}

export async function patch(url, data) {
  return await axios.patch(url, data)
    .then( (response) => {
      if (response.status === 200) {
        return response.data;
      }
      console.log( `Error "${url}": ${response.status}` );
      throw new Helpers.HttpError( response.status, "" );
      //return [ response.status, "" ];
    })
    .catch( (err) => {
      console.log( `Error "${url}": ${err.toString()}` );
      throw new Helpers.HttpError( 0, err.toString() );
      //return [ 0, err ];
    });
}

export async function del(url) {
  return await axios.delete(url)
    .then( (response) => {
      if (response.status === 204) {
        //return true;
        return;
      }
      console.log( `Error "${url}": ${response.status}` );
      throw new Helpers.HttpError( response.status, "" );
      //return [ response.status, "" ];
    })
    .catch( (err) => {
      console.log( `Error "${url}": ${err.toString()}` );
      throw new Helpers.HttpError( 0, err.toString() );
      //return [ 0, err ];
    });
}

/*
export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  patch: axios.patch,
  delete: axios.delete,
}
*/

import * as React from "react";

const GlobalContext = React.createContext();


const init = {
  bar: {
    allowed: null,
    text: null,
    link: null,
    color_bg: null,
    color_text: null
  },
};


/*
* Each dispatcher must return object {} with new data to enter into new state
*/
const dispatcher = (action, oldData, newData) => {

  switch (action) {

    case 'BAR_SET':
      console.log(oldData, newData);
      oldData.bar = newData;
      break;

    default:
      throw new Error(`GLOBAL context: Unsupported action type: ${action.type}`);
  }
};



function globalReducer(state, action) {

  let newState = { ...state };

  if ( action.type == 'MULTI' ) {

    action.payload.forEach( dispatch => {
      dispatcher( dispatch.type, newState, dispatch.payload );
    })

  } else {

    dispatcher( action.type, newState, action.payload );

  }

  return newState;
}


// Own hook for reading/writing values/states
function useGlobal() {
  const context = React.useContext(GlobalContext);
  if (! context) {
    throw new Error("GLOBAL context: useGlobal() must be used within a GlobalProvider");
  }
  const [state, dispatch] = context;

  // OWN OPTIONAL DISPATCH methods here:
  const barSet = (bar) => dispatch({ type: 'BAR_SET', payload: bar });

  return {
    global : state,
    barSet
  }
}


// Providing context in app tree
function GlobalProvider(props) {
  const [state, dispatch] = React.useReducer(globalReducer, init);
  const value = React.useMemo(() => [state, dispatch], [state]);
  return <GlobalContext.Provider value={value} {...props} />
}


export {GlobalProvider, useGlobal};

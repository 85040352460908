import React from 'react'
import { Link } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';

import Header2 from '../../parts/header2';
import Side from '../../parts/side';

// Images


const InfoUserPrivacyPage = (props) => {
  const { i18n, t } = useTranslation();

  return(
    <>
      <section id="home">
          <Header2 props={props} />
      </section>

      <section id="content" className="border-top">
          <div className="container">
              <h2 className="headline-left">Ochrana osobných údajov</h2>
              <div className="row">

                  <div className="col-xl-8 main">
                    <section id="we-transport">
                      <strong>Podmienky ochrany osobných údajov v zmysle Nariadenia Európskeho parlamentu a Rady (EÚ) </strong><strong>2016/679 z 27. apríla 2016 o ochrane fyzických osôb pri spracúvaní osobných údajov a o voľnom </strong><strong>pohybe takýchto údajov (ďalej ako „GDPR“)</strong>

                      <h3>Informácie o účele</h3>
                      <h4>Informácie o účele spracúvania, na ktoré sú osobné údaje určené</h4>
                      <p>Jednou zo zásad spracúvania osobných údajov je zásada obmedzenia účelu. V zmysle tejto zásady sa osobné údaje môžu získavať len na konkrétne určený, výslovne uvedený a oprávnený účel a nesmú sa ďalej spracúvať spôsobom, ktorý nie je zlučiteľný s týmto účelom. Spracúvanie osobných údajov má byť úzko naviazané na účel spracúvania osobných údajov, a to najmä pokiaľ ide o zoznam alebo rozsah spracúvaných osobných údajov, ktorý by mal byť nevyhnutný na to, aby sa spracúvaním daných osobných údajov účel mohol dosiahnuť. Nie je správne, aby sa zoznam alebo rozsah osobných údajov umelo alebo dodatočne rozširoval vzhľadom na účel.</p>

                      <p>Ak je účel a zoznam alebo rozsah osobných údajov stanovený zákonom, je potrebné ho rešpektovať, ak si zoznam alebo rozsah spracúvaných osobných údajov určuje prevádzkovateľ, má dbať na to, aby ho zbytočne, nad rámec účelu nerozširoval.</p>

                      <p>Zákon o ochrane osobných údajov stanovuje povinnosť prevádzkovateľa poskytnúť dotknutej osobe informácie o účele spracovania osobných údajov, na ktorý sú jej osobné údaje určené a to aj v prípade, keď sa osobné údaje nezískavajú priamo od dotknutej osoby. Je potrebné, aby tieto informácie boli dotknutej osobe poskytnuté najneskôr pri získavaní jej osobných údajov, respektíve v dostatočnom časovom predstihu, jasne a zrozumiteľne a takým spôsobom, aby sa s týmito informáciami mohla skutočne oboznámiť a porozumela im.</p>

                      <h3>Právo dotknutej osoby</h3>
                      <h4>Právo dotknutej osoby podať návrh na začatie konania v zmysle ustanovenia § 100 zákona o ochrane osobných údajov</h4>
                      <p>Dotknutá osoba, ktorá sa domnieva, že dochádza k neoprávnenému spracúvaniu jej osobných údajov alebo došlo k zneužitiu jej osobných údajov, môže na Úrade pre ochranu osobných údajov Slovenskej republiky (ďalej len ,,Úrad“) podať návrh na začatie konania o ochrane osobných údajov.</p>

                      <p>Návrh na začatie konania možno podať písomne, osobne ústnou formou do zápisnice, elektronickými prostriedkami, pričom musí byť podpísaný zaručeným elektronickým podpisom, telegraficky alebo telefaxom, ktorý však treba písomne alebo ústne do zápisnice doplniť najneskôr do 3 dní.</p>

                      <h4>Predmetný návrh musí v zmysle ustanovenia § 63 ods. 2 zákona o ochrane osobných údajov obsahovať:</h4>
                      <ol>
                       	<li>meno, priezvisko, adresu trvalého pobytu a podpis navrhovateľa</li>
                       	<li>označenie toho, proti komu návrh smeruje; názov alebo meno a priezvisko, sídlo alebo trvalý pobyt, prípadne právnu formu a identifikačné číslo</li>
                       	<li>predmet návrhu s označením, ktoré práva sa podľa tvrdenia navrhovateľa pri spracúvaní osobných údajov porušili</li>
                       	<li>dôkazy na podporu tvrdení uvedených v návrhu</li>
                       	<li>kópiu listiny preukazujúcej uplatnenie práva podľa § 28, ak sa takéto právo mohlo uplatniť, alebo uvedenie dôvodov hodných osobitného zreteľa</li>
                      </ol>
                      <p>Úrad následne rozhodne o návrhu navrhovateľa v lehote do 60 dní odo dňa začatia konania. V odôvodnených prípadoch môže Úrad túto lehotu primerane predĺžiť, najviac však o 6 mesiacov. O predĺžení lehoty Úrad písomne informuje účastníkov konania.</p>

                      <strong>Vzor návrhu:</strong>
                      <a href="https://www.dataprotection.gov.sk/uoou/sites/default/files/kcfinder/files/WEB-vzor_navrhu.pdf"><strong>https://www.dataprotection.gov.sk/uoou/sites/default/files/kcfinder/files/WEB-vzor_navrhu.pdf</strong></a>
                      <h3>Právo žiadať informácie</h3>
                      <h4>Informácie o práve žiadať od príslušného orgánu prístup k osobným údajom, ktoré sa dotknutej osoby týkajú, ich opravu, vymazanie alebo obmedzenie ich spracúvania</h4>
                      <p>Dotknutá osoba má právo získať od príslušného orgánu potvrdenie o tom, či sa spracúvajú osobné údaje, ktoré sa jej týkajú, a ak tomu tak je, má právo získať prístup k týmto osobným údajom a informácie o</p>
                      <ol>
                       	<li>účele spracúvania osobných údajov a právnom základe spracúvania osobných údajov</li>
                       	<li>kategórii spracúvaných osobných údajov</li>
                       	<li>príjemcovi alebo kategórii príjemcov, ktorým boli alebo majú byť osobné údaje poskytnuté, najmä o príjemcovi v tretej krajine alebo o medzinárodnej organizácii</li>
                       	<li>dobe uchovávania osobných údajov; ak to nie je možné, informáciu o kritériách jej určenia</li>
                       	<li>práve žiadať od príslušného orgánu opravu osobných údajov týkajúcich sa dotknutej osoby alebo ich vymazanie alebo obmedzenie spracúvania osobných údajov, alebo práve namietať spracúvanie osobných údajov</li>
                       	<li>kontaktných údajoch úradu</li>
                       	<li>práve podať návrh na začatie konania podľa § 100</li>
                       	<li>zdroji osobných údajov, ak sú dostupné.</li>
                      </ol>
                      <p>Fyzická osoba by mala mať právo na prístup k údajom, ktoré boli o nej získané, a toto právo aj jednoducho uplatňovať, aby si bola vedomá zákonnosti spracúvania a mohla si ju overiť. Každá dotknutá osoba by preto mala mať právo vedieť a byť informovaná najmä o účeloch spracúvania údajov, kategóriách dotknutých osobných údajov a o dobe, počas ktorej sa budú údaje spracúvať, ako aj o príjemcoch osobných údajov atď. Na dodržanie tohto práva je dostatočné, aby mala dotknutá osoba k dispozícii úplné zhrnutie uvedených údajov v zrozumiteľnej forme, to znamená vo forme, ktorá umožňuje, aby bola dotknutá osoba informovaná o uvedených údajoch, aby si mohla uplatniť práva, ktoré jej tento zákon priznáva.</p>

                      <p>Príslušný orgán môže úplne alebo čiastočne obmedziť prístup k ich osobným údajom, a to v takom rozsahu a na tak dlho, ako je toto opatrenie s náležitým zreteľom na práva a oprávnené záujmy dotknutej fyzickej osoby v demokratickej spoločnosti nevyhnutné a primerané, aby sa zabránilo mareniu úradného alebo súdneho zisťovania, vyšetrovania alebo konania, aby sa zabránilo ohrozeniu plnenia úloh na účely trestného konania, aby sa tak ochránila verejná bezpečnosť alebo národná bezpečnosť, alebo aby sa ochránili práva a slobody iných. Príslušný orgán by mal prostredníctvom konkrétneho a individuálneho preskúmania každého prípadu posúdiť, či by sa právo na prístup malo čiastočne alebo úplne obmedziť.</p>

                      <strong>Akékoľvek obmedzenie prístupu by malo byť dotknutej osobe v zásade oznámené písomne a malo by zahŕňať skutkové alebo právne dôvody, na ktorých je toto rozhodnutie založené.</strong>

                      <p>Dotknutá osoba by tiež mala mať právo na opravu nesprávnych osobných údajov, ktoré sa jej týkajú, ako aj právo na ich vymazanie, ak je spracúvanie takýchto údajov v rozpore so zákonom. Právom na opravu by však nemal byť napríklad dotknutý obsah svedeckej výpovede.</p>

                      <p>Fyzická osoba by mala mať právo na obmedzenie spracúvania, ak napadne správnosť osobných údajov a nemožno určiť ich správnosť či nesprávnosť, alebo keď je potrebné osobné údaje uchovať na účely dokazovania. Namiesto vymazania osobných údajov by sa spracúvanie malo obmedziť najmä vtedy, keď sa v osobitnom prípade možno odôvodnene domnievať, že vymazanie by mohlo ovplyvniť oprávnené záujmy dotknutej osoby. V takomto prípade by sa
                      obmedzené údaje mali spracúvať len na účely, ktoré zabránili ich vymazaniu. Metódy na obmedzenie spracúvania osobných údajov by okrem iného mohli zahŕňať presunutie vybraných údajov do iného systému spracúvania, napríklad na účely archivácie, alebo zamedzenie prístupu k nim. V automatizovaných informačných systémoch by sa obmedzenie spracúvania malo v zásade zabezpečiť technickými prostriedkami. Skutočnosť, že spracúvanie osobných údajov je obmedzené, by sa v systéme mala vyznačiť tak, aby bolo jednoznačné, že spracúvanie osobných údajov je obmedzené. Takáto oprava alebo vymazanie osobných údajov alebo obmedzenie spracúvania by sa mali oznámiť príjemcom, ktorým sa údaje poskytli, a príslušným orgánom, od ktorých nesprávne údaje pochádzajú. Príslušné orgány by takisto mali upustiť od ďalšieho šírenia takýchto údajov.</p>

                      <h3>Používanie súborov cookies</h3>
                      <p>Súbory „cookies“ sú malé súbory, ktoré nám umožňujú uložiť informácie týkajúce sa Vášho zariadenia a Vás ako používateľa, konkrétne keď navštívite jednu z našich webových stránok. Súbory cookies nám pomáhajú zistiť, ako často sú naše webové stránky navštevované, ako aj počet používateľov.</p>

                      <p>Na základe článku 6 ods. 1 písm. f) GDPR používame „dočasné súbory cookies“ s cieľom optimalizovať našu webovú stránku a zabezpečiť primeraný a nerušený komfort používateľov. Tieto súbory cookies sa ukladajú výlučne počas trvania Vašej návštevy na našich webových stránkach. Keď zatvoríte svoj prehliadač, automaticky sa vymažú.</p>

                      <p>Okrem toho používame „trvalé súbory cookies“ na uchovanie informácií o našich návštevníkoch, ktorí opakovane navštevujú niektorú z našich webových stránok. Účelom využívania súborov cookies je, aby sme Vám mohli ponúkať optimálne používateľské usmernenie, ako aj to, aby ste boli „spoznaný“, na základe čoho je možné počas opakovaného použitia poskytovať (v čo najväčšej možnej miere) rôzne webové stránky a nový obsah.</p>

                      <p>Vo všeobecnosti nevytvárame individuálny profil Vašich online aktivít. Obsah trvalého súboru cookie je obmedzený na identifikačné číslo. Na väčšine našich stránok sa neukladajú meno, emailová adresa, IP adresy atď.</p>

                      <h3>Nástroje zdieľania: Súbory cookies tretích strán</h3>
                      <p>V súčasnosti na svojich webových stránkach používame vkladané tlačidlá „na účely zdieľania“. Tieto návštevníkom uľahčujú pridať si do obľúbených položiek určitý obsah a zdieľať ho prostredníctvom ich obľúbených sociálnych sietí. Ak kliknete na niektoré z týchto tlačidiel, súbor cookie sa môže uložiť prostredníctvom služby, ktorú ste si vybrali, a to s cieľom zdieľať daný obsah.Nekontrolujeme používanie týchto súborov cookies, preto by ste pre viac informácií mali navštíviť dotknutú webovú stránky tretej strany. Tlačidlá „na účely zdieľania“ poskytuje AddThis. Pre viac informácií o ich postupoch pri získavaní údajov pozrite Pravidlá ochrany údajov <a href="http://www.addthis.com/privacy/terms-of-service/"><strong>AddThisExternal Link</strong></a>.</p>

                      <h3>Salesforce</h3>
                      <p>Osobné údaje spracúvame v databázovom systéme Salesforce v rozsahu meno, priezvisko, emailová adresa, telefónne číslo, adresa odosielateľ a príjemcu.</p>

                      <p>Salesforce poskytuje spoločnosť Salesforce.com, inc. The Landmark at One Market, Suite 300, San Francisco, CA 94105, USA.</p>

                      <p>Údaje uložené v systéme Salesforce budú uložené na serveroch prevádzkovaných spoločnosťou Salesforce.com, inc. v súlade s pravidlami GDPR a dohodou EU-US Privacy Shield.</p>

                      <p>Ďalšie informácie o ochrane osobných údajov spoločnosťou Salesforce.com, Inc. sú k dispozícii na: <a href="https://www.salesforce.com/company/privacy"><strong>https://www.salesforce.com/company/privacy</strong></a>.</p>

                      <p>Ďalšie informácie o EU-US Privacy Shield sú dostupné na webstránke Európskej komisie: <a href="https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/eu-us-privacy-shield_en"><strong>https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/eu-us-privacy-shield_en</strong></a>.</p>

                      <h3>Záver</h3>
                      <p>S cieľom zaručiť spravodlivé spracúvanie osobných údajov dotknutej osoby a na to, aby dotknutá osoba mohla uplatňovať svoje práva, by dotknutá osoba mala byť okrem uvedených informácii v osobitných prípadoch informovaná aj o</p>
                      <ol>
                       	<li>právnom základe spracúvania jej osobných údajov</li>
                       	<li>o tom, ako dlho sa budú jej osobné údaje uchovávať</li>
                       	<li>aký je účel spracovania jej osobných údajov</li>
                       	<li>kategórie príjemcov a ďalšie informácie</li>
                      </ol>
          					</section>
                  </div>

                  <div className="col-xl-4 side">
                      <Side />
                  </div>

              </div>
          </div>
      </section>
    </>
  );
}

export default InfoUserPrivacyPage;

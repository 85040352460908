import React from 'react'

import Header1 from '../parts/header1';
import Services from '../services';
import About from '../about';
import Offer from '../offer';
import Numbers from '../numbers';
import OrderBoxTransports from '../parts/orderBoxTransports';
import OrderBoxState from '../parts/orderBoxState';

// Assets
import { withTranslation } from 'react-i18next';
import 'css/index.css';
import $ from 'jquery';


class IndexPage extends React.Component {

  constructor(props) {
    super(props);

    // Refs
    /*this.refers = {};

    // Build ref object with elements ids
    this.setRef = (element) => {
      this.refers[element.id] = element;
    };*/

    this.transportsEmptyText = 'Loading / Načítá se ...';
  }

  state = {
    transports : [],
  }

  componentDidMount() {

    /*
    //switch menu icon
    var currentIcon = $('#home .navbar-toggler img').attr('src');

    $('#home .navbar-toggler').click(function(){
        if ($(this).children().attr('src') == '../images/icon_bars_3.png') {
            $(this).children().attr('src', '../images/icon_close_3.png');
        }
        else {
            $(this).children().attr('src', '../images/icon_bars_3.png');
        }
    });
    //rotate icon - contact top
    $('.termin-prepravy-toggler').click(function(){
        $('.termin-prepravy-toggler span img').toggleClass('rotate');
    });

    $('.order-check-toggler').click(function(){
        $('.order-check-toggler span img').toggleClass('rotate');
    });
    //form show-hide
    $('a[data-action="form"]').click(function(){
        $('#contact-form').slideDown('slow');
        setTimeout(function(){
            $('main').hide();
        },600);

    });
    $('#contact-form .contact-navigation #cancel').click(function(){
        $('#contact-form').slideUp('slow');
        $('main').show();
    });

    function counting() {
        $('.number-list-item').each(function() {
            var $this = $(this),
                countTo = $this.attr('data-count');

            $({ countNum: $this.text()}).animate({
              countNum: countTo
            },

            {
              duration: 2000,
              easing:'swing',
              step: function() {
                $this.text(Math.floor(this.countNum));
              },
              complete: function() {
                $this.text(this.countNum);
              }
            });
        });
    }


    $('#numbers').waypoint(function(){
        counting();
        console.log('counter_point')
    },
    {
        offset: '75%'
    });*/
  }


  render () {
    const { t } = this.props;

    let transportsFilter = null;

    return (
      <>
        <section id="home">
            <Header1 props={this.props} />

            <div className="home-text container">
                <h1 id="main">
                  { t('banner.title').split('\n').reduce((r, c, x) => r ? [...r, <br key={x}/>, c] : [c], null) }
                </h1>
                <h2 id="small">
                  { t('banner.description').split('\n').reduce((r, c, x) => r ? [...r, <br key={x}/>, c] : [c], null) }
                </h2>
            </div>
        </section>

        <section id="order">
            <div className="container">
                <OrderBoxTransports />
                <OrderBoxState />
            </div>
        </section>

        <Services />
        <About />
        <Offer />
        <Numbers />
      </>
    );
  }
}

export default withTranslation()(IndexPage);

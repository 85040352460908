import React, {PropTypes} from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import Joi from 'joi-browser';
//import { withRouter } from 'react-router-dom';
import ReactGA from "react-ga";

import Form, { Select } from '../form';
import PackageContent from '../parts/packageContent';

// Images
//import img_logo from 'images/logo_MAK.png';
//import img_icon_close from 'images/icon_close_formular.png';

// Backend
import * as DataContractsService from 'services/dataContractsService.js';
import * as DataUsersService from 'services/dataUsersService.js';
import * as DataAddressesService from 'services/dataAddressesService.js';
import * as DataTransportsService from 'services/dataTransportsService.js';
import * as DataNewslettersService from 'services/dataNewslettersService';
import * as Helpers from 'utils/helpers';
import * as Dt from 'utils/dt';
import { toast } from 'react-toastify';
import { today, formatDT, formatD } from 'utils/dt';

// ANALYTICS
import { gtmEvent } from 'analytics';


class OrderForm extends Form {
  defaultContract = {
    _id : 0,
    number : "",
    note : "",

    email: "",

    transport_date : 0,

    addr_src_firstname : "",
    addr_src_lastname : "",
    addr_src_phone : "",
    addr_src_street : "",
    addr_src_city : "",
    addr_src_zip : "",
    addr_src_country : "",

    addr_dst_firstname : "",
    addr_dst_lastname : "",
    addr_dst_phone : "",
    addr_dst_street : "",
    addr_dst_city : "",
    addr_dst_zip : "",
    addr_dst_country : "",

    subscribe : 0,
    print : 1,
  };

  defaultContractTest = {
    _id : 0,
    number : "",
    note : "Testovací poznámka",

    email: "info@fzatloukal.cz",

    transport_date : 0,

    addr_src_firstname : "Mojejméno",
    addr_src_lastname : "Mojepříjmení",
    addr_src_phone : "9876543210",
    addr_src_street : "Mojeulice",
    addr_src_city : "Mojeměsto",
    addr_src_zip : "987654",
    addr_src_country : "sk",

    addr_dst_firstname : "Druhéjméno",
    addr_dst_lastname : "Druhépříjmení",
    addr_dst_phone : "789456123",
    addr_dst_street : "Druháulice",
    addr_dst_city : "Druhéměsto",
    addr_dst_zip : "654321",
    addr_dst_country : "gb",

    subscribe : 0,
    print : 1,
  };

  schema = {
    _id : Joi.number(),
    number : Joi,
    note : Joi.string().min(2).max(2000).label('Popis zásielky'),

    email: Joi.string().email({ minDomainAtoms: 2 }).required().label('E-mail'),

    transport_date : Joi.string().required().label('Dátum'),

    addr_src_firstname : Joi.string().min(2).max(100).label('Meno'),
    addr_src_lastname : Joi.string().min(2).max(100).label('Priezvisko'),
    addr_src_phone : Joi.string().min(7).max(20).label('Telefón'),
    addr_src_street : Joi.string().min(2).max(100).label('Adresa'),
    addr_src_city : Joi.string().min(2).max(100).label('Mesto'),
    addr_src_zip : Joi.string().min(3).max(20).label('PSČ'),
    addr_src_country : Joi.string().required().label('Štát'),

    addr_dst_firstname : Joi.string().min(2).max(100).label('Meno'),
    addr_dst_lastname : Joi.string().min(2).max(100).label('Priezvisko'),
    addr_dst_phone : Joi.string().min(7).max(20).label('Telefón'),
    addr_dst_street : Joi.string().min(2).max(100).label('Adresa'),
    addr_dst_city : Joi.string().min(2).max(100).label('Mesto'),
    addr_dst_zip : Joi.string().min(3).max(20).label('PSČ'),
    addr_dst_country : Joi.string().required().label('Štát'),

    subscribe : Joi,
    print : Joi,
  };
  /*schema = {
    _id : Joi.number(),
    number : Joi,
    note : Joi.string().label('Popis zásielky').allow(''),

    email: Joi.string().label('E-mail').allow(''),

    addr_src_firstname : Joi.string().label('Jméno').allow(''),
    addr_src_lastname : Joi.string().label('Příjmení').allow(''),
    addr_src_phone : Joi.number().label('Telefon').allow(''),
    addr_src_street : Joi.string().label('Adresa').allow(''),
    addr_src_city : Joi.string().label('Město / Okres').allow(''),
    addr_src_zip : Joi.number().label('PSČ').allow(''),
    addr_src_country : Joi.string().label('Stát').allow(''),

    addr_dst_firstname : Joi.string().label('Jméno').allow(''),
    addr_dst_lastname : Joi.string().label('Příjmení').allow(''),
    addr_dst_phone : Joi.number().label('Telefon').allow(''),
    addr_dst_street : Joi.string().label('Adresa').allow(''),
    addr_dst_city : Joi.string().label('Město / Okres').allow(''),
    addr_dst_zip : Joi.number().label('PSČ').allow(''),
    addr_dst_country : Joi.string().label('Stát').allow(''),
  };*/
  schema_package = {
    _id : Joi.number(),
    title : Joi.string().required().label('Obsah zásielky'),
    description : Joi.string().label('Opis zásielky').allow(''),
    quantity : Joi.number().required().label('Zásielka: počet'),
    price : Joi.number().required().label('Zásielka: odhadovaná cena'),
    errors : Joi
  }

  countries = [
    { _id : 'sk', name : "Slovensko" },
    { _id : 'gb', name : "Veľká británia" },
    { _id : 'cz', name : "Česká republika" },
  ];

  state = {
    data : this.defaultContract,
    packages : [],  // each line: { title : "", description : "", quantity : "1", price : "", errors : {} }
    errors : {},
    transports : null,
    countriesDst : null,

    user : null,
  };


  componentDidMount() {
    this.handlePackageAdd();
    this.transportsLoad();
  }


  // GET
  async transportsLoad() {
    try {
      const data = await DataTransportsService.getTransportsByState(1);
      this.setState({ transports : data });
      return data;
    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
      }
      return null;
    }
  }

  async userLoad(email) {
    try {
      const data = await DataUsersService.getUserByEmail(email);
      this.setState({ user : data });
      return data;
    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
        this.setState({ errNotInDb : true });   // Zvolený příspěvek již není v databázi!
      }
      return null;
    }
  }


  getRandom() {
    const min = 1;
    const max = 1000000;
    return parseInt(min + Math.random() * (max - min));
  }


  mapContractFromViewModel(data, userId, srcAddrId, dstAddrId) {
    //Helpers.renameObjKey(data, "_id", "pcId");

    /*return {
      fcUser : Helpers.parseFc(userId),
      fcAddrSrc : Helpers.parseFc(srcAddrId),
      fcAddrDst : Helpers.parseFc(dstAddrId),
      fcTransport : null,
      number : "2021/" + this.getRandom(),
      note : data.note,
    };*/
    const transportDate = parseInt(data.transport_date);
    const td = today();
    return {
      fcUser : userId,
      fcAddrSrc : srcAddrId,
      fcAddrDst : dstAddrId,
      fcTransport : isNaN(transportDate) || (transportDate === 0) ? null : transportDate,
      number : `${td.dd}${td.mm}${td.yy}-${this.getRandom()}`,
      note : data.note,
      iwillprint : (data.print == 1),
    };
  }

  mapUserFromViewModel(data) {
    return {
      email : data.email,
    };
  }

  mapSrcAddrFromViewModel(data) {
    //Helpers.renameObjKey(data, "_id", "pcId");

    return {
      type : 1,
      firstname : data.addr_src_firstname,
      lastname : data.addr_src_lastname,
      //phone : parseInt(data.addr_src_phone),
      phone : data.addr_src_phone,
      addrStreet : data.addr_src_street,
      addrCity : data.addr_src_city,
      //addrZip : parseInt(data.addr_src_zip),
      addrZip : data.addr_src_zip,
      addrCountry : data.addr_src_country,
    };
  }

  mapDstAddrFromViewModel(data) {
    //Helpers.renameObjKey(data, "_id", "pcId");

    return {
      type : 1,
      firstname : data.addr_dst_firstname,
      lastname : data.addr_dst_lastname,
      //phone : parseInt(data.addr_dst_phone),
      phone : data.addr_dst_phone,
      addrStreet : data.addr_dst_street,
      addrCity : data.addr_dst_city,
      //addrZip : parseInt(data.addr_dst_zip),
      addrZip : data.addr_dst_zip,
      addrCountry : data.addr_dst_country,
    };
  }


  handleChangeCheckbox = ({ currentTarget : input }) => {
    const data = { ...this.state.data };
    data[input.name] = input.value == 0 ? 1 : 0;
    this.setState({ data });
  };


  handleEmptySubmit = e => {
    e.preventDefault();
  };


  handleChangeSrcCountry = ({ currentTarget : input }) => {
    // same as "handleChangeInput" from "form.jsx" >>>
    const errors = this.handleValidation(input.name, input.value);

    const data = { ...this.state.data };
    data[input.name] = input.value;
    // <<<
    data.transport_date = 0;

    // Set DST country
    data.addr_dst_country = 0;
    const countriesDst = this.filterDstCountry(input.value);

    this.setState({ data, errors, countriesDst });
  };


  handlePackageAdd = () => {
    const packages = [ ...this.state.packages ];
    packages.push( { title : "", description : "", quantity : "1", price : "", errors : {} } );
    this.setState({ packages });
  };

  handlePackageRemove = line => {
    const packages = this.state.packages.filter( (item, index) => index !== line);
    this.setState({ packages });
  };

  handlePackageChangeInput = ({ currentTarget : input }, id) => {
    const packages = [ ...this.state.packages ];
    packages[id][input.name] = input.value;
    this.setState({ packages });
  };


  packageValidate = (line) => {
    const { error } = Joi.validate(this.state.packages[line], this.schema_package, { abortEarly : false});
    if (! error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };



  // POST
  doSubmit = async (e) => {
    // Validate rest
    let valid = true;
    const packages = [];
    for (let i = 0; i < this.state.packages.length; i++) {
      packages[i] = {...this.state.packages[i]};
      packages[i].errors = this.packageValidate(i);
      if ( (packages[i].errors !== null) && Object.keys(packages[i].errors).length !== 0 ) {
        valid = false;
      }
    }
    this.setState({ packages });

    if (valid) {
      toast.info('Spracovávam...');
    } else {
      // Scroll to top
      window.scrollTo(0, 1400);
      toast.warn("Najskôr opravte chyby vo formulári.");
      return;
    }

    const data = { ...this.state.data };
    data.packages = this.state.packages;

    // Analytics
    this.sendAnalytics();

    try {
      const response = await DataContractsService.placeOrder(data);

      toast.dismiss();
      toast.success('Objednávka vytvorená');
      this.setState({ data : this.defaultContract });
      return true;

    } catch (ex) {
      toast.dismiss();
      console.log(ex, ex.message);
      if ( ex.response && (ex.response.status == 405) ) {
        toast.error( ex.response.data );
      } else {
        toast.error('Chyba pri komunikácii, objednávku prosím pošlite na matkoakubko@matkoakubko.com');
      }
      return false;
    }


    try {
      // user
      let user = await this.userLoad(this.state.data.email);
      if ( user._id === undefined ) {
        user = await DataUsersService.insertUser( this.mapUserFromViewModel(data) );
      }

      const srcAddr = await DataAddressesService.insertAddress( this.mapSrcAddrFromViewModel(data) );
      const dstAddr = await DataAddressesService.insertAddress( this.mapDstAddrFromViewModel(data) );
      const contract = await DataContractsService.insertContract( this.mapContractFromViewModel(data, user._id, srcAddr._id, dstAddr._id) );

      toast.dismiss();
      //this.props.history.push("/order-complete"); // TODO: returning ERROR
      toast.success('Objednávka vytvorená');

      this.setState({ data : this.defaultContract });

    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
        console.log('Objednávka nebyla vytvořena');
      }

      toast.dismiss();
      toast.error('Chyba pri komunikácii, objednávku prosím pošlite na matkoakubko@matkoakubko.com');
      return null;
    }

    // IS HERE to prevent catch error 422 (already subscribed, when )
    if (data.subscribe == 1) {
      try {
        await DataNewslettersService.insertEmail( data.email );
        toast.success('Odber noviniek prihlásený');
      } catch (ex) {
        if (ex.response && ex.response.status === 404) {
          console.log('Nepřihlášeno k odběru novinek');
        } else if (ex.response && ex.response.status === 422) {
          toast.info('Novinky už odoberáte');
        }
      }
    }

  }


  filterDstCountry(selectedSrcCountry) {
    if ( selectedSrcCountry === undefined ) { // No data loaded
      return undefined;
    }
    if ( (selectedSrcCountry == 0) || (selectedSrcCountry === "") ) { // Src country not selected
      return null;
    }

    let out = [];
    let counter = 0;
    for ( let i in this.countries ) {
      const country = this.countries[i];

      if ( ((selectedSrcCountry === "cz") || (selectedSrcCountry === "sk")) && (country._id === "gb") ) {
        out[counter++] = country;
      }
      if ( (selectedSrcCountry === "gb") && ((country._id === "cz") || (country._id === "sk")) ) {
        out[counter++] = country;
      }
    }

    return out;
  }


  filterDates() {
    if ( (this.state.transports === undefined)
      || (this.state.data === undefined)
    ) { // No data loaded
      return undefined;
    }

    const selectedSrcCountry = this.state.data.addr_src_country;
    //const selectedDstCountry = this.state.data.addr_dst_country;

    if ( (selectedSrcCountry === undefined)
      //|| (selectedDstCountry === undefined)
      || (selectedSrcCountry === "")
      //|| (selectedDstCountry === "")
    ) { // Src or dst country not selected
      return null;
    }

    let out = [];
    let counter = 0;
    for ( let i in this.state.transports ) {
      const transport = this.state.transports[i];

      if ( ((selectedSrcCountry === "cz") || (selectedSrcCountry === "sk")) && ((transport.fcAddrSrc == 1) || (transport.fcAddrDst == 2)) ) {
        out[counter++] = { _id : transport._id, name : formatD(transport.dtStart) };
      }
      if ( (selectedSrcCountry === "gb") && (transport.fcAddrSrc == 2) ) {
        out[counter++] = { _id : transport._id, name : formatD(transport.dtStart) };
      }
    }

    return out;
  }


  sendAnalytics() {
    gtmEvent({
      event: 'form-submitted',
      eventProps: {
          category: 'form',
          action: 'submitted',
          label: 'order'
      }
    });

    ReactGA.initialize("UA-49991991-1");
    ReactGA.event({
      category: 'button',
      action: 'preklik',
      label: 'objednavka',
      transport: 'xhr',
    });
  }


  render () {
    const { countries : countriesSrc } = this;
    const { t } = this.props;
    const { transports, data, packages } = this.state;


    // TRANSPORTS
    if (transports === null) {
      // LOADING TRANSPORTS
      return (
        <form onSubmit={this.handleEmptySubmit} id="contact-form" className="mt-3">
          <div className="container">
            <h2 className="headline-center">{t('form.order.title')}</h2>
            <p id="desc">načítám přepravy...</p>
          </div>
        </form>
      );
    } else if ( Object.keys(transports).length === 0 ) {
      // NO TRANSPORTS AVAILABLE -> DISABLE ORDERS
      return (
        <form onSubmit={this.handleEmptySubmit} id="contact-form" className="mt-3">
          <div className="container">
            <h2 className="headline-center">{t('form.order.title')}</h2>
            <p id="desc">{t('form.order.notransport')}.</p>
          </div>
        </form>
      );
    }


    // SELECT DST COUNTRY
    let countriesDst = this.state.countriesDst;
    if ( countriesDst === undefined ) {
      countriesDst = [ { _id : '', name : "načítám..." } ];
    } else if ( countriesDst === null ) {
      countriesDst = [ { _id : '', name : "Najskôr vyberte štát odosielateľa." } ];
    } else if ( countriesDst.length === 0 ) {
      countriesDst = [ { _id : '', name : "Pre tento smer aktuálne nie sú prepravy naplánované, skúste neskôr." } ];
    }

    // SELECT DATES
    let transportDates = this.filterDates();
    if ( transportDates === undefined ) {
      transportDates = [ { _id : '', name : "načítám..." } ];
    } else if ( transportDates === null ) {
      transportDates = [ { _id : '', name : "Najskôr vyberte štát odosielateľa." } ];
    } else if ( transportDates.length === 0 ) {
      /*transportDates = [];
      transportDates[0] = { _id : 0, name : "Pre tento smer aktuálne nie sú prepravy naplánované, skúste neskôr." };*/
      transportDates = [ { _id : '', name : "Pre tento smer aktuálne nie sú prepravy naplánované, skúste neskôr." } ];
    }

    let sum = 0.0;
    for (let i = 0; i < packages.length; i++)
    {
      sum += ( parseFloat(packages[i]["quantity"]) * parseFloat(packages[i]["price"]) );
    }

    return (
      <form onSubmit={this.handleSubmit} id="contact-form" className="mt-3">
          <div className="container">
              <h2 className="headline-center">{t('form.order.title')}</h2>
              <p id="desc">
                { t('form.order.description').split('\n').reduce((r, c, x) => r ? [...r, <br key={x}/>, c] : [c], null) }
                : <Link to="/ako-vyplnit-objednavku" target="_blank">{/*t('form.order.howto')*/"Návod na vyplnenie objednávky."}</Link>
              </p>

              <div className="udaje_odesilatel">
                  <h3>{t('form.order.sender')}</h3>
                  <div className="row">
                      <div className="col-lg-4 input-box">
                          {this.renderInput('addr_src_firstname', t('firstname') )}
                      </div>

                      <div className="col-lg-4 input-box">
                          {this.renderInput('addr_src_lastname', t('lastname') )}
                      </div>

                      <div className="col-lg-4 input-box">
                          {this.renderInput('addr_src_phone', t('phone') )}
                      </div>
                  </div>

                  <div className="row">
                      <div className="col-lg-6 input-box">
                          {this.renderInput('addr_src_street', t('street') )}
                      </div>

                      <div className="col-lg-6 input-box">
                          {this.renderInput('addr_src_city', t('city') )}
                      </div>
                  </div>

                  <div className="row">
                      <div className="col-lg-6 input-box">
                          {this.renderInput('addr_src_zip', t('zip') )}
                      </div>

                      <div className="col-lg-6 input-box">
                          <Select
                            name={'addr_src_country'}
                            label={t('country')}
                            options={countriesSrc}
                            onChange={this.handleChangeSrcCountry}
                            value={data['addr_src_country']}
                          />
                      </div>
                  </div>
              </div>
              <div className="email">
                  <h3>{t('youremail')}</h3>
                  <div className="row justify-content-center">
                      <div className="col-lg-6 input-box">
                          {this.renderInput('email', t('email') )}

                          <label htmlFor="termin-prepravy_checkbox" className="email-checkbox">{t('form.order.emailme.dates')}
                              <input id="termin-prepravy_checkbox" type="checkbox" onChange={this.handleChangeCheckbox} name="subscribe" value={data.subscribe} />
                              <span className="checkmark"></span>
                          </label>

                      </div>
                  </div>
              </div>
              <div className="udaje_prijemce">
                  <h3>{t('form.order.recipient')}</h3>
                  <div className="row">
                      <div className="col-lg-4 input-box">
                          {this.renderInput('addr_dst_firstname', t('firstname') )}
                      </div>

                      <div className="col-lg-4 input-box">
                          {this.renderInput('addr_dst_lastname', t('lastname') )}
                      </div>

                      <div className="col-lg-4 input-box">
                          {this.renderInput('addr_dst_phone', t('phone') )}
                      </div>
                  </div>

                  <div className="row">
                      <div className="col-lg-6 input-box">
                          {this.renderInput('addr_dst_street', t('street') )}
                      </div>

                      <div className="col-lg-6 input-box">
                          {this.renderInput('addr_dst_city', t('city') )}
                      </div>
                  </div>

                  <div className="row">
                      <div className="col-lg-6 input-box">
                          {this.renderInput('addr_dst_zip', t('zip') )}
                      </div>

                      <div className="col-lg-6 input-box">
                          {this.renderSelect('addr_dst_country', t('country') , countriesDst)}
                      </div>
                  </div>


                  <h3>{t('form.order.transport')}</h3>

                  <div style={{ marginBottom : 0 }} className="row justify-content-center">
                      <div className="col-lg-6 input-box">
                          {this.renderSelect('transport_date', t('dateofdelivery') , transportDates)}
                      </div>
                  </div>

                  <div className="email">
                      <div className="row justify-content-center">
                          <div className="col-lg-6 input-box">
                            <label style={{ lineHeight : '36px' }} htmlFor="print_checkbox" className="email-checkbox">{t('form.order.iwillprint')}
                              <input id="print_checkbox" type="checkbox" onChange={this.handleChangeCheckbox} name="print" value={data.print} defaultChecked={data.print == 1} />
                              <span className="checkmark"></span>
                            </label>

                            { (data.print != 1) &&
                              <label htmlFor="print_checkbox_fee" className="email-checkbox">{t('form.order.printprice')}
                                <input id="print_checkbox_fee" type="checkbox" defaultChecked disabled />
                                <span className="checkmark"></span>
                              </label>
                            }
                          </div>
                      </div>
                  </div>

                  <div className="email packages">
                    <div className="row">
                      <div className="col-lg-12 input-box">
                          {this.renderTextarea('note', t('form.order.content'), 3)}
                      </div>
                    </div>

                    <p> &nbsp; &nbsp; Obsah zásielky pre colnú deklaráciu</p>
                    <div className="items">
                      {
                        packages.map((x, i) =>
                          <PackageContent
                              key={i}
                              line={i}
                              data={packages[i]}
                              change={this.handlePackageChangeInput}
                              remove={this.handlePackageRemove}
                            />
                        )
                      }
                    </div>

                    <div style={{ textAlign:"center" }}>
                      <button type="button" onClick={this.handlePackageAdd} className="success" >pridať položku</button>
                    </div>

                    <hr />

                    <p style={{textAlign:"right", fontWeight:"bold"}}>Celková cena: {isNaN(sum) ? 0.0 : Helpers.formatEur(sum)} €</p>
                  </div>

                  <div className="row justify-content-center">
                      <div className="col-lg-3 order-button">
                          <a><button type="submit">{t('form.order.submit')}</button></a>
                      </div>
                  </div>

              </div>

          </div>
      </form>
    );
  }
}

//export default withRouter(OrderForm);
export default withTranslation()(OrderForm);

import React from 'react';
import { useTranslation } from 'react-i18next';

// Assets
import img_flag_sk from 'images/flag_sk_top_active_2x.png';
import img_flag_sk_disabled from 'images/flag_sk_top_2x.png';
import img_flag_gb from 'images/flag_bg_top_active_2x.png';
import img_flag_gb_disabled from 'images/flag_bg_top_2x.png';

const LangSelector = ({ props }) => {
  const { i18n } = useTranslation();

  const changeLang = (lang) => {
    i18n.changeLanguage(lang);

    if (props !== undefined && props.history !== undefined) {
      const pathname = props.location.pathname;

      if (pathname === "/") {
        // LEAVE AS IT IS
      } else if (pathname.startsWith("/404")) {
        // LEAVE AS IT IS
      } else if (pathname.startsWith("/login")) {
        // LEAVE AS IT IS
      } else if (pathname.startsWith("/blog")) {
        // LEAVE AS IT IS
      } else {

        if (lang === "en") {
          // Changed to "EN"
          if (pathname.startsWith("/preprava-balikov-slovensko-anglicko")) {
            props.history.push('/delivery');
          } else if (pathname.startsWith("/stahovanie-nabytku-v-anglicku")) {
            props.history.push('/moving');
          } else if (pathname.startsWith("/nakupy-v-anglicku")) {
            props.history.push('/food');
          } else if (pathname.startsWith("/kontakt")) {
            props.history.push('/contact');
          } else if (pathname.startsWith("/objednavka-prepravy-balika")) {
            props.history.push('/order');
          } else if (pathname.startsWith("/objednano")) {
            props.history.push('/order-complete');
          }
        } else if (lang === "sk") {
          // Changed to "SK"
          if (pathname.startsWith("/delivery")) {
            props.history.push('/preprava-balikov-slovensko-anglicko');
          } else if (pathname.startsWith("/moving")) {
            props.history.push('/stahovanie-nabytku-v-anglicku');
          } else if (pathname.startsWith("/food")) {
            props.history.push('/nakupy-v-anglicku');
          } else if (pathname.startsWith("/contact")) {
            props.history.push('/kontakt');
          } else if (pathname.startsWith("/order")) {
            props.history.push('/objednavka-prepravy-balika');
          } else if (pathname.startsWith("/order-complete")) {
            props.history.push('/objednano');
          }
        }

      }

    }
  }

  return (
    <div style={{ cursor: "pointer" }} className="language-set float-right">
      <span><a onClick={() => changeLang("sk")}><img id="flag_sk" src={ i18n.languages[0] == "sk" ? img_flag_sk : img_flag_sk_disabled } alt="" /></a></span>
      <span><a onClick={() => changeLang("en")}><img id="flag_bg" src={ i18n.languages[0] == "en" ? img_flag_gb : img_flag_gb_disabled } alt="" /></a></span>
    </div>
  )
}


export default LangSelector;

import React, {useEffect} from "react";
import { useLocation } from "react-router-dom";

import ReactGA from "react-ga";
import TagManager from 'react-gtm-module';
import ReactPixel from "react-facebook-pixel";

// https://raptis.wtf/blog/custom-hook-to-connect-google-analytics-in-react/
// https://sujanbyanjankar.com.np/global-site-tag-gtag-in-react-js-without-npm-package
// https://stackoverflow.com/questions/57111146/how-to-use-multiple-reactpixel-trackabc-inside-one-component


const GA_TRACKING_ID = "UA-49991991-1";
const GTM_TRACKING_ID = "GTM-MMPJS3Z";
const FP_TRACKING_ID = "134333297094140";


const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === "development";  // Enable debug mode on the local development environment

export default function useAnalytics() {
  const location = useLocation();

  // Init
  useEffect(() => {
    // Don't use in DEV mode
    if (isDev) {
      console.log("NOTE: App is in 'dev' environment, analytics not initialized after start");
      return;
    }

    // Google analytics
    ReactGA.initialize(GA_TRACKING_ID, {
      debug: isDev,
    });

    // Google Tag Manager
    TagManager.initialize({
      gtmId: GTM_TRACKING_ID,
    });

    // Facebook pixel
    ReactPixel.init(FP_TRACKING_ID, {}, {
      autoConfig: true,
      debug: isDev,
    });
  }, [])


  // When changing page
  useEffect(() => {
    // Don't use in DEV mode
    if (isDev) {
      console.log("NOTE: App is in 'dev' environment, analytics not initialized when location changed");
      return;
    }

    const currentPath = location.pathname + location.search;

    // Google analytics
    /* BUG: https://github.com/cityofaustin/janis/pull/115
    ReactGA.set({ page: currentPath });
    ReactGA.pageview(currentPath);
    FIXED:*/
    ReactGA.set({
      page: window.location.pathname,
      location: `${window.location.origin}${window.location.pathname}${window.location.search}`
    });
    ReactGA.pageview(currentPath);

    // Facebook pixel
    ReactPixel.pageView();
  }, [location])

}


export function gaEvent(data) {
  if (isDev) {
    console.log("NOTE: App is in 'dev' environment, GA event not sent");
    return;
  }

  ReactGA.event(data);
}

export function gtmEvent(dataLayerObject) {
  if (isDev) {
    console.log("NOTE: App is in 'dev' environment, GTM event not sent");
    return;
  }

  TagManager.dataLayer({
    dataLayer: dataLayerObject,
  });
}

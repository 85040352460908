import React, { useEffect } from 'react';
import { useLocation } from "react-router-dom";
import {Helmet} from "react-helmet";

import config from 'config.json';


const Meta = (props) => {
  const location = useLocation();


  useEffect( () => {
    /*const fetchData = async () => {
      await accountLoad();
    };
    fetchData();*/
  }, [] );


  const def = {
    title: "Maťko a Kubko",
    description: "Spoľahlivá preprava balíkov do Anglicka a z Anglicka na Slovensko? Spoločnosť Maťko a Kubko to zariadi. Rýchla preprava za skvelé ceny."
  }

  const meta = config.pages[ location.pathname ];

  return (
    <Helmet>
        <title>{(meta === undefined) ? def.title : meta.title + " | " + def.title }</title>
        <meta name="description" content={(meta === undefined) ? def.description : meta.description } />
    </Helmet>
  );
}


export default Meta;

import React from 'react';
import { useTranslation } from 'react-i18next';

// Images
import img_truck from 'images/truck.jpg';


const About = (props) => {
  const { t } = useTranslation();

  return (
    <section id="about">
        <div className="container-fluid">
            <div className="row">

                <div className="col-xl-5 image">
                    <img src={img_truck} alt="" />
                </div>

                <div className="col-xl-7 text container">
                    <div className="inner-text">
                        <h3 className="headline-left">{t('aboutus')}</h3>
                        { t('aboutus.text').split('\n').map(item => <p key={item}>{item}</p>) }
                    </div>
                </div>

            </div>
        </div>
    </section>
  )
}

export default About;

import React from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

// Images
import img_partner1 from 'images/partner1.png';
import img_partner2 from 'images/partner2.png';
import img_partner3 from 'images/partner3.png';
import img_partner4 from 'images/partner4.jpg';
import img_partner5 from 'images/partner5.png';
import img_partner6 from 'images/partner6.png';


class Partnership extends React.Component {
  state = {
    partners : {
      1 : { _key : 1, icon : img_partner1, link : "https://www.bionatural.sk/" },
      2 : { _key : 2, icon : img_partner2, link : "http://zoya.sk/" },
      3 : { _key : 3, icon : img_partner3, link : "https://balikdosveta.com/" },
      4 : { _key : 4, icon : img_partner4, link : "https://www.novock.sk/" },
      5 : { _key : 5, icon : img_partner5, link : "https://zobortrans.sk/" },
      6 : { _key : 6, icon : img_partner6, link : null },
    },
  };

  render () {
    const { t } = this.props;
    const { partners } = this.state;

    return (
      <section id="partnership" className="border-top">
           <div className="container">
               <h2>{t('partners')}</h2>
               <div className="row justify-content-center">

                 { Object.values(partners).map(item =>
                   <div key={item._key} className="col-lg-2 partner-box">
                       <a href={item.link} target="_blank" rel="nofollow"><img src={item.icon} alt="" /></a>
                   </div>
                 ) }

               </div>
           </div>
       </section>
    );
  }
}

export default withTranslation()(Partnership);

import React from 'react';


const Loader = (props) => {

  return (
      <div style={{
        width: '300px',
        height: '100px',
        margin: 'auto',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      }}>
          <p style={{ fontSize: 'large', color: '#888a8a' }}>Loading / Načítám ...</p>
      </div>
  )
}

export default Loader;

import React from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import Header2 from '../parts/header2';
import Side from '../parts/side';

import BlogItem from './blog/blogItem';

// Backend
import * as DataBlogService from 'services/dataBlogService.js';
import * as Helper from 'utils/helpers';

// Images
import img_arrow_left from 'images/arrow_left_white.png';
import img_arrow_right from 'images/arrow_right_white.png';

class BlogPage extends React.Component {
  state = {
    blogs : null,
    page : 1,
  };


  async componentDidMount() {
    await this.blogsLoad(1);
  }


  handleClickedChangePage = async isPlus => {
    if (isPlus) {
      window.scrollTo(0, 0);
      const page = this.state.page + 1;
      this.setState({ page, blogs: null });
      await this.blogsLoad(page);
    } else {
      if (this.state.page > 1) {
        window.scrollTo(0, 0);
        const page = this.state.page - 1;
        this.setState({ page, blogs: null });
        await this.blogsLoad(page);
      }
    }
  }


  // GET
  async blogsLoad( page ) {
    try {
      const data = await DataBlogService.getBlogsOrdId("desc", page);
      this.setState({ blogs : data });
    } catch(e) {

    }
  }


  render () {
    const { t } = this.props;
    const { blogs, page } = this.state;

    //const blogsFiltered = Object.values(blogs).filter(item => item.enabled === true);

    let blogsFiltered = null;

    if (blogs !== null) {
      const blogKeys = Object.keys(blogs);

      blogsFiltered = [];
      for (let i = blogKeys.length - 1; i >= 0; i--) {
        if (blogs[ blogKeys[i] ].enabled === true) {
          blogsFiltered.push( blogs[ blogKeys[i] ] );
        }
      }
    }


    return(
      <>
        <section id="home">
            <Header2 props={this.props} />
        </section>

        <section id="content" className="border-top">
            <div className="container">
                <h2 className="headline-left">{t('blog')}</h2>
                <div className="row">

                    <div className="col-xl-8 main blog-articles">

                      {
                        (blogsFiltered === null) ?
                          <p></p>
                        :
                          <div className="paginator row" style={{ textAlign: 'center' }}>
                            <div className="col-xl-6">
                              {
                                (page > 1) &&
                                  <button className="border" onClick={ () => this.handleClickedChangePage(false) }><img src={img_arrow_left} alt="" /> &nbsp; {t('newer')}</button>
                              }
                            </div>
                            <div className="col-xl-6">
                              {
                                (blogsFiltered.length === 5) &&
                                  <button className="border" onClick={ () => this.handleClickedChangePage(true) }>{t('older')} &nbsp; <img src={img_arrow_right} alt="" /></button>
                              }
                            </div>
                          </div>
                      }


                      <section id="we-transport">
                        {
                          ( (blogsFiltered === null) || (blogsFiltered.length === 0) ) ?
                            <p style={{ textAlign: 'center' }}>{ (blogsFiltered === null) ? t('loading') + "..." : "" }</p>
                          :
                            blogsFiltered.map(item =>
                              <BlogItem
                                key={item._id}
                                id={item._id}
                                slug={item.slug}
                                title={item.title}
                                image={item.image}
                                text={item.excerpt}
                              />
                            )
                        }
                      </section>


                      {
                        (blogsFiltered === null) ?
                          <p></p>
                        :
                          <div className="paginator row" style={{ textAlign: 'center', marginTop: '50px' }}>
                            <div className="col-xl-6">
                              {
                                (page > 1) &&
                                  <button className="border" onClick={ () => this.handleClickedChangePage(false) }><img src={img_arrow_left} alt="" /> &nbsp; {t('newer')}</button>
                              }
                            </div>
                            <div className="col-xl-6">
                              {
                                (blogsFiltered.length === 5) &&
                                  <button className="border" onClick={ () => this.handleClickedChangePage(true) }>{t('older')} &nbsp; <img src={img_arrow_right} alt="" /></button>
                              }
                            </div>
                          </div>
                      }

                    </div>

                    <div className="col-xl-4 side">
                        <Side />
                    </div>

                  </div>
              </div>
          </section>
      </>
    );
  }
}

export default withTranslation()(BlogPage);

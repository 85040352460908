import React from 'react';
import { withTranslation } from 'react-i18next';

import Header2 from '../parts/header2';

// Assets


class Page404 extends React.Component {
  render () {
    const { t } = this.props;

    return(
      <>
          <Header2 props={this.props} />
          <p>{t('page.notfound')}.</p>
      </>
    );
  }
}

export default withTranslation()(Page404);

import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';

import Header2 from '../parts/header2';
import Side from '../parts/side';
import SharedPricing from './shared/pricing';

// Assets
import 'css/preprava_balikov.css';

// Images
import img_truck from 'images/truck.jpg';

import img_arrow from 'images/arrow_dotted_right.png';
import img_icon_sk from 'images/flag_sk.png';
import img_icon_cz from 'images/flag_cz.png';
import img_icon_gb from 'images/flag_bg.png';


const DeliveryPage = (props) => {
  const { i18n, t } = useTranslation();

  return(
    <>
      <section id="home">
          <Header2 props={props} />
      </section>

      <section id="content" className="border-top">
          <div className="container">
              <h2 className="headline-left">{t('page.delivery')}</h2>
              <div className="row">
                  <div className="col-xl-8 main">
                      <section id="preprava_balikov">
                          { i18n.languages[0] == "sk" &&
                            <p>{ /*t('page.delivery.introduction')*/ }</p>
                          }
                          <img src={img_truck} alt="" />
                      </section>

                      <section id="we-transport">
                          <h3 className="headline-left">{t('whatwedeliver')}?</h3>
                          <p>{t('page.delivery.whatwedeliver')}</p>

                          <h3 className="headline-left">{t('notcontain')}?</h3>
                          <p><Trans i18nKey="page.delivery.notcontain"><Link to="/vseobecne-zmluvne-podmienky-ustanovenia-pri-preprave-zasielok-vzp"></Link></Trans></p>
                          <div style={{ marginTop: '30px' }}>
                            <Link to={i18n.language === "sk" ? "/objednavka-prepravy-balika" : "/order"} data-action="form"><button>{t('orderit')}</button></Link>
                          </div>
                      </section>


                      <SharedPricing />


                      <section>
                          <h3 className="headline-left">{t('howlong')}?</h3>
                          <p><Trans i18nKey="page.delivery.howlong"><Link to="/najblizsi-termin-prepravy/"></Link><b></b><Link to="/zberne-miesta"></Link></Trans></p>
                      </section>

                      <section id="packing">
                          <h3 className="headline-left">{t('howpack')}?</h3>
                          <p>{t('page.delivery.howpack').split('\n').reduce((r, c, x) => r ? [...r, <br key={x}/>, c] : [c], null)}</p>
                          { i18n.languages[0] == "sk" &&
                            <div className="packing-list border-top border-bottom">
                                <p id="list-title">{t('read')}:</p>
                                <ul className="inner-list">
                                    <li><Link to="/ako-spravne-zabalit-elektroniku-pri-preprave-do-zahranicia">{t('page.delivery.howpack.electronics')}</Link></li>
                                    <li><Link to="/ako-zabalit-kufor-pri-preprave-zahranicia">{t('page.delivery.howpack.suitcase')}</Link></li>
                                    <li><Link to="/ako-zabalit-balik-do-anglicka-navod-krok-za-krokom">{t('page.delivery.howpack.stepbystep')}</Link></li>
                                </ul>
                            </div>
                          }
                      </section>

                      <section>
                          <h3 className="headline-left">{t('issafe')}?</h3>
                          <p><Trans i18nKey="page.delivery.issafe"><b></b></Trans></p>
                      </section>

                      { i18n.languages[0] == "sk" &&
                        <>
                          <section>
                              <h3 className="headline-left">{t('discount')}</h3>
                              <p><Trans i18nKey="page.delivery.discount"><Link to="/vernostna-zlava"></Link><b></b><Link to="/vernostna-zlava"></Link></Trans></p>
                          </section>

                          <section>
                              <h3 className="headline-left">{t('free')}</h3>
                              <p><Trans i18nKey="page.delivery.free"><Link to="/matko-a-kubko-pomaha-detom"></Link></Trans></p>
                          </section>
                        </>
                      }

                      <section>
                          <p><Trans i18nKey="page.delivery.order"><span></span></Trans></p>
                          <Link to={i18n.language === "sk" ? "/objednavka-prepravy-balika" : "/order"} data-action="form"><button>{t('orderit')}</button></Link>
                      </section>
                  </div>

                  <div className="col-xl-4 side">
                      <Side />
                  </div>

              </div>
          </div>
      </section>
    </>
  );
}

export default DeliveryPage;

import React from 'react'

import Header2 from '../parts/header2';

// Assets


class LoginPage extends React.Component {
  render () {
    return(
      <React.Fragment>
        <section id="home">
            <Header2 props={this.props} />
        </section>
      </React.Fragment>
    );
  }
}

export default LoginPage;

import React, { PropTypes, useContext } from 'react';
import { Context } from '../../global.jsx';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

// Images
import img_arrow from 'images/arrow_right_red.png';


const Blog = ({ t, textlinksonly, size }) => {
    const [state, dispatch] = useContext(Context);
    const { blogs } = state;

    if ( (blogs === null) || (typeof blogs !== 'object') || (Object.keys(blogs).length === 0) ) {
      return ( textlinksonly ? <section id="blog-side"></section> : null );
    }

    let count = 0;
    //const blogsFiltered = Object.values(blogs).filter(item => (item.enabled === true) && (count++ < {size}) );
    //const blogsFiltered = Object.values(blogs).filter(item => (item.enabled === true) );
    const blogKeys = Object.keys(blogs);
    let blogsFiltered = [];
    for (let i = blogKeys.length - 1; i >= 0; i--) {
      if (blogsFiltered.length >= (textlinksonly ? 5 : 3)) {
        break;
      }

      if (blogs[ blogKeys[i] ].enabled === true) {
        blogsFiltered.push( blogs[ blogKeys[i] ] );
      }
    }

    return (
      textlinksonly ?

      <section id="blog-side">
          <h2>{t('fromblog')}</h2>
          <ul className="blog-list">

            { blogsFiltered.map(item =>
              <li key={item._id}>
                <Link to={"/" + item.slug}>{item.title}</Link>
              </li>
            )}

          </ul>
      </section>

      :

      <section id="blog-section">
          <div className="container">
              <h3 className="headline-center">{t('fromblog')}</h3>
              <div className="row">

                { blogsFiltered.map(item =>
                  <div
                    key={item._id}
                    className="col-lg-4 blog-item"
                  >
                    <Link to={"/" + item.slug} id="img_link" href=""><img src={item.image} /></Link>
                    <Link to={"/" + item.slug} id="title_link" href=""><h3>{item.title}</h3></Link>
                    <p dangerouslySetInnerHTML={{__html: item.excerpt}}></p>
                    <Link to={"/" + item.slug} id="continue_link">
                        {t('continuereading')}
                        <img src={img_arrow} alt="" />
                    </Link>
                  </div>
                )}

              </div>
              <Link to="/blog"><button className="border">{t('allarticles')}</button></Link>
          </div>
      </section>
    );
}

export default withTranslation()(Blog);

Blog.defaultProps = {
  size : 3
};

import React from 'react';
import { useTranslation } from 'react-i18next';

import {isset} from 'utils/helpers';

// Images
import icon_close from 'images/icon_close_3.png';


const PackageContent = ({ line, data, change, remove }) => {
  const { t } = useTranslation();

  return (
    <div key={line} className="row">
      <div className="col-lg-5">
        <label className="form-required">Druh tovaru (oblečenie, sladkosti, kozmetika, ...)</label>
        <input onChange={ e => change(e, line) } value={data.title} name="title" className="form-control" />
        { isset(data.errors) && isset(data.errors.title) && <div className="alert alert-danger">{data.errors.title}</div> }
      </div>
      <div className="col-lg-3">
        <label className="form-required">Počet</label>
        <input onChange={ e => change(e, line) } value={data.quantity} type="number" name="quantity" min="1" step="1" className="form-control" />
        { isset(data.errors) && isset(data.errors.quantity) && <div className="alert alert-danger">{data.errors.quantity}</div> }
      </div>
      <div className="col-lg-3">
        <label className="form-required">Cena jedného kusu [€]</label>
        <input onChange={ e => change(e, line) } value={data.price} type="number" name="price" min="0" step="0.01" className="form-control" />
        { isset(data.errors) && isset(data.errors.price) && <div className="alert alert-danger">{data.errors.price}</div> }
      </div>
      <div className="col-lg-1">
        {
          line > 0 &&
            <button type="button" onClick={ () => remove(line) } className="imgbutton"><img src={icon_close} /></button>
        }
      </div>
    </div>
  );
}

export default PackageContent;

import React from 'react';
import { withTranslation } from 'react-i18next';
import Joi from 'joi-browser';

import Form from '../form';

// Backend
import * as DataMessagesService from 'services/dataMessagesService.js';
import { toast } from 'react-toastify';


class ContactForm extends Form {
  defaultMessage = {
    _id : 0,
    email: "",
    phone : "",
    lastname : "",
    message : "",
  };

  defaultMessageTest = {
    _id : 0,
    email: "mail@mail.mail",
    phone : "876543210",
    lastname : "Celé jméno",
    message : "Toto je testovací zpráva",
  };

  schema = {
    _id : Joi.number(),
    email: Joi.string().email({ minDomainAtoms: 2 }).required().label('E-mail'),
    phone : Joi.string().required().label('Telefón'),
    lastname : Joi.string().required().label('Meno'),
    message : Joi.string().required().label('Správa'),
  };

  state = {
    data : this.defaultMessage,
    errors : {},
    countries : [
      { _id : 'sk', name : "Slovensko" },
      { _id : 'gb', name : "Veľká británia" },
      { _id : 'cz', name : "Česká republika" },
    ],
  };


  mapFromMessage(data) {
    //Helper.renameObjKey(data, "_id", "pcId");
    //data.phone = parseInt(data.phone);

    return data;
  }


  // POST
  doSubmit = async (e) => {
    //this.props.onSave( this.state.data );
    toast.info('zpracovávám...');

    try {
      const mappedMessages = this.mapFromMessage(this.state.data);
      await DataMessagesService.insertMessage( mappedMessages );

      toast.success('Správa odoslaná');

      this.setState({ data : this.defaultMessage });
      return true;

    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
        console.log('Zpráva nebyla odeslána');
      }

      toast.error('Chyba pri komunikácii, správu prosím pošlite na matkoakubko@matkoakubko.com');
      return false;
    }

  }


  render () {
    const { t } = this.props;

    return (
      <form onSubmit={this.handleSubmit} id="contact-form">
          {this.renderInput('lastname', t('yourname') + ' *')}
          {this.renderInput('phone', t('yourphone') + ' *')}
          {this.renderInput('email', t('youremail') + ' *')}
          {this.renderTextarea('message', t('yourmessage') + ':')}

          <button type="submit">{t('submit')}</button>
      </form>
    );
  }
}

export default withTranslation()(ContactForm);

import React from 'react';
import { withTranslation } from 'react-i18next';
import Joi from 'joi-browser';

import Form from '../form';

// Backend
import * as DataContractsService from 'services/dataContractsService.js';
import * as Helpers from 'utils/helpers';
import * as DT from 'utils/dt';


class OrderStateForm extends Form {
  defaultContractState = {
    _id : 0,
    number: "",
  };

  schema = {
    _id : Joi.number(),
    //number : Joi.number().label('Číslo objednávky'),
    number : Joi,
  };

  state = {
    data : this.defaultContractState,
    errors : {},
    contractStates : {},
    contractToStates : {},
    contractToStatesText : null,

    errNotInDb : false,
  };


  async contractStatesLoad() {
    try {
      const contractStates = await DataContractsService.getContractStates();
      this.setState({ contractStates });
      return contractStates;
    } catch(e) {
      return null;
    }
  }

  async contractToStatesLoad( contractNumber ) {
    try {
      const contractToStates = await DataContractsService.getContractToStateByContractnumber( contractNumber );
      this.setState({ contractToStates });
      return contractToStates;
    } catch(e) {
      return null;
    }
  }

  async contractLoad( contractNumber ) {
    try {
      const contracts = await DataContractsService.getContractByNumber( contractNumber );
      //this.setState({ contract : ( contracts.length === 1 ? contracts[0] : null ) });
      return contracts;

    } catch (e) {
      if (e.response && e.response.status === 404) {
        this.setState({ errNotInDb : true }); // Not found
      }
      return null;
    }
  }


  handleChangeInput = ({ currentTarget : input }) => {
    const data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data });
  };


  // POST
  doSubmit = async (e) => {
    if ( this.state.data.number === "" ) {
      this.setState({ contractToStates : {}, contractToStatesText : "Vyplňte číslo objednávky" });
      return;
    }

    //this.props.onSave( this.state.data );
    this.setState({ contractToStates : {}, contractToStatesText : "Načítám stav ..." });

    try {
      var contractToStatesText = "";

      const contractToStates = await this.contractToStatesLoad( this.state.data.number );
      // If no record found, contract can be: not existing OR exists and is without any states
      if (contractToStates.length === 0) {
        const contract = await this.contractLoad( this.state.data.number );
        if (contract.length === 0) {
          contractToStatesText = "Objednávka nenalezena";
        } else if (contract.length > 1) {
          contractToStatesText = "Objednávka nenalezena";
        } else  {
          contractToStatesText = `Nalezena objednávka číslo "${this.state.data.number}":\nTuto objednávku jsme zatím nezpracovali`;
        }
        this.setState({ contractToStatesText });
        return false;
      }

      const contractStates = await this.contractStatesLoad();
      contractToStatesText = `Nalezena objednávka číslo "${this.state.data.number}":`;

      // Contract records found
      const contractToStatesArray = Object.values(contractToStates);
      for (let i = 0; i < contractToStatesArray.length; i++) {
        const current = contractToStatesArray[i];
        contractToStatesText += "\n" + (i+1) + ") " + DT.formatDT(current.dtCreated) + ": " + contractStates[current.fcState].name;
      }
      this.setState({ contractToStatesText });
      return true;

    } catch (e) {
      if (e.response && e.response.status === 404) {
        this.setState({ contractToStatesText : "Objednávka nenalezena" });
      } else {
        this.setState({ contractToStatesText : "Neočekávaná chyba při komunikaci se serverem" });
      }
      return false;
    }
  }


  render () {
    //<form onSubmit={this.handleSubmit} id="state-form">
    //</form>
    const { t } = this.props;
    const { contract, contractToStates, contractToStatesText } = this.state;

    return (
      <>
        <input onChange={this.handleChangeInput} name="number" type="text" placeholder={t('form.state.number') + "..."} />
        <a className="flex-grow-1" onClick={this.handleSubmit}><button>{t('form.state.submit')}</button></a>

        { (contractToStatesText !== null) &&
          <div style={{ width: '100%', textAlign: 'center', marginTop: '20px' }}>
            <p style={{ marginBottom: '0' }}>{ Helpers.newlineTextToBr(contractToStatesText) }</p>
          </div>
        }
      </>
    );
  }
}

export default withTranslation()(OrderStateForm);

import React, {useEffect, useContext} from 'react';
import {Context} from '../../global.jsx';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


// Images
import img_icon_up from 'images/icon_up.png';

// Backend
import * as Dt from 'utils/dt';


const HeaderTransportBox = ({ }) => {
  const { i18n, t } = useTranslation();
  const [state, dispatch] = useContext(Context);
  const { plannedTransports } = state;

  if ( (state.plannedTransports === null) || (state.plannedTransports.length === 0) ) return null;

  return (
    <div className="termin-prepravy border-top">
        <div className="container">
            <div className="termin-prepravy-toggler" data-toggle="collapse" aria-expanded="true" data-target="#termin-prepravy-trigger">
                <strong>{t('nearesttermoftranpsport')}</strong>
                <span><img src={img_icon_up} alt="" /></span>
            </div>
            <div id="termin-prepravy-trigger" className="termin-prepravy-content collapse">
                <div className="row">

                    { plannedTransports.map( item =>
                        <div key={item.key} className="col-lg-4 preprava-item border">
                            <h2>{t( (item.direction === 1) ? "fromsktoen" : ((item.direction === 2) ? "fromentosk" : "") )}</h2>
                            <div id="date-preprava">
                                <p>{t('dateofdelivery')}</p>
                                <span className="datum_prepravy">{ Dt.formatD(item.transport.dtStart) }</span>
                            </div>
                            <div id="date-uzavirka" className={ (false) ? "urgent" : "" }>
                                <p>{t('deadlineoforders')}</p>
                                <span className="datum_prepravy">{ Dt.formatD(item.transport.dtClose) } <small>{t('at')} 15:00</small></span>
                            </div>
                        </div>
                      )
                    }
                    <div id="preprava-button" className="col-lg-4 preprava-item">
                        <Link to={i18n.language === "sk" ? "/objednavka-prepravy-balika" : "/order"} data-action="form">{t('orderit')}</Link>
                    </div>

                </div>
            </div>
        </div>
    </div>
  );
};

export default HeaderTransportBox;

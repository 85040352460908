import React from 'react'
import { Link } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';

import Header2 from '../../parts/header2';
import Side from '../../parts/side';

// Images


const InfoDutyPage = (props) => {
  const { i18n, t } = useTranslation();

  return(
    <>
      <section id="home">
          <Header2 props={props} />
      </section>

      <section id="content" className="border-top">
          <div className="container">
              <h2 className="headline-left">{t('brexit')}</h2>
              <div className="row">

                  <div className="col-xl-8 main">
                    <section id="we-transport">
                      <p>{t('pageInfo.duty.introduction')}</p>

          						<h2>{t('pageInfo.duty.save')}</h2>
        							<p><Trans i18nKey="pageInfo.duty.text1"><b></b></Trans></p>
        							<p>{t('pageInfo.duty.text2')}</p>

                      <p><b>{t('example')}:</b></p>
                      <ul><Trans i18nKey="pageInfo.duty.example1"><li></li><li></li><li></li></Trans></ul>
                      <p><Trans i18nKey="pageInfo.duty.example1comment"><b></b></Trans></p>

                      <p><b>{t('example')}:</b></p>
                      <p><Trans i18nKey="pageInfo.duty.example2"><b></b></Trans></p>
                      <p>&nbsp;</p>

                      <p><Trans i18nKey="pageInfo.duty.text3"><b></b></Trans></p>

                      <h2>{t('pageInfo.duty.duty')}</h2>
                      <p>{t('pageInfo.duty.duty1')}</p>
                      <ol><Trans i18nKey="pageInfo.duty.duty2"><li></li><li></li><li></li></Trans></ol>
                      <p>{t('pageInfo.duty.duty3')} <Link to="/stahovanie-nabytku-v-anglicku">{t('moving')}</Link></p>

          					</section>
                  </div>

                  <div className="col-xl-4 side">
                      <Side />
                  </div>

              </div>
          </div>
      </section>
    </>
  );
}

export default InfoDutyPage;

import config from 'config.json';
import * as Http from './httpService';
import * as Helpers from 'utils/helpers';
import * as Dt from 'utils/dt';
import * as Mapper from 'utils/mapper';

const apiUsersUrl = config.apiUrl + '/users';
const apiUserToAddressesUrl = config.apiUrl + '/user_to_addresses';

// Users

export function mapToUsers(items) {
  let mappedItems = {};
  for ( let i = 0; i < items.length; i++ ) {
    const item = mapToUser( items[i] );
    mappedItems[ item._id ] = item;
  }
  return mappedItems;
}

export function mapToUser(data) {     // mapToViewModel
  Helpers.renameObjKey(data, "pcId", "_id");
  data._id = Helpers.parsePc(data._id);
  data.fcRole = Helpers.parseFc(data.fcRole);
  data.dtCreated = Dt.fromMysql(data.dtCreated);

  return data;
}

export function mapFromUser(data) {
  Helpers.renameObjKey(data, "_id", "pcId");
  Helpers.removeObjKey(data, "dtCreated");
  if ( (data.fcRole !== undefined) && (data.fcRole !== null) ) {
    data.fcRole = "/roles/" + data.fcRole;
  }

  return data;
}


export async function getUserByEmail(email) {
  //const response = await Http.get( apiUsersUrl + '/onebyemail/' + email + config.apiFormat );
  const response = await Http.get( apiUsersUrl + '/onebyemail/' + email );
  return mapToUser( response );
}


export async function getUsers() {
  const response = await Http.get( apiUsersUrl + config.apiFormat );
  return mapToUsers( response );
}

export async function getUser(id) {
  const response = await Http.get( apiUsersUrl + '/' + id + config.apiFormat );
  return mapToUser( response );
}

export async function insertUser(data) {
  const response = await Http.post( apiUsersUrl + config.apiFormat, mapFromUser(data) );
  return mapToUser( response );
}

export async function updateUser(id, data) {
  const response = await Http.put( apiUsersUrl + '/' + id + config.apiFormat, mapFromUser(data) );
  return mapToUser( response );
}

export async function deleteUser(id) {
  return await Http.del( apiUsersUrl + '/' + id + config.apiFormat );
}


// UserToAddresses

export function mapToUserToAddresses(items) {
  let mappedItems = {};
  for ( let i = 0; i < items.length; i++ ) {
    const item = mapToUserToAddress( items[i] );
    mappedItems[ item._id ] = item;
  }
  return mappedItems;
}

export function mapToUserToAddress(data) {     // mapToViewModel
  Helpers.renameObjKey(data, "pcId", "_id");
  data._id = Helpers.parsePc(data._id);
  data.fcUser = Helpers.parseFc(data.fcUser);
  data.fcAddress = Helpers.parseFc(data.fcAddress);

  return data;
}

export function mapFromUserToAddress(data) {
  Helpers.renameObjKey(data, "_id", "pcId");

  return data;
}


export async function getUserToAddresses() {
  const response = await Http.get( apiUserToAddressesUrl + config.apiFormat );
  return mapToUserToAddresses( response );
}

export async function getUserToAddress(id) {
  const response = await Http.get( apiUserToAddressesUrl + '/' + id + config.apiFormat );
  return mapToUserToAddress( response );
}

export async function insertUserToAddress(data) {
  const response = await Http.post( apiUserToAddressesUrl + config.apiFormat, mapFromUserToAddress(data) );
  return mapToUserToAddress( response );
}

export async function updateUserToAddress(id, data) {
  const response = await Http.put( apiUserToAddressesUrl + '/' + id + config.apiFormat, mapFromUserToAddress(data) );
  return mapToUserToAddress( response );
}

export async function deleteUserToAddress(id) {
  return await Http.del( apiUserToAddressesUrl + '/' + id + config.apiFormat );
}


export function testUsers() {
  return [
      { _id: 1,
        contact: 'Jméno Příjmení\nUlice 123\nMěsto 12345\nSlovenská republika',
        login: 'Role: administrátor\nEmail: email@email.sk',
      },
      { _id: 2,
        contact: 'Jméno Příjmení\nUlice 123\nMěsto 12345\nSlovenská republika',
        login: 'Role: administrátor\nEmail: email@email.sk',
      },
      { _id: 3,
        contact: 'Jméno Příjmení\nUlice 123\nMěsto 12345\nSlovenská republika',
        login: 'Role: administrátor\nEmail: email@email.sk',
      },
      { _id: 4,
        contact: 'Jméno Příjmení\nUlice 123\nMěsto 12345\nSlovenská republika',
        login: 'Role: administrátor\nEmail: email@email.sk',
      },
    ];
}

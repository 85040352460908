import config from 'config.json';
import * as Http from './httpService';
import * as Helpers from 'utils/helpers';
import * as Dt from 'utils/dt';

const apiTransportsUrl = config.apiUrl + '/transports';
const apiTransportStateStatesUrl = config.apiUrl + '/transport_states';
const apiTransportRouteRoutesUrl = config.apiUrl + '/transport_routes';

// Transports

export function mapToTransports(items) {
  let mappedItems = {};
  for ( let i = 0; i < items.length; i++ ) {
    const item = mapToTransport( items[i] );
    mappedItems[ item._id ] = item;
  }
  return mappedItems;
}

export function mapToTransport(data) {
  Helpers.renameObjKey(data, "pcId", "_id");
  data._id = Helpers.parsePc(data._id);
  data.fcState = Helpers.parseFc(data.fcState);
  data.fcAddrSrc = Helpers.parseFc(data.fcAddrSrc);
  data.fcAddrDst = Helpers.parseFc(data.fcAddrDst);
  data.dtClose = Dt.fromMysql(data.dtClose);
  data.dtStart = Dt.fromMysql(data.dtStart);
  data.dtFinish = Dt.fromMysql(data.dtFinish);

  return data;
}

export function mapFromTransport(data) {
  Helpers.renameObjKey(data, "_id", "pcId");
  data.dtClose = Dt.toMysql(data.dtClose);
  data.dtStart = Dt.toMysql(data.dtStart);
  data.dtFinish = Dt.toMysql(data.dtFinish);
  data.fcAddrSrc = "/addresses/" + data.fcAddrSrc;
  data.fcAddrDst = "/addresses/" + data.fcAddrDst;
  data.fcState = "/transport_states/" + data.fcState;

  return data;
}


export async function getTransports() {
  const response = await Http.get( apiTransportsUrl + config.apiFormat );
  return mapToTransports( response );
}

// BEWARE: THIS RETURNS ARRAY INSTEAD OF OBJECT
export async function getTransportsByState( stateNumber, order ) {
  var params = {
    fcState : stateNumber
  };
  if ( order !== undefined ) {
    params[ 'order[dtStart]'] = (order) ? 'asc' : 'desc';
  }

  const response = await Http.get( apiTransportsUrl + config.apiFormat, params );

  let mappedItems = [];
  for ( let i = 0; i < response.length; i++ ) {
    const item = mapToTransport( response[i] );
    mappedItems[i] = item;
  }
  return mappedItems;
}

export async function getTransport(id) {
  const response = await Http.get( apiTransportsUrl + '/' + id + config.apiFormat );
  return mapToTransport( response );
}

export async function insertTransport(data) {
  const response = await Http.post( apiTransportsUrl + config.apiFormat, mapFromTransport(data) );
  return mapToTransport( response );
}

export async function updateTransport(id, data) {
  const response = await Http.put( apiTransportsUrl + '/' + id + config.apiFormat, mapFromTransport(data) );
  return mapToTransport( response );
}

export async function deleteTransport(id) {
  return await Http.del( apiTransportsUrl + '/' + id + config.apiFormat );
}


// TransportStates

export function mapToTransportStates(items) {
  let mappedItems = {};
  for ( let i = 0; i < items.length; i++ ) {
    const item = mapToTransportState( items[i] );
    mappedItems[ item._id ] = item;
  }
  return mappedItems;
}

export function mapToTransportState(data) {
  Helpers.renameObjKey(data, "pcId", "_id");
  data._id = Helpers.parsePc(data._id);

  return data;
}

export function mapFromTransportState(data) {
  Helpers.renameObjKey(data, "_id", "pcId");

  return data;
}


export async function getTransportStates() {
  const response = await Http.get( apiTransportStateStatesUrl + config.apiFormat );
  return mapToTransportStates( response );
}

export async function getTransportState(id) {
  const response = await Http.get( apiTransportStateStatesUrl + '/' + id + config.apiFormat );
  return mapToTransportState( response );
}

export async function insertTransportState(data) {
  const response = await Http.post( apiTransportStateStatesUrl + config.apiFormat, mapFromTransportState(data) );
  return mapToTransportState( response );
}

export async function updateTransportState(id, data) {
  const response = await Http.put( apiTransportStateStatesUrl + '/' + id + config.apiFormat, mapFromTransportState(data) );
  return mapToTransportState( response );
}

export async function deleteTransportState(id) {
  return await Http.del( apiTransportStateStatesUrl + '/' + id + config.apiFormat );
}


// TransportRoutes

export function mapToTransportRoutes(items) {
  let mappedItems = {};
  for ( let i = 0; i < items.length; i++ ) {
    const item = mapToTransportRoute( items[i] );
    mappedItems[ item._id ] = item;
  }
  return mappedItems;
}

export function mapToTransportRoute(data) {     // mapToViewModel
  Helpers.renameObjKey(data, "pcId", "_id");
  data._id = Helpers.parsePc(data._id);
  data.fcTransport = Helpers.parseFc(data.fcTransport);
  data.fcUser = Helpers.parseFc(data.fcUser);
  data.fcAddrSrc = Helpers.parseFc(data.fcAddrSrc);
  data.fcAddrDst = Helpers.parseFc(data.fcAddrDst);
  data.dtStart = Dt.fromMysql(data.dtStart);
  data.dtFinish = Dt.fromMysql(data.dtFinish);

  return data;
}

export function mapFromTransportRoute(data) {
  Helpers.renameObjKey(data, "_id", "pcId");

  return data;
}


export async function getTransportRoutes() {
  const response = await Http.get( apiTransportRouteRoutesUrl + config.apiFormat );
  return mapToTransportRoutes( response );
}

export async function getTransportRoute(id) {
  const response = await Http.get( apiTransportRouteRoutesUrl + '/' + id + config.apiFormat );
  return mapToTransportRoute( response );
}

export async function insertTransportRoute(data) {
  const response = await Http.post( apiTransportRouteRoutesUrl + config.apiFormat, mapFromTransportRoute(data) );
  return mapToTransportRoute( response );
}

export async function updateTransportRoute(id, data) {
  const response = await Http.put( apiTransportRouteRoutesUrl + '/' + id + config.apiFormat, mapFromTransportRoute(data) );
  return mapToTransportRoute( response );
}

export async function deleteTransportRoute(id) {
  return await Http.del( apiTransportRouteRoutesUrl + '/' + id + config.apiFormat );
}


export function testData() {
  return [
      { _id: 1,
        transport: 'Přeprava: 20.1.2020\nUzavírka: 15.1.2020\nUkončení: 25.1.2020\nVýchozí adresa: Slovensko\nCílová adresa: Anglie',
        process: 'Přeprava nebyla zahájena.',
        contracts: 'Nepotvrzených zakázek: 3\nCelkem zakázek: 8',
      },
    ];
}

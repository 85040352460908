import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';

import {isset,empty} from 'utils/helpers';
// Context
import {useGlobal} from 'context/globalContext.js';
// Backend
import * as DataSettingsService from 'services/dataSettingsService.js';


const Bar = ({show, text, link, bgColor, textColor}) => {
  // Hooks
  const {global, barSet} = useGlobal();


  useEffect( () => {
    const fetchData = async () => {
      const status = await settingsLoad();
    };
    if (global.bar.allowed === null) {
      fetchData();
    }
  }, [global.bar] );


  // GET
  const settingsLoad = async(id) => {
    const bar = {};
    try {
      const data = await DataSettingsService.getSettingsByKeys();
      for (const key in data) {
        if ( isset(data[key]) ) {
          switch (key) {
            case "main_bar-allowed":
              bar["allowed"] = (data[key].value == "1");
              console.log( bar["allowed"] );
              break;
            case "main_bar-text":
              bar["text"] = data[key].value;
              break;
            case "main_bar-link":
              bar["link"] = data[key].value;
              break;
            case "main_bar-color_bg":
              bar["color_bg"] = data[key].value;
              break;
            case "main_bar-color_text":
              bar["color_text"] = data[key].value;
              break;
            default:
              break;
          }
        }
      }
      barSet(bar);
      return true;
    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
      }
      return null;
    }
  };

  // Bar disabled
  if (global.bar.allowed !== true) {
    return null;
  }

  // NO-Link
  if ( empty(global.bar.link) ) {
    return (
      <div className="bar" style={{backgroundColor:global.bar.color_bg, color:global.bar.color_text}}>
        {global.bar.text}
      </div>
    )
  }
  // Link to internal
  if (global.bar.link[0] === "/") {
    return (
      <Link to={global.bar.link} className="bar" style={{backgroundColor:global.bar.color_bg, color:global.bar.color_text}}>
        {global.bar.text}
      </Link>
    )
  }
  // Link to external
  return (
    <a target="_blank" href={global.bar.link} className="bar" style={{backgroundColor:global.bar.color_bg, color:global.bar.color_text}}>
      {global.bar.text}
    </a>
  )

};


export default Bar;

Bar.defaultProps = {
  show: false,
  text: "",
  link: null,
  bgColor: null,
  textColor: null
}

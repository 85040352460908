import React from 'react'
import { Link } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';

import Header2 from '../../parts/header2';
import Side from '../../parts/side';

// Images
import img_matko_a_kubko_pomahame_detom from 'images/pages/matko_a_kubko_pomahame_detom.jpg';


const InfoChildrenPage = (props) => {
  const { i18n, t } = useTranslation();

  return(
    <>
      <section id="home">
          <Header2 props={props} />
      </section>

      <section id="content" className="border-top">
          <div className="container">
              <h2 className="headline-left">{t('helpTheChildren')}</h2>
              <div className="row">

                  <div className="col-xl-8 main">
                    <section id="preprava_balikov">
                      <img src={img_matko_a_kubko_pomahame_detom} alt="Maťko a Kubko pomáha deťom" />
                    </section>
                    <section id="we-transport">
                      <p><Trans i18nKey="pageInfo.children.introduction"><a href="/"></a></Trans></p>
                      <h3 className="section-title">{t('pageInfo.children.how')}</h3>
                      <p><Trans i18nKey="pageInfo.children.howText"><b></b><a href="https://matkoakubko.com/spravte-spolu-s-nami-na-vianoce-radost-detom/"></a><a href="https://matkoakubko.com/matko-kubko-podporuje-deti/"></a><a href="https://matkoakubko.com/preprava-balikov-slovensko-anglicko"></a></Trans></p>
                      <h3 className="section-title">{t('pageInfo.children.whom')}</h3>
                      <p><Trans i18nKey="pageInfo.children.whomText"><b></b></Trans></p>
                      <h3 className="section-title">{t('pageInfo.children.involve')}</h3>
                      <p><Trans i18nKey="pageInfo.children.involveText"><b></b></Trans></p>
                      <h3 className="section-title">{t('pageInfo.children.why')}</h3>
                      <p>{t('pageInfo.children.whyText')}</p>
                    </section>

                    <section>
                        <p><Trans i18nKey="page.delivery.order"><span></span></Trans></p>
                        <Link to={i18n.language === "sk" ? "/objednavka-prepravy-balika" : "/order"} data-action="form"><button>{t('orderit')}</button></Link>
                    </section>
                  </div>

                  <div className="col-xl-4 side">
                      <Side />
                  </div>

              </div>
          </div>
      </section>
    </>
  );
}

export default InfoChildrenPage;

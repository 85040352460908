import React from 'react'
import { Link } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';

import Header2 from '../../parts/header2';
import Side from '../../parts/side';

// Images
import order_tutorial_1 from 'images/pages/order_tutorial_1.jpeg';
import order_tutorial_2 from 'images/pages/order_tutorial_2.jpeg';
import order_tutorial_3 from 'images/pages/order_tutorial_3.jpeg';
import order_tutorial_4 from 'images/pages/order_tutorial_4.jpeg';
import order_tutorial_5 from 'images/pages/order_tutorial_5.jpeg';
import order_tutorial_6 from 'images/pages/order_tutorial_6.jpeg';
import order_tutorial_7 from 'images/pages/order_tutorial_7.jpeg';
import order_tutorial_8 from 'images/pages/order_tutorial_8.jpeg';


const InfoHowToMakeOrderPage = (props) => {
  const { i18n, t } = useTranslation();

  return(
    <>
      <section id="home">
          <Header2 props={props} />
      </section>

      <section id="content" className="border-top">
          <div className="container">
              <h1 className="headline-left">Návod na vyplnenie objednávky</h1>
              <div className="row">

                  <div className="col-xl-8 main">
                    <section id="we-transport">
                      <p>Kliknite si na stránku <Link to="/objednavka-prepravy-balika">Objednanie prepravy</Link> a postupujte podľa týchto krokov.</p>

                      <h2>1. Vyplňte údaje o odosielateľovi</h2>
                      <p>Vyplňte <b>meno, priezvisko, telefón, adresa, mesto, PSČ</b> a <b>krajinu</b> odosielateľa, nezabudnite na <b>email</b> (zadajte ho správne, na email vám príde QR kód, bez neho nie je možné zásielku odoslať) a ak chcete zaškrtnite aj či chcete dostávať emaily o najbližej preprave.</p>
                      <img src={order_tutorial_1} />

                      <h2>2. Vyplňte údaje o príjemcovi</h2>
                      <p>Opäť vyplňte <b>meno, priezvisko, telefón, adresa, mesto, PSČ</b> <b>krajinu</b> príjemcu. Vyberte si termín prepravy (všetky termíny prepráv <Link to="/ako-vyplnit-objednavku">nájdete tu</Link>). Zaškrtnite možnosť <b>Áno, vytlačím si vygenerovaný QR kód a nalepím ho na každú zásielku</b> ak si QR kód, ktorý vám pošleme na email, nalepíte na krabicu sami.</p>
                      <img src={order_tutorial_2} />
                      <p>Ak nemáte tlačiareň, QR kód vám môžeme nalepiť na krabicu/e my, ale účtujeme si za to poplatok 5 EUR/GBP. V tom prípade túto možnosť odškrtnite, zobrazí sa vám už vopred zaškrtnutá možnosť <b>Súhlasím s poplatkom 5 EUR/GBP za vytlačenie QR kódu</b>.</p>
                      <img src={order_tutorial_3} />

                      <h2>3. Vyplňte údaje o zásielke</h2>
                      <p>Vyplňte <b>meno, priezvisko, telefón, adresa, mesto, PSČ</b> a <b>krajinu</b> odosielateľa, nezabudnite na <b>email</b> (zadajte ho správne, na email vám príde QR kód, bez neho nie je možné zásielku odoslať) a ak chcete zaškrtnite aj či chcete dostávať emaily o najbližej preprave.</p>
                      <p>A) Do kolónky <b>Popis zásielky a počet zásielok</b> napíšte, čo posielate, čiže <b>kufor, bicykel, tašku, krabicu</b> atď, ich počet. <b>Nie obsah zásielky</b>.</p>
                      <p>B) Vyplňte <b>Obsah zásielky (Colné vyhlásenie)</b>, do jednotlivých kolóniek napíšte, čo máte v zásielke, aký druh tovaru, ich počet a cenu za kus.</p>
                      <p>Ak posiela sukromná osoba zásielku inej súkromnej osobe (tovar neobchodného charakteru), tak hodnota tovaru <b>nesmie prekročiť 150 eur</b>, v opačnom prípade bude pri doručení <b>platiť prijímateľ CLO a DPH</b>.<br />
                        Ak sa nejedná o nové veci, tak je potrebné to napísať aj do colného vyhlásenia (obnosené šatstvo, použité hračky, atd)</p>
                      <p><b>Príklad:</b></p>
                      <ul>
                        <li>Obnosené oblečenie - 25 kusov - 2 eur / kus</li>
                        <li>Bicykel -  1 kus – 60 eur / kus</li>
                        <li>Drogéria - 5   kusov – 7 eur / kus</li>
                      </ul>
                      <p>Je to dôležité pre colné konanie, vypĺňajte údaje pravdivo a hlavne neposielajte <Link to="/viete-ktore-veci-vylucene-prepravy-balikov-anglicka">veci vylúčené z prepravy</Link>.</p>
                      <p>C) Ak máte viac druhov vecí, kliknite na tlačidlo <b>pridať položku</b>, objaví sa nová kolónka na vypĺňanie.</p>
                      <p>D) <b>Celková cena</b> je súčet hodnoty vecí vo vašej zásielke, tento údaj je dôležitý pre colné konanie, <b>nie je to cena prepravy</b>.</p>
                      <p>E) Ak ste všetky údaje vyplnili, kliknite na tlačidlo <b>Odoslať objednávku</b>.</p>

                      <h2>4.</h2>
                      <p>Na váš email, ktorý ste zadali v objednávke, pošleme všetky informácie, ktoré sa týkajú vašej objednávky. V prílohe emailu nájdete <b>QR kód</b>, ktorý si vytlačte a nalepte na krabicu. Ďalej tam nájdete aj <b>proforma invoice</b>, kde je súhrn vašej objednávky.</p>
                      <img src={order_tutorial_4} />

                      <h2>Ako má vyzerať krabica s QR kódom</h2>
                      <p>a) Na krabici bude nalepený <b>IBA QR kód</b>, nič iné.</p>
                      <img src={order_tutorial_5} />
                      <p>b) Ku <b>QR kódu NEPÍŠTE</b> adresu odosielateľa ani príjemcu.</p>
                      <img src={order_tutorial_6} />
                      <p>c) Na krabicu <b>NEPÍŠTE adresu</b> odosielateľa ani príjemcu, či už máte QR kód alebo nemáte.</p>
                      <img src={order_tutorial_7} />
                      <p>d) Ak nemáte tlačiareň a nevytlačili ste si QR kód, náš kuriér nalepí QR kód za vás, táto služba je spoplatnená 5 EUR/GBP (viď bod 2.).</p>
                      <img src={order_tutorial_8} />

                      <h2>Video návod</h2>
                      <p>Pozrite si ako vyplniť objednávku prepravy vo videu:</p>
                      <iframe width="800" height="450" src="https://www.youtube.com/embed/luCRDTlTPtc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                      <p>Ak by ste mali akékoľvek otázky, kľudne nás <Link to="/kontakt">kontaktujte</Link> alebo nám napíšte správu na našu <a href="https://www.facebook.com/MatkoAKubko" target="_blank">facebookovú stránku</a>.</p>

                      <p>&nbsp;</p>
                      <p><Link to="/ako-zabalit-balik-do-anglicka-navod-krok-za-krokom">Ako zabaliť balík do Anglicka? Návod krok za krokom</Link></p>
                      <p><Link to="/ako-zabalit-kufor-pri-preprave-zahranicia">Ako zabaliť kufor pri preprave do zahraničia</Link></p>
                      <p><Link to="/ako-spravne-zabalit-nabytok-pri-preprave">Ako správne zabaliť nábytok pri preprave?</Link></p>
                      <p><Link to="/ako-spravne-zabalit-elektroniku-pri-preprave-do-zahranicia">Ako správne zabaliť elektroniku pri preprave do zahraničia</Link></p>
          					</section>
                  </div>

                  <div className="col-xl-4 side">
                      <Side />
                  </div>

              </div>
          </div>
      </section>
    </>
  );
}

export default InfoHowToMakeOrderPage;

import React from 'react';
import { useTranslation } from 'react-i18next';


const Offer = (props) => {
  const { t } = useTranslation();

  return (
    <section id="we-offer">
        <div className="container">
            <h3 className="headline-left">{t('weoffer')}</h3>

            <div className="row">

                <div className="col-lg-4 offer-item">
                    <div data-aos="fade-zoom-in" id="insurance" className="number-box">1</div>
                    <h4>{t('insurance')}</h4>
                    <p>{t('weoffer.insurance').split('\n').reduce((r, c, x) => r ? [...r, <br key={x}/>, c] : [c], null)}</p>
                </div>

                <div className="col-lg-4 offer-item">
                    <div data-aos="fade-zoom-in" data-aos-delay="500" id="cost" className="number-box">2</div >
                    <h4>{t('cost')}?</h4>
                    <p>{t('weoffer.cost').split('\n').reduce((r, c, x) => r ? [...r, <br key={x}/>, c] : [c], null)}</p>
                </div>

                <div className="col-lg-4 offer-item">
                    <div data-aos="fade-zoom-in" data-aos-delay="1000" id="deliver" className="number-box">3</div>
                    <h4>{t('howlong')}?</h4>
                    <p>{t('weoffer.howlong').split('\n').reduce((r, c, x) => r ? [...r, <br key={x}/>, c] : [c], null)}</p>
                </div>

            </div>
        </div>
    </section>
  )
}

export default Offer;

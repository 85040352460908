import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import ScrollToTop from 'components/common/scrolltotop';

// CSS
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

// JS
import $ from 'jquery';
import Popper from 'popper.js';
//import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap/dist/js/bootstrap.min.js';

import Loader from 'components/loader';
import Global from './global';
import App from './App';
import * as serviceWorker from './serviceWorker';

// Context
import { GlobalProvider } from 'context/globalContext.js';


ReactDOM.render(
  <BrowserRouter>
    <ScrollToTop>
      <Suspense fallback={<Loader />}>

        <GlobalProvider>
          <Global>
            <App />
          </Global>
        </GlobalProvider>

      </Suspense>
    </ScrollToTop>
  </BrowserRouter>,
  document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
